
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane,
  NavItem, InputGroup,
  NavLink,
  CardFooter, CardHeader, UncontrolledAlert
} from "reactstrap";

import Form from 'react-bootstrap/Form';
import Flatpickr from "react-flatpickr";
import { useParams, Link, useNavigate } from 'react-router-dom';
import { TotalPositionsCard, TotalCalledCard, TotalCallableCard, DistributedCard, NAVCard, GainLossesCard, DPICard, TVPICard, IRRCard, } from "./dashboard_lib.js";

import Variables from "../lib/variables/variables.js";
import UseMoondanceApi from "../lib/api";

import MoondanceTable from "../lib/components/MoondanceTable.js";

import { buildTableData, buildAccountParameter } from "../lib/formatData";
import { MoondanceContext } from '../../App';

import Summary from "../pages/summary.js";
import Chart from "../lib/charts/chart.js";
import MoondanceLayout from "../lib/layout.js";
import WaterFallChart from "../lib/charts/WaterFallChart";
import Loading from "../lib/components/Loading.js";


const currentDate = new Date();
const twoYearsBefore = currentDate.getFullYear() - 7;
const defaultStartDate = new Date(twoYearsBefore, 0, 1);


const PdfReport = (props) => {
  document.title = "Dashboard | Moondance";

  const params = useParams();
  const moondanceApi = UseMoondanceApi();

  const [transactionData, setTransactionData] = useState({});
  const { darkMode } = useContext(MoondanceContext);
  const { portraitMode } = useContext(MoondanceContext);
  const { lastUpdate } = useContext(MoondanceContext);
  const [overviewChartCurrency, setOverviewChartCurrency] = useState('user_currency');
  const [ai_reply, setAiReply] = useState('');
  const { selectedAccountList } = useContext(MoondanceContext);
  const { selectedStatus } = useContext(MoondanceContext);
  const [userName, setUserName] = useState('');
  const { userCurrency } = useContext(MoondanceContext);
  const [DATA, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [cashflowChartDisplay, setCashflowChartDisplay] = useState('All');
  const [showCapitalCallsOnly, setShowCapitalCallsOnly] = useState(false)
  const [activeTab, setactiveTab] = useState("summary");
  const [pageSelectAll, setPageSelectAll] = useState(true);
  const [showBudget, setShowBudget] = useState(false)
  const [min_year, set_min_year] = React.useState(0);
  const [max_year, set_max_year] = React.useState(0);

  const flatpickrRef = useRef(null);

  const [selectedStartDate, setSelectedStartDate] = useState(defaultStartDate);
  const [pdfReportSettings, setPdfReportSettings] = React.useState([
    { key: 'summary', title: 'Summary', display: true, type: 'custom' },
    //Summary per account
    { key: 'table_per_account', title: 'Summary per Account ', display: true, type: 'table', variable: 'position_account_name', variableName: 'Account' },
    { key: 'chart_per_account', title: 'Summary per Account ', display: true, type: 'chart', variable: 'account', variableName: 'Account', horizontal: true },

    //Summary per vintage
    { key: 'table_per_vintage', title: 'Summary per Vintage', display: true, type: 'table', variable: 'asset_vintage_year', variableName: 'Vintage' },
    { key: 'chart_per_vintage', title: 'Summary per Vintage', display: true, type: 'chart', variable: 'asset_vintage_year', variableName: 'Vintage', horizontal: true },

    //Summary per currency
    { key: 'table_per_currency', title: 'Summary per Currency', display: true, type: 'table', variable: 'asset_currency', variableName: 'Currency' },
    { key: 'chart_per_currency', title: 'Summary per Currency', display: true, type: 'chart', variable: 'asset_currency', variableName: 'Currency', horizontal: true },


    { key: 'chart_per_type', title: 'Summary per Type', display: true, type: 'chart', variable: 'asset_type', variableName: 'type', horizontal: true },
    { key: 'chart_per_geography', title: 'Summary per Geography', display: true, type: 'chart', variable: 'geography', variableName: 'geography', horizontal: true },
    /*
        { key: 'table_per_type', title: 'Summary per Fund Type (table)', display: true, type: 'table', variable: 'asset_type', variableName : 'Type' },
        { key: 'chart_per_type', title: 'Summary per Fund Type (chart)', display: true, type: 'chart', variable: 'asset_type', variableName : 'Type' },
    */

    { key: 'list_of_positions', title: 'List of Positions', display: true, },

    {
      key: 'cashflows',
      title: 'List of Transactions',
      display: true,
      selectListlabel:

        <>
          <Col sm="2">
            <InputGroup>
              <Flatpickr
                className="form-control "
                onChange={(dateStr) => { setSelectedStartDate(dateStr) }}
                value={selectedStartDate}
                options={{
                  clickOpens: false,  // ensure this is true
                  allowInput: true,   // ensure direct input is allowed
                  dateFormat: "d/m/Y",
                }}
                ref={flatpickrRef}

              />
              <div className="input-group-append" onClick={() => flatpickrRef.current.flatpickr.open()}>
                <span className="input-group-text">
                  <i className="mdi mdi-calendar"></i>
                </span>
              </div>
            </InputGroup>
          </Col>
        </>
    },

    /*{key: 'summary_per_sectors', title: 'Summary per Sectors', display: false },
            {key: 'summary_per_geography', title: 'Summary per geographies', display: false },
            {key: 'list_of_positions', title: 'List of Positions (with KPis)', display: false },*/
  ]);
  const [pdfReportContent, setPdfReportContent] = React.useState(null);

  const reportRef = useRef(null);


  const [selectedEndDate, setSelectedEndDate] = useState(new Date(currentDate.setMonth(currentDate.getMonth() + 3)));

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    if (printWindow) {
      printWindow.document.write(`
      <html>
        <head>
          <title>Generated Report</title>
          <style>
    /* Define A4 landscape page size */
    @page {
      size: A4 landscape;
      margin: 0;
    }

    /* Body styling for A4 landscape */
    body {
      width: 297mm; /* A4 width */
      margin: 0;
      padding: 8mm; /* Padding inside each page */
      background-color: white !important;
      font-family: Verdana, Geneva, Tahoma, sans-serif !important;
      font-weight: 300;
      font-size: 12px;
      line-height: 1.8em;
      color: black;
      box-sizing: border-box;
    }

    /* Main report container */
    .pdfReport {
      width: 100%;
      margin: 0;
      padding: 0;
    }
    h1 {
      font-size: 3em !important;
      font-weight: 200;  
      line-height: 25px;
      padding:0;
  }
    .pdf_header {
      width: 100%;
      height: 20mm;

      min-height: 20mm;
      max-height: 20mm;
      border-bottom: 1px solid #767676;
      box-sizing: border-box;

      position:absolute;
      top: 0mm;

      padding-bottom: 0mm;
      margin-bottom: 5mm;

    }

    /* Content wrapper */
    .content-wrapper {
      width: 100%;
      min-height: 210mm;
      height: 210mm !important;
      max-height: 210mm !important;
      box-sizing: border-box;
      overflow: hidden !important;
      break-inside: auto; /* Allow content to split across pages */
      display: block;
      page-break-after: always;
    }

    /* Footer styling (repeats on each page) */
    .pdf_footer {
      width: 100%;
      height:6mm;
      border-top: 1px solid #767676;
      box-sizing: border-box;
      position:absolute;
      bottom: 0mm;
    }

    /* Section styling */
    .pdf_left {
      position: relative; /* Changed from absolute for flow */
      width: 100%;
      margin-bottom: 5mm;
    }

    /* Table styling */
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 10px;
      background: white !important;
      break-inside: auto; /* Allow tables to split */
    }
    th {
      font-weight: 600;
      text-align: left;
      border-bottom: 1px solid #767676;
    }
    tr {
      border-bottom: 1px solid #767676;
      break-inside: avoid; /* Prefer not to split rows */
    }
    td {
      text-align: right;
      vertical-align: middle;
      height:4em;
    }
    td, th {
      padding: 1mm 3mm;
      line-height: 1.2em;
    }

    /* Chart styling */
    .apexcharts-canvas {
      background: white !important;
      max-width: 100%;
      break-inside: avoid; /* Prevent charts from splitting */
    }
    .apexcharts-gridline,
    .apexcharts-grid line,
    .apexcharts-xaxis line,
    .apexcharts-yaxis line,
    .apexcharts-xcrosshairs,
    .apexcharts-ycrosshairs {
      stroke: transparent !important;
      display: none !important;
    }
    .apexcharts-xaxis text,
    .apexcharts-yaxis text {
      font-family: Verdana, Geneva, Tahoma, sans-serif !important;
      fill: #000000 !important;
    }
    .apexcharts-tooltip {
      background: white !important;
      color: black !important;
    }

    /* Horizontal rule */
    hr {
      border: 0;
      border-top: 1px solid #767676;
      margin: 1em 0;
      break-inside: avoid;
    }
    tr, td, th {
      break-inside: avoid;
      page-break-inside: avoid;
    }

    /* Print-specific adjustments */
    @media print {
      @page {
        size: A4 landscape;
        margin: 0;
        font-size: 10px;
      }
      body {
        margin: 0 !important;
        padding: 0mm !important;
      }
      .content-wrapper {
        width: 100%;
        min-height: 210mm;
        height: 210mm !important;
        max-height: 210mm !important;
      }
      tr, th, td {
        border-bottom: 0.5px solid #999;
      }
      .pdf_footer {
        border-top: 0.5px solid #767676;
      }
      .pdf_header {
        border-bottom: 0.5px solid #767676;
        
      }
   
    }
  </style>
        </head>
        <body>
              ${reportRef.current.innerHTML}
        </body>
        <script>
          document.addEventListener("DOMContentLoaded", function () {
            // Hide ApexCharts crosshairs
            document.querySelectorAll(".apexcharts-xcrosshairs, .apexcharts-ycrosshairs").forEach(el => {
              el.style.stroke = "transparent";
              el.style.display = "none";
              el.style.visibility = "hidden";
              el.style.opacity = "0";
            });
            // Delay to ensure charts render fully before hiding
            setTimeout(() => {
              document.querySelectorAll(".apexcharts-xcrosshairs, .apexcharts-ycrosshairs").forEach(el => {
                el.style.stroke = "transparent";
                el.style.display = "none";
                el.style.visibility = "hidden";
                el.style.opacity = "0";
              });
            }, 500);
          });
        </script>
      </html>
    `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      // Uncomment to close the window after printing
      printWindow.close();
    }
  };
  useEffect(() => {
    const fetchUserProfile = async () => {
      const profile = await moondanceApi.getLoggedinUser();

      if (
        profile
        && profile.data
        && profile.data.first_name
        && profile.data.last_name) {
        setUserName(profile.data.first_name + ' ' + profile.data.last_name);
      }

    };
    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (params.id) { setactiveTab(params.id) }
  }, [params, params.id]);

  useEffect(() => {
    if (props.triggerReport) {
      handlePrint();
    }
  }, [props, props.triggerReport]);
  useEffect(() => {



    async function fetchYearlyData(api_parameters) {
      try {

        if (api_parameters.account && api_parameters.status) {
          const return_data = await moondanceApi.fetchYearlyTransactionPerPosition(api_parameters);
          const years = return_data.data.map(item => Number(item.year));

          // Find the minimum and maximum year values
          let min_year = Math.min(...years)
          let max_year = Math.max(...years)
          set_min_year(min_year);
          set_max_year(min_year);
          let positionFlow = {};

          positionFlow['meta_data'] = []
          positionFlow['meta_data']['min_year'] = min_year
          positionFlow['meta_data']['max_year'] = max_year

          return_data.data.forEach((item) => {
            if (!positionFlow[item.position_id]) {

              positionFlow[item.position_id] = {
                id: item.position_id,
                name: item.name,
                balance_in_user_currency: {},
                cash_in_in_user_currency: {},
                cash_out_in_user_currency: {},
                balance_in_commitment_pct: {},
                cash_in_in_commitment_pct: {},
                cash_out_in_commitment_pct: {},
              }

              for (let i = min_year; i <= max_year; i++) {
                positionFlow[item.position_id].balance_in_user_currency[i] = 0;
                positionFlow[item.position_id].cash_in_in_user_currency[i] = 0;
                positionFlow[item.position_id].cash_out_in_user_currency[i] = 0;
                positionFlow[item.position_id].balance_in_commitment_pct[i] = 0;
                positionFlow[item.position_id].cash_in_in_commitment_pct[i] = 0;
                positionFlow[item.position_id].cash_out_in_commitment_pct[i] = 0;
              }

            }
            if (item.year !== undefined && item.year !== null) {
              positionFlow[item.position_id].balance_in_user_currency[item.year] = item.balance_in_user_currency;
              positionFlow[item.position_id].cash_in_in_user_currency[item.year] = item.cash_in_in_user_currency;
              positionFlow[item.position_id].cash_out_in_user_currency[item.year] = item.cash_out_in_user_currency;
              positionFlow[item.position_id].balance_in_commitment_pct[item.year] = item.balance_in_commitment_pct;
              positionFlow[item.position_id].cash_in_in_commitment_pct[item.year] = item.cash_in_in_commitment_pct;
              positionFlow[item.position_id].cash_out_in_commitment_pct[item.year] = item.cash_out_in_commitment_pct;
            }
          });
          return positionFlow
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchData() {
      var api_parameters = {
        account: buildAccountParameter(selectedAccountList),
        status: buildAccountParameter(selectedStatus),
        userCurrency: userCurrency,
      };
      if (api_parameters.account && api_parameters.status) {
        try {
          var returnData = {};

          // Fetch summary, yearly, and positions data in parallel
          const [summarydata, yearlyData, positionsData, positionCashFlowPerYear, positionExpectedCashFlowPerYear] = await Promise.all([
            moondanceApi.fetchDashboardSummary(api_parameters),
            moondanceApi.fetchYearly(api_parameters),
            moondanceApi.fetchPositionsList({
              account: api_parameters.account,
              status: api_parameters.status,
              calculateIrr: true,
              keyword: ''
            }),
            fetchYearlyData(api_parameters),

            moondanceApi.fetchPositionExpectedCashFlowPerYear(api_parameters)
          ]);

          //sort result of fetchPositionExpectedCashFlowPerYear  to group them per position_id and year
          let model_balance_value_in_user_currency = {}
          let model_balance_in_pct_of_commitment = {}
          let model_call_value_in_user_currency = {}
          let model_distribution_value_in_user_currency = {}



          if (positionExpectedCashFlowPerYear.data) {
            //console.log('positionExpectedCashFlowPerYear', positionExpectedCashFlowPerYear)
            positionExpectedCashFlowPerYear.data.forEach((item, index) => {

              if (!model_balance_in_pct_of_commitment[item.position_id]) {
                model_balance_in_pct_of_commitment[item.position_id] = {}
              }
              if (!model_balance_value_in_user_currency[item.position_id]) {
                model_balance_value_in_user_currency[item.position_id] = {}
              }
              if (!model_call_value_in_user_currency[item.position_id]) {
                model_call_value_in_user_currency[item.position_id] = {}
              }
              if (!model_distribution_value_in_user_currency[item.position_id]) {
                model_distribution_value_in_user_currency[item.position_id] = {}
              }
              model_balance_in_pct_of_commitment[item.position_id][item.year] = item.model_balance_in_pct_of_commitment
              model_balance_value_in_user_currency[item.position_id][item.year] = item.model_balance_value_in_user_currency
              model_call_value_in_user_currency[item.position_id][item.year] = item.model_call_value_in_user_currency
              model_distribution_value_in_user_currency[item.position_id][item.year] = item.model_distribution_value_in_user_currency

            })
          }

          returnData.expectedBalanceInPctOfCommitment = model_balance_in_pct_of_commitment
          returnData.expectedBalanceInUserCurrency = model_balance_value_in_user_currency
          returnData.expectedCallsInUserCurrency = model_call_value_in_user_currency
          returnData.expectedDistributionInUserCurrency = model_distribution_value_in_user_currency





          if (yearlyData.data.length > 0) {
            returnData.perYear = MoondanceLayout.filterDataPeryear(yearlyData.data);
            //console.log('perYear', returnData.perYear)
            returnData.listPositions = positionsData.data;
            returnData.ai_position_comments = positionsData.ai;

            returnData.positions_metadata = positionsData.metadata;
            returnData.table = buildTableData(yearlyData, positionsData.data);
            returnData.summary = summarydata.data;
            returnData.overviewChart = MoondanceLayout.buildOverviewChart(returnData, false, false);
            returnData.callable = MoondanceLayout.buildCallableChart(returnData);
            returnData.cashflow = [];
            returnData.cashflow['Past'] = MoondanceLayout.buildCashFlowChart(returnData, 'Past', false, showBudget);
            returnData.cashflow['Future'] = MoondanceLayout.buildCashFlowChart(returnData, 'Future', false, showBudget);
            //console.log('overviewChartCurrency',overviewChartCurrency)
            // Await the result of fetchYearlyData()

            // Calculate stats based on cashflow
            returnData.stats = {};
            returnData.stats.sumOfFutureCalls = 0;
            returnData.stats.sumOfFutureDistributions = 0;
            returnData.stats.futureAddedGain = 0;
            returnData.stats.sumOfPastCalls = 0;
            returnData.stats.sumOfPastDistributions = 0;
            returnData.stats.pastAddedGain = 0;

            returnData.stats.sumOfCalls = 0;
            returnData.stats.sumOfDistributions = 0;
            returnData.stats.addedGain = 0;

            if (returnData.cashflow['Future'] && returnData.cashflow['Future'].data[0] && returnData.cashflow['Future'].data[0].data) {
              returnData.stats.sumOfFutureCalls = returnData.cashflow['Future'].data[0].data.reduce((a, b) => a + b, 0);
            }
            if (returnData.cashflow['Future'] && returnData.cashflow['Future'].data[1] && returnData.cashflow['Future'].data[1].data) {
              returnData.stats.sumOfFutureDistributions = returnData.cashflow['Future'].data[1].data.reduce((a, b) => a + b, 0);
            }
            returnData.stats.sumOfFutureCashFlows = returnData.stats.sumOfFutureCalls + returnData.stats.sumOfFutureDistributions;

            if (returnData.cashflow['Past'] && returnData.cashflow['Past'].data[0] && returnData.cashflow['Past'].data[0].data) {
              returnData.stats.sumOfPastCalls = returnData.cashflow['Past'].data[0].data.reduce((a, b) => a + b, 0);
            }
            if (returnData.cashflow['Past'] && returnData.cashflow['Past'].data[1] && returnData.cashflow['Past'].data[1].data) {
              returnData.stats.sumOfPastDistributions = returnData.cashflow['Past'].data[1].data.reduce((a, b) => a + b, 0);
            }
            returnData.stats.sumOfPastCashFlows = returnData.stats.sumOfPastCalls + returnData.stats.sumOfPastDistributions;

            returnData.stats.sumOfCalls = returnData.stats.sumOfPastCalls + returnData.stats.sumOfFutureCalls;
            returnData.stats.sumOfDistributions = returnData.stats.sumOfPastDistributions + returnData.stats.sumOfFutureDistributions;
            returnData.stats.sumOfCashFlows = returnData.stats.sumOfCalls + returnData.stats.sumOfDistributions;

            returnData.cashflow['All'] = MoondanceLayout.buildCashFlowChart(returnData, 'All', false, showBudget);

            returnData.cumulatedCashflow = [];
            returnData.cumulatedCashflow['Past'] = MoondanceLayout.buildCumulatedCashFlowChart(returnData, 'Past', false, showBudget);
            returnData.cumulatedCashflow['Future'] = MoondanceLayout.buildCumulatedCashFlowChart(returnData, 'Future', false, showBudget);

            returnData.cumulatedCashflow['Future_callOnly'] = MoondanceLayout.buildCumulatedCashFlowChart(returnData, 'Future', true, showBudget);
            returnData.cumulatedCashflow['All'] = MoondanceLayout.buildCumulatedCashFlowChart(returnData, 'All', false, showBudget);
            returnData.positionCashFlowPerYear = positionCashFlowPerYear;
            setData(returnData);
          } else {
            setData('nodata');
          }

          /* returnData.overviewChart.options.grid = {
              show: false, // Ensure no gridlines
            borderColor: "transparent", // Avoid unwanted borders
            xaxis: {lines: {show: false } },
            yaxis: {lines: {show: false } }
             
           }*/
          returnData.overviewChart.options.animations = {
            enabled: false, // Disable animations for PDFs
          }
          returnData.overviewChart.options.tooltip = {
            enabled: false, // Disable tooltips for PDFs
          }
          returnData.overviewChart.options.stroke = {
            width: 1,
            colors: ['#CCCCCC']
          }

          //console.log(' Return Data', returnData)
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    }


    async function fetchTransaction() {
      if (selectedAccountList) {
        var api_parameters = {
          account: buildAccountParameter(selectedAccountList),
          status: buildAccountParameter(selectedStatus),
          keyword: '',
          userCurrency: userCurrency,
        }
        //console.log('fetchData',selectedStartDate)
        if (selectedStartDate instanceof Date) {

          var formattedDate =

            selectedStartDate.getFullYear() + '-' +
            ("0" + (selectedStartDate.getMonth() + 1)).slice(-2) + "-" +
            ("0" + selectedStartDate.getDate()).slice(-2)

          //console.log('isdate', formattedDate)
          api_parameters.date_start = formattedDate
        } else {
          var parts = selectedStartDate.split('/');
          var newDateStr = `${parts[2]}-${parts[1]}-${parts[0]}`;
          api_parameters.date_start = newDateStr
        }
        if (selectedEndDate instanceof Date) {
          var formattedDate =
            selectedEndDate.getFullYear() + "-" +
            ("0" + (selectedEndDate.getMonth() + 1)).slice(-2) + "-" +
            ("0" + selectedEndDate.getDate()).slice(-2)

          api_parameters.date_end = formattedDate

        } else {
          var parts = selectedEndDate.split('/');
          var newDateStr = `${parts[2]}-${parts[1]}-${parts[0]}`;
          api_parameters.date_end = newDateStr
        }
        try {
          var data = {};
          var data_transactions = await moondanceApi.fetchTransactions(api_parameters);
          //console.log('dataPerType', api_parameters, data_transactions.data)
          if (data_transactions.data.length > 0) {
            data.transactions = data_transactions.data
            data.transactions.sort((a, b) => b.timestamp - a.timestamp);

            setTransactionData(data.transactions);

          } else {
            setTransactionData(null);
          }
          setLoading(false)

        } catch (error) {
          console.log(error);
        }
      }
    }
    if (selectedAccountList && selectedStatus && cashflowChartDisplay) {
      setLoading(true);
      fetchData().then(() => {
        fetchTransaction().then(() => {
          setLoading(false);
        });
      });
    }
  }, [selectedAccountList, selectedStatus, showBudget, lastUpdate]);



  function checkPage(index) {
    let new_pdfReportSettings = [...pdfReportSettings]
    new_pdfReportSettings[index].display = (new_pdfReportSettings[index].display ? !new_pdfReportSettings[index].display : true)
    setPdfReportSettings(new_pdfReportSettings)

    setPdfReportContent( null)
  }

  function checkAllPage(status) {
    console.log('checkAllPage', status)
    let new_pdfReportSettings = [...pdfReportSettings]
    new_pdfReportSettings.map((item, index) => {
      new_pdfReportSettings[index].display = status
    })
    setPdfReportContent( null)
    setPdfReportSettings(new_pdfReportSettings)
    setPageSelectAll(status)
  }

  function buildReport() {
    const pageStart = (page) => page * 297; // A4 height in mm

    const pageHeader = (page) => (
      <div className="pdf_header" >
        <h1 style={{
          position: 'absolute',
          top: '0mm',
          left: '5mm',
          paddingTop: '0mm'
        }}>Private Equity Portfolio Report</h1>

        <sub
          style={{
            position: 'absolute',
            top: '3mm',
            right: '15mm',
          }}
        >
          Date: {new Date().toLocaleDateString('fr-FR')}
        </sub>
        <sub
          style={{
            position: 'absolute',
            top: '8mm',
            right: '15mm',
          }}
        >
          Account: {MoondanceLayout.displayArrayLabels(selectedAccountList)}
        </sub>
        <sub
          style={{
            position: 'absolute',
            top: '13mm',
            right: '15mm',
          }}
        >
          Status: {MoondanceLayout.displayArrayLabels(selectedStatus)}
        </sub>
      </div>
    );

    function showGraph(title, option, horizontal, top, left, height = 100) {
      return (
        <>
          <div
            className="pdf_left"
            style={{
              left: `${left}mm`,
              top: `${top}mm`,
              width: '145mm',
              fontSize: '14px',
              fontWeight: '650',
              position: 'absolute',
            }}
          >
            {title}
          </div>
          <div
            className="pdf_left"
            style={{
              left: `${left}mm`,
              top: `${top + 5}mm`,
              width: '145mm',
              height: '75mm',
              maxHeight: '80mm',
              position: 'absolute',
            }}
          >
            <Chart option={option} horizontal={horizontal} hideControls={true} height={250} showChart={true} />
          </div>
        </>
      );
      { }
    }

    function showTable(title, groupBy = 'asset_name', positionPerPage = 20, starting_position = 0) {
      return <>
        <div
          className="pdf_left"
          style={{
            left: '5mm',
            top: '25mm',
            width: '145mm',
            fontSize: '14px',
            fontWeight: '650',
            position: 'absolute',
          }}
        >
          {title}
        </div>
        <div
          className="pdf_left"
          style={{
            left: '5mm',
            top: '35mm',
            width: '290mm',
            fontSize: '14px',
            fontWeight: '650',
            position: 'absolute',
          }}
        >

          <MoondanceTable
            printMode={true}
            key={groupBy}
            rows={DATA.listPositions}
            defaultSort={{ key: groupBy, direction: 'ascending' }}
            paginationSize={positionPerPage}
            starting_position={starting_position}
            hideExportButton={true}
            groupBy={groupBy}
            columns={[
              {
                label: 'Name',
                field: 'asset_name',
                style: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
                customFormat: (row) => {
                  return (
                    <span>{row.asset_name}</span>)
                },
              },

              (selectedAccountList.length > 1) && { label: 'Account', field: 'position_account_name', align: 'right' },
              {
                label: 'Type', field: 'asset_type',
                customFormat: (row) => {
                  if (MoondanceLayout.assetType && row.asset_type) {
                    if (MoondanceLayout.assetType[row.asset_type]) {
                        return MoondanceLayout.assetType[row.asset_type].label;
                    } else {
                        let explType = typeof row.asset_type === 'string' ? row.asset_type.split(',') : [];
                        let returnVal = '';
                
                        explType.forEach((item) => {
                            if (MoondanceLayout.assetType[item] && MoondanceLayout.assetType[item].label) {
                                returnVal += MoondanceLayout.assetType[item].label + ', ';
                            }
                        });
                
                        return returnVal ? returnVal.slice(0, -2) : ''; // Remove trailing comma and space
                    }
                }
                
                return '';
                
        
                }
              },
              { label: 'Vintage', field: 'asset_vintage_year', align: 'right', },
              { label: 'Currency', field: 'asset_currency', align: 'right', },
              { label: 'Commitment', field: 'position_commited_in_user_currency_at_current_rate', format: 'currency', formatUnit: userCurrency, customFormat: (row) => { return MoondanceLayout.formatCurrency(row['position_commited_in_user_currency_at_current_rate'], userCurrency) }, align: 'right', showTotal: true },
              { label: 'Paid in', field: 'position_cumulated_capital_calls_in_user_currency', format: 'currency', formatUnit: userCurrency, customFormat: (row) => { return MoondanceLayout.formatCurrency(row['position_cumulated_capital_calls_in_user_currency'], userCurrency) }, align: 'right', showTotal: true },
              { label: 'Distribution', field: 'position_cumulated_distributions_in_user_currency', format: 'currency', formatUnit: userCurrency, customFormat: (row) => { return MoondanceLayout.formatCurrency(row['position_cumulated_distributions_in_user_currency'], userCurrency) }, align: 'right', showTotal: true },

              {
                label: 'Nav', field: 'position_last_nav_in_user_currency', format: 'currency', formatUnit: userCurrency,
                customFormat: (row) => {
                  return (
                    (parseInt(row['position_last_nav_in_user_currency']) === 0 && parseInt(row['position_cumulated_capital_calls_in_user_currency']) < 0)
                      ? <span className="badge bg-danger"><i className="mdi mdi-alert"></i> NO NAV</span>
                      : MoondanceLayout.formatCurrency(row['position_last_nav_in_user_currency'], userCurrency)
                  )
                },
                align: 'right', showTotal: true
              },
              { label: 'Gain & Losses', field: 'position_gain_and_losses_in_user_currency', format: 'currency', formatUnit: userCurrency, customFormat: (row) => { return MoondanceLayout.formatCurrency(row['position_gain_and_losses_in_user_currency'], userCurrency) }, align: 'right', showTotal: true },

              { label: 'TVPI', field: 'position_tvpi_in_user_currency', format: 'number', align: 'right' },
              { label: 'DPI', field: 'position_dpi_in_user_currency', format: 'number', align: 'right' },
              { label: 'IRR', field: 'position_irr_in_user_currency', customFormat: (row) => { return (MoondanceLayout.formatPercentage(row['position_irr_in_user_currency'], 2, true)) }, align: 'right' },

            ]
            }

          />
        </div>

      </>
    }
    function showChartpage(item, item_label = '',horizontal = false) {

      return <>
        {showGraph('Capital Call per ' + item_label, 'cc_' + item, horizontal, topRow, leftColumn, defaultSquareHeight)}
        {showGraph('Nav per ' + item_label, 'nav_' + item, horizontal, topRow, rightColumn, defaultSquareHeight)}
        {showGraph('Distribution per ' + item_label, 'dis_' + item, horizontal, top2Row, leftColumn, defaultSquareHeight)}
        {showGraph('Gain & Losses per ' + item_label, 'gl_' + item, horizontal, top2Row, rightColumn, defaultSquareHeight)}
      </>

    }

    function showCashFlowTable(positionPerPage, starting_position) {
      return <>
        <div
          className="pdf_left"
          style={{
            left: '5mm',
            top: '25mm',
            width: '145mm',
            fontSize: '14px',
            fontWeight: '650',
            position: 'absolute',
          }}
        >
          Transactions
        </div>
        <div
          className="pdf_left"
          style={{
            left: '5mm',
            top: '35mm',
            width: '290mm',
            fontSize: '14px',
            fontWeight: '650',
            position: 'absolute',
          }}
        >

          <MoondanceTable
            printMode={true}
            rows={transactionData}
            defaultSort={{ key: 'timestamp', direction: 'descending' }}
            paginationSize={positionPerPage}
            starting_position={starting_position}
            hideExportButton={true}

            columns={[
              { label: 'Date', field: 'timestamp', format: 'timestampToDate' },
              {
                label: 'Position',
                allowGroupBy: true,
                field: 'asset_name',
                style: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },

              },
              {
                label: 'Type', field: 'type',
                allowGroupBy: true,
                customFormat: (row) => {
                  let typeLabel = row.type
                  if (MoondanceLayout.transactionType.find(item => item.id === row.type) && MoondanceLayout.transactionType.find(item => item.id === row.type).label) {
                    typeLabel = MoondanceLayout.transactionType.find(item => item.id === row.type).label
                  }
                  return typeLabel
                }
                , align: 'left'
              },
              { label: 'Shares (+/-)', field: 'shares', customFormat: (row) => { return (MoondanceLayout.formatNumber(row['shares'], 2)) }, format: 'number', align: 'right' },

              { label: 'Value (' + userCurrency + ')', field: 'value_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', },

              { label: 'Note', field: 'note', },
              {
                label: 'Amount', field: 'value_in_transaction_currency', align: 'right',
                customFormat: (row) => {
                  let sign = ''
                  return row.value_in_transaction_currency.toLocaleString('fr-FR', { style: "currency", currency: row.transaction_currency, maximumFractionDigits: 0 })

                },
              },

              {
                label: 'Bank', field: 'bank', align: 'right',
                allowGroupBy: true
              },

              {
                label: 'Currency', field: 'transaction_currency',
                allowGroupBy: true, align: 'right'
              }


            ]
            }

          />
        </div>

      </>
    }
    const pageFooter = (page) => (

      <div className="pdf_footer">
        <sub style={{ position: 'absolute', bottom: '0mm', left: '5mm', }}>
          Exported by {userName}
        </sub>
        <sub style={{ position: 'absolute', bottom: '0mm', right: '15mm' }}>
          Moondance.financial - {new Date().toLocaleString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })} -  Page {page + 1}
        </sub>
      </div>
    );

    let page = []
    let orientation = []
    let pageNumber = 0
    let topRow = 30;
    let top2Row = 115;
    let leftColumn = 5;
    let rightColumn = 155;
    let defaultSquareHeight = 100
    let startingPosition = 0;
    let positionPerPage = 10


    pdfReportSettings && pdfReportSettings.map((item) => {

      if (item.display) {
        if (item.type === 'table' && item.variable) {
          page[pageNumber++] = showTable(item.title, item.variable)
        } else if (item.type === 'chart' && item.variable) {
          page[pageNumber++] = showChartpage(item.variable, (item.variableName || item.variable), (item.horizontal ? item.horizontal : false))
        } else {
          //console.log(item)
          if (item.key === 'summary') {
            page[pageNumber++] = <>
              <div
                className="pdf_left"
                style={{
                  top: '25mm',
                  left: '15mm',
                  width: '100mm',
                  maxHeight: '100mm',
                }}
              >
                <h2 >Account :{MoondanceLayout.displayArrayLabels(selectedAccountList)}</h2>
                <p>
                  Number of Positions: {MoondanceLayout.formatNumber(DATA.summary.total_count_positions, 0)}
                  <hr />
                  Total Commitment : <span style={{ float: 'right' }}>{MoondanceLayout.formatNumber(DATA.summary.total_commited_in_user_currency / 1000, 0) + ' k' + MoondanceLayout.currencySymbol(userCurrency)}</span><br />
                  Cash Contributed : <span style={{ float: 'right' }}>- {MoondanceLayout.formatNumber(DATA.summary.total_capital_call_in_user_currency / 1000, 0) + ' k' + MoondanceLayout.currencySymbol(userCurrency)}</span><br />
                  Total Open Commitment : <span style={{ float: 'right' }}> = {MoondanceLayout.formatNumber(DATA.summary.total_remaining_in_user_currency / 1000, 0) + ' k' + MoondanceLayout.currencySymbol(userCurrency)}</span>
                  <hr />
                  Cash Contributed : <span style={{ float: 'right' }}>{MoondanceLayout.formatNumber(DATA.summary.total_capital_call_in_user_currency / 1000, 0) + ' k' + MoondanceLayout.currencySymbol(userCurrency)}</span><br />
                  Gain & Losses :<span style={{ float: 'right' }}>+ {MoondanceLayout.formatNumber(DATA.summary.total_gain_losses_in_user_currency / 1000, 0) + ' k' + MoondanceLayout.currencySymbol(userCurrency)}</span><br />
                  Distributions :<span style={{ float: 'right' }}>- {MoondanceLayout.formatNumber(DATA.summary.total_distributed_in_user_currency / 1000, 0) + ' k' + MoondanceLayout.currencySymbol(userCurrency)}</span><br />
                  Current Net Asset value :<span style={{ float: 'right' }}>= {MoondanceLayout.formatNumber(DATA.summary.total_nav_in_user_currency / 1000, 0) + ' k' + MoondanceLayout.currencySymbol(userCurrency)}</span><br />
                  <hr />
                  IRR  : <span style={{ float: 'right' }}>{`${MoondanceLayout.formatNumber(DATA.summary.total_irr * 150, 2)}%`},</span><br />
                  TVPI  : <span style={{ float: 'right' }}>{`${MoondanceLayout.formatNumber(DATA.summary.total_tvpi_in_user_currency, 2)}x`},</span><br />
                  DPI  : <span style={{ float: 'right' }}>{`${MoondanceLayout.formatNumber(DATA.summary.total_distributed_in_user_currency / DATA.summary.total_capital_call_in_user_currency, 2)}x`},</span><br />
                </p>
              </div>


              <div
                className="pdf_left"
                style={{
                  top: '55mm',
                  left: '130mm',
                  width: '150mm',
                  position: 'absolute',

                  maxHeight: '100mm',
                }}
              >
                {loading ? (
                  <Loading />
                ) : DATA.overviewChart.data && DATA.callable.data.length > 0 ? (
                  <WaterFallChart
                    data={DATA.overviewChart.data}
                    options={DATA.overviewChart.options}
                    horizontal={false}
                    hideControls={true}
                    height={380}
                    layout="print"
                    currency={userCurrency || 'EUR'}
                  />
                ) : (
                  <p>No data</p>
                )}
              </div>


            </>
          } else if (item.key === 'list_of_positions') {
            startingPosition = 0;
            while (DATA.listPositions.length > startingPosition) {
              page[pageNumber++] = showTable(item.title, null, positionPerPage, startingPosition)
              startingPosition += positionPerPage;
            }
          } else if (item.key === 'cashflows') {
            startingPosition = 0;
            while (transactionData.length > startingPosition) {
              page[pageNumber++] = showCashFlowTable(positionPerPage, startingPosition)
              startingPosition += positionPerPage;
            }


          }
        }
      }
    });


    /* if (pdfReportSettings.find(item => item.key === 'list_of_positions').display) {
                startingPosition = 0;
       while (DATA.listPositions.length > startingPosition) {
                page[pageNumber++] = {
                  key: 'position_' + startingPosition,
                  selected: true,
                  content: (
                    <>
  
                      <div
                        className="pdf_left"
                        style={{
                          left: '5mm',
                          top: '25mm',
                          position: 'absolute',
  
                          width: '200mm',
                          fontSize: '14px',
                          fontWeight: '650',
                          position: 'absolute',
  
                        }}
                      >List of Positions
                      </div>
                      <div
                        className="pdf_left"
                        style={{
                          position: 'absolute',
                          top: '32mm',
                          left: '5mm',
                          width: '290mm',
                          height: '190mm',
  
                          position: 'absolute',
                          fontSize: '10px',
                        }}
                      >
  
                        <MoondanceTable
                          printMode={true}
                          key={'position_list'}
                          rows={DATA.listPositions}
                          defaultSort={{ key: 'asset_name', direction: 'ascending' }}
                          paginationSize={positionPerPage}
                          starting_position={startingPosition}
                          hideExportButton={true}
                          columns={[
                            {
                              label: 'Name', field: 'asset_name', customFormat: (row) => {
                                return (
                                  <span>{row.asset_name}</span>)
                              },
                            },
  
                            { label: 'Vintage', format: 'number', field: 'asset_vintage_year', align: 'right', },
                            { label: 'Commitment', field: 'position_commited_in_user_currency_at_current_rate', format: 'currency', formatUnit: userCurrency, customFormat: (row) => { return MoondanceLayout.formatCurrency(row['position_commited_in_user_currency_at_current_rate'], userCurrency) }, align: 'right', showTotal: true },
                            { label: 'Paid in', field: 'position_cumulated_capital_calls_in_user_currency', format: 'currency', formatUnit: userCurrency, customFormat: (row) => { return MoondanceLayout.formatCurrency(row['position_cumulated_capital_calls_in_user_currency'], userCurrency) }, align: 'right', showTotal: true },
                            { label: 'Distribution', field: 'position_cumulated_distributions_in_user_currency', format: 'currency', formatUnit: userCurrency, customFormat: (row) => { return MoondanceLayout.formatCurrency(row['position_cumulated_distributions_in_user_currency'], userCurrency) }, align: 'right', showTotal: true },
  
                            {
                              label: 'Nav', field: 'position_last_nav_in_user_currency', format: 'currency', formatUnit: userCurrency,
                              customFormat: (row) => {
                                return (
                                  (parseInt(row['position_last_nav_in_user_currency']) === 0 && parseInt(row['position_cumulated_capital_calls_in_user_currency']) < 0)
                                    ? <span className="badge bg-danger"><i className="mdi mdi-alert"></i> NO NAV</span>
                                    : MoondanceLayout.formatCurrency(row['position_last_nav_in_user_currency'], userCurrency)
                                )
                              },
                              align: 'right', showTotal: true
                            },
                            { label: 'TVPI', field: 'position_tvpi_in_user_currency', format: 'number', align: 'right' },
                            { label: 'DPI', field: 'position_dpi_in_user_currency', format: 'number', align: 'right' },
                            { label: 'IRR', field: 'position_irr_in_user_currency', customFormat: (row) => { return (MoondanceLayout.formatPercentage(row['position_irr_in_user_currency'], 2, true)) }, align: 'right' },
  
                          ]
                          }
  
                        />
                      </div>
                    </>
  
  
                  )
                };
              startingPosition += positionPerPage;
       }
     }*/

    //left: '-10000px'
    setPdfReportContent(
      <div className="pdfReport_landscape" ref={reportRef} style={{ position: 'fixed', left: '-10000px' }}>
        {page.map((page, index) => (
          <React.Fragment key={index}>
            <div className="pdf-page" style={{ position: 'relative', height: '210mm', width: '297mm' }}>
              <div className="content-wrapper">
                {pageHeader(index)}
                {page}
                {pageFooter(index)}
              </div>
            </div>
          </React.Fragment>


        ))}
      </div>
    );
  }

  return (
    <React.Fragment >

      <Container fluid={true}>
        {(loading ?
          <Loading />
          :
          (!DATA.summary || DATA.summary.total_count_positions === 0 ?
            <>

              <Row>
                <Col xl={8} lg={3} sm={12} md={6} xs={12}>
                  <Card  >
                    <CardHeader className="bg-transparent border-bottom  card-header ">
                      <h5 style={{ marginTop: "1em" }}>No data is available for display.</h5>
                    </CardHeader>
                    <CardBody>
                      <p>This may be because no result match your research criteria </p>
                      <p>or because you haven't created any position in your account yet.</p>
                    </CardBody>
                    <CardFooter className="bg-transparent border-bottom  card-footer flex flex-row-reverse">
                      <a href="/addPosition">
                        <button className="btn btn-primary">Add a new position</button>
                      </a>
                    </CardFooter>
                  </Card>
                </Col>
                <Col xl={4} lg={3} sm={12} md={12} xs={12} >
                  <p ><b>Selected Account :</b>{MoondanceLayout.displayArrayLabels(selectedAccountList)}<br />
                    <b>Selected Status :</b>{MoondanceLayout.displayArrayLabels(selectedStatus)}<br />
                    <b>Currency :</b>{userCurrency}
                  </p>
                </Col>
              </Row>
            </>
            :
            <>

              <p>Select the pages to include in your report</p>
              <Form.Label column sm="3">
                <input
                  type="checkbox"
                  checked={pageSelectAll}
                  onClick={() => checkAllPage(!pageSelectAll)} /> {(pageSelectAll ? 'Unselect All' : 'Select All')}
              </Form.Label>
              <hr />
              {(pdfReportSettings && pdfReportSettings.map((page, index) => (

                <Form.Group as={Row} className="mb-3">
                  
                  {(page.key ==='cashflows' ? 
                    <>
                     <Form.Label column sm="3">
                    <input column sm="1" type="checkbox" checked={(page.display ? page.display : false)} onClick={() => checkPage(index)} />&nbsp;List of Transactions since </Form.Label>
                    {page.selectListlabel}
                    </> : 
                  <Form.Label column sm="3"> <input column sm="1" type="checkbox" checked={(page.display ? page.display : false)} onClick={() => checkPage(index)} />&nbsp;{page.title}  {((page.type ==='table' || page.type ==='chart') ? ' (' + page.type +')': '')}</Form.Label>)}
                </Form.Group>
              ))



              )}

              <hr />
              <button className="btn btn-primary" onClick={() => { buildReport() }}>Generate Report</button>
              {(pdfReportContent ? <button className="btn btn-primary" style={{ marginLeft: '1em' }} onClick={() => { handlePrint() }}>Print or Save as PDF</button>
                : null)}
              <hr />



              {pdfReportContent}
            </>

          )
        )}

      </Container>
    </React.Fragment >
  )
  //}
}
  ;

export default PdfReport;
/*
<div className="pdf_left" style={{ top: '765mm', width: '110mm', height: '30mm', left: '5mm' }}>
<b>Nav per Vintage</b>
<Chart option="nav_vintage" hideControls={true} height={320} />

</div>
<div className="pdf_right" style={{ top: '765mm', width: '110mm', height: '30mm', right: '5mm' }}>
<b>Open Commitment per Vintage</b>
<Chart option="commit_vintage" hideControls={true} height={320} />
</div>
 <div className="pdf_left" style={{ top: '460mm', width: '205mm', left: '5mm' }}>
                    <hr />
                    <Yearly />
                  </div>

div
          className="pdf_left"
          style={{
            left: '5mm',
            top: '165mm',
            width: '200mm',
            fontSize: '14px',
            fontWeight: '650',
            position: 'absolute',
          }}
        >
          NAV per Geography
        </div>
     
          <Chart option="nav_geo" horizontal={true} hideControls={true} height={220} />
        </div>


                  */


/*
 
 
  <div
    className="pdf_left"
    style={{
      left: '5mm',
      padding: '5mm',
      top: '130mm',
      width: '98mm',
      fontSize: '14px',
      fontWeight: '450',
      border: `1px solid ${Variables.printColorScheme.actual_neutral}`,
      borderRadius: '6.2px',
      position: 'absolute',
    }}
  >
    Number of Positions:{' '}
    <span style={{ float: 'right' }}>
      {MoondanceLayout.formatNumber(DATA.summary.total_count_positions, 0)}
    </span>
    <br />
    Total Commitment:{' '}
    <span style={{ float: 'right' }}>
      {MoondanceLayout.formatNumber(DATA.summary.total_commited_in_user_currency / 1000, 0)}
      {' k' + MoondanceLayout.currencySymbol(userCurrency)}
    </span>
    <br />
    Cash Contributed:{' '}
    <span style={{ float: 'right' }}>
      -{' '}
      {MoondanceLayout.formatNumber(DATA.summary.total_capital_call_in_user_currency / 1000, 0)}
      {' k' + MoondanceLayout.currencySymbol(userCurrency)}
    </span>
    <br />
    Total Open Commitment:{' '}
    <span style={{ float: 'right' }}>
      ={' '}
      {MoondanceLayout.formatNumber(DATA.summary.total_remaining_in_user_currency / 1000, 0)}
      {' k' + MoondanceLayout.currencySymbol(userCurrency)}
    </span>
  </div>
 
  <div
    className="pdf_left"
    style={{
      left: '110mm',
      padding: '5mm',
      top: '130mm',
      width: '98mm',
      fontSize: '14px',
      fontWeight: '450',
      border: `1px solid ${Variables.printColorScheme.actual_neutral}`,
      borderRadius: '6.2px',
      position: 'absolute',
    }}
  >
    Cash Contributed:{' '}
    <span style={{ float: 'right' }}>
      {MoondanceLayout.formatNumber(DATA.summary.total_capital_call_in_user_currency / 1000, 0)}
      {' k' + MoondanceLayout.currencySymbol(userCurrency)}
    </span>
    <br />
    Gain & Losses:{' '}
    <span style={{ float: 'right' }}>
      +{' '}
      {MoondanceLayout.formatNumber(DATA.summary.total_gain_losses_in_user_currency / 1000, 0)}
      {' k' + MoondanceLayout.currencySymbol(userCurrency)}
    </span>
    <br />
    Distributions:{' '}
    <span style={{ float: 'right' }}>
      -{' '}
      {MoondanceLayout.formatNumber(DATA.summary.total_distributed_in_user_currency / 1000, 0)}
      {' k' + MoondanceLayout.currencySymbol(userCurrency)}
    </span>
    <br />
    Current Net Asset Value:{' '}
    <span style={{ float: 'right' }}>
      = {MoondanceLayout.formatNumber(DATA.summary.total_nav_in_user_currency / 1000, 0)}
      {' k' + MoondanceLayout.currencySymbol(userCurrency)}
    </span>
  </div>
<div
    className="pdf_left"
    style={{
      left: '5mm',
      top: '175mm',
      width: '200mm',
      fontSize: '14px',
      fontWeight: '650',
      position: 'absolute',
    }}
  >
    NAV per Sector
  </div>
  <div
    className="pdf_left"
    style={{
      left: '5mm',
      top: '180mm',
      width: '200mm',
      height: '120mm',
      position: 'absolute',
    }}
  >
    <Chart option="nav_sectors" horizontal={true} hideControls={true} height={220} />
  </div>*/