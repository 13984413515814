
import React, { useState, useContext, useEffect } from "react";
import {
  Card, CardBody, CardTitle, Col, Row, Container, Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Select from "react-select";

import UseMoondanceApi from "../lib/api.js";
import { MoondanceContext } from '../../App.js';
import { buildAccountParameter, positionPerGroup, positionPerGroupGeographies, positionPerGroupSectors } from "../lib/formatData.js";
import loading_icon from "../../assets/images/loading_icon.gif";

import { Link } from 'react-router-dom';
import PositionsSummary from '../pages/lib_summary.js';

import MoondanceLayout from "../lib/layout.js";
import { set } from "lodash";
import { use } from "react";
const Summary = (props) => {

  const moondanceApi = UseMoondanceApi();
  document.title = "List Positions | Moondance";
  const { selectedAccountList } = useContext(MoondanceContext);
  const { selectedStatus } = useContext(MoondanceContext);

  const [selected_y, set_selected_y] = useState('asset_name');
  const [selected_x, set_selected_x] = useState('a');
  const [DATA, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);

  const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);




  useEffect(() => {
    async function fetchData() {
      //console.log(selected_y)
      var api_parameters = {
        account: buildAccountParameter(selectedAccountList),
        status: buildAccountParameter(selectedStatus),
        calculateIrr: true,
        userCurrency: userCurrency,
      }
      try {
        if (api_parameters.account && api_parameters.account.length > 0) {
          var returnData = {}
          var data = await moondanceApi.fetchPositionsList(api_parameters);

          //console.log('MoondanceApi.fetchPositionsList from /Positions', data)

          if (data.data.length > 0) {
            if (selected_y === 'geography') {
              returnData.byGeographies = await moondanceApi.fetchAssetsGeographiesList(api_parameters);
              returnData.byGeographies = returnData.byGeographies.data
              returnData.statsPerType = positionPerGroupGeographies(returnData, selected_y.id)

             
            
              console.log('returnData',  returnData.listPositions)
              


            } else   if (selected_x === 'geography') {
              returnData.byGeographies = await moondanceApi.fetchAssetsGeographiesList(api_parameters);
              returnData.byGeographies = returnData.byGeographies.data
              returnData.statsPerType = positionPerGroupGeographies(returnData, selected_y.id)


            /*  returnData.byGeographies.map((geo) => {
                let thisPosition = data.data.find((item) =>item.position_id === geo.position_id)
                this_position['geography']=
                data.data.       
            })*/ . 
              console.log('returnData',  returnData.listPositions)
              


              console.log('returnData', selected_y, selected_x,props.value , returnData)

            } else if (selected_y === 'sectors') {

              returnData.listPositions = data.data
              returnData.bySectors = await moondanceApi.fetchAssetsSectorsList(api_parameters);
              returnData.bySectors = returnData.bySectors.data
              returnData.statsPerType = positionPerGroupSectors(returnData, selected_y.id)

            } else {

              returnData.listPositions = data.data
              returnData.statsPerType = positionPerGroup(data.data, selected_x.id, selected_y)

            }



            setData(returnData)
          }
          setLoading(false)
        }
      } catch (error) {
        console.log(error);
      }

    }
    fetchData();
  }, [selected_y, selectedAccountList, selectedStatus, selected_x, lastUpdate]);

  useEffect(() => {
    if(props.col){
      set_selected_y(props.col)
    }
    if(props.row){
      set_selected_x(props.row)
    }
   
  }, [props.col,props.row,props.value]);


  return (
    <React.Fragment>


      {DATA.listPositions && (
        <Row>
          <PositionsSummary 
                data={DATA} 
                printMode={(props.printMode ? props.printMode : false)} 
                row= {(props.row ? props.row : null)}
                row_label= {(props.row_label ? props.row_label : null)}
                col= {(props.col ? props.col : null)}   
                paginationSize={(props.paginationSize ? props.paginationSize : false)}   
                defaultSort={(props.defaultSort ? props.defaultSort : { key: 'rowHeader', direction: 'ascending' })}
                value_key={(props.value ? props.value : null)}
          />
        </Row>
      )}
    </React.Fragment>
  );

};// 

export default Summary;
