
import React, { useState, useContext, useEffect } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button, Input, InputGroup, InputGroupAddon, InputGroupText,
    NavItem,
    NavLink,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { MoondanceContext } from '../../App';

import MoondanceLayout from "../lib/layout.js";
import MoondanceTable from "../lib/components/MoondanceTable.js";
import UseMoondanceApi from "../lib/api";
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Logs from '../admin/logs.js'
import Accounts from '../admin/accounts.js'

import CrudCustomerAccount from '../admin/crud_customer_account.js'
import CrudAccount from '../admin/crud_account.js'
import CrudUser from "../admin/crud_user.js";

const MoonBase = () => {

    let Navigate = useNavigate();

    const moondanceApi = UseMoondanceApi();
    const { darkMode } = useContext(MoondanceContext);
    const { lastUpdate } = useContext(MoondanceContext);
    const [activeTab, setactiveTab] = useState("Clients");
    const [userListKeyword, setUserListKeyword] = useState("");
    const [accountListKeyword, setAccountListKeyword] = useState("");
    const [customerAccountId, setCustomerAccountId] = useState(null);
    const [accountId, setAccountId] = useState(null);
    const [userId, setUserId] = useState(null);
    
    const [userKeyword, setUserKeyword] = useState("");
    const [clientListKeyword, setClientListKeyword] = useState("");
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };
    const [DATA, setDATA] = useState({ userList: [] })

    const [showAdminMenu, setShowAdminMenu] = useState(false);


    const fetchUsersList = async () => {
        const userlist = await moondanceApi.admin_get_users_list({ keyword: userListKeyword });
        if (userlist && userlist.data) {
            console.log('userData',userlist.data)
            setDATA({ userList: userlist.data })
        }
    };
    const fetchClientList = async () => {
        const clientList = await moondanceApi.admin_get_clients_list({ keyword: clientListKeyword });
        if (clientList && clientList.data) {
            setDATA({ clientList: clientList.data })
        }
    };
    const fetchUserProfile = async () => {
        const profile = await moondanceApi.getLoggedinUser();
        if (
            profile
            && profile.data
            && profile.data.access_level
            && profile.data.access_level === 'admin') {
            setShowAdminMenu(true);
        } else {
            setShowAdminMenu(false);
            Navigate('/dashboard')
        }
    };
    const fetchAccountList = async () => {
        const accountList = await moondanceApi.admin_get_accounts_list({
            keyword: accountListKeyword,
        });
        if (accountList && accountList.data) {
            console.log('account',accountList.data)
            setDATA({ accountList: accountList.data })
        }
    };

    useEffect(() => {
        fetchUserProfile();
        if (activeTab === "Users") {
            fetchUsersList();
        } else if (activeTab === "Clients") {
            fetchClientList();
        } else if (activeTab === "Accounts") {
            fetchAccountList();
        }
    }, [activeTab, userListKeyword, clientListKeyword, accountListKeyword]);




    return (
        <React.Fragment >
            {(showAdminMenu === true && (
                <div className={(darkMode ? 'page-content darkMode' : 'page-content')} >
                    <Container fluid={true}>
                        <Row style={{ marginBottom: '1em' }}>
                            <div className="flex">
                                <h1><i className="mdi mdi-desktop-mac-dashboard"></i> Admin Menu</h1>
                            </div>
                        </Row>
                        <hr />
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={(activeTab === 'Clients' ? "active nav-link " : "")}
                                    onClick={() => { toggle("Clients"); }}
                                >
                                    <i className="bx bxs-business me-1  me-1 align-middle"> </i>{" "}Clients
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={(activeTab === 'Users' ? "active nav-link " : "")}
                                    onClick={() => { toggle("Users"); }}
                                >
                                    <i className="mdi mdi-account me-1 align-middle"> </i>{" "}Users
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={(activeTab === 'Accounts' ? "active nav-link " : "")}
                                    onClick={() => { toggle("Accounts"); }}
                                >
                                    <i className="mdi mdi-account me-1 align-middle"> </i>{" "}Accounts
                                </NavLink>
                            </NavItem>


                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={(activeTab === 'Stats' ? "active nav-link " : "")}
                                    onClick={() => { toggle("Logs"); }}
                                >
                                    <i className="dripicons-home me-1 align-middle"> </i>{" "}Logs
                                </NavLink>
                            </NavItem>



                        </Nav>
                        <TabContent activeTab={activeTab} className="p-3">
                            <TabPane tabId="Users" id="Users">
                                <Row>

                                    <Card>
                                        <CardBody>
                                            <div class="form-group row">
                                                <label class="col-sm-1 col-form-label">Keyword :</label>
                                                <div class="col-sm-10">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        value={userListKeyword}
                                                        onChange={e => setUserListKeyword(e.target.value)}
                                                        style={{ maxWidth: '400px', marginLeft: '1em' }}
                                                    />
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Col xl={12} sm={12}>
                                        <Card>
                                            <CardBody>
                                                {(DATA.userList && DATA.userList.length > 0 ? (
                                                    <>


                                                        <MoondanceTable
                                                            key={`${DATA.userList}-${lastUpdate}`}
                                                            title="Users  List"
                                                            rows={DATA.userList}
                                                            defaultSort={{ key: 'last_name', direction: 'ascending' }}
                                                            paginationSize={25}
                                                            columns={[
                                                                {
                                                                    label: 'Id', field: 'id',
                                                                    customFormat: (row) => {
                                                                        return (<Link className="success" to={'/admin/users/' + row.id}>{row.id}</Link>)
                                                                    },


                                                                },
                                                                {
                                                                    label: 'Customer', field: 'customer_account_name',
                                                                    customFormat: (row) => {
                                                                        return (
                                                                            <>
                                                                                {(row.customer_account_name !== '' ?
                                                                                    <>
                                                                                        <Link className="success" to={'/admin/clients/' + row.customer_account}>{row.customer_account_name}</Link>
                                                                                    </>

                                                                                    : 'Undefined')}
                                                                            </>
                                                                        )
                                                                    },
                                                                },
                                                                {
                                                                    label: 'User',
                                                                    field: 'last_name',
                                                                    customFormat: (row) => {
                                                                        return (
                                                                            <>
                                                                                <Link className="success" to={'/admin/users/' + row.id}>{row.first_name} {row.last_name}</Link> &nbsp;
                                                                                <Link style={{ marginLeft: '0.5em' }} className="fas fa-edit" onClick={() => { setUserId(row.id); toggle("crudUser"); }}> </Link>
                                                                        
                                                                                {row.superadmin_access_level === 'admin' ? <><br/><span className="badge fs-6 bg-danger">Backoffice Access</span></> : ''}
                                                                                {row.confirmed === 0 ? <><br/><span className="badge fs-6 bg-warning">UNCONFIRMED</span></> : ''}
                                                                                <br />{row.email}
                                                                                <br />{row.tel}
                                                                            </>)
                                                                    }
                                                                },
                                                                {
                                                                    label: 'Active',
                                                                    field: 'active',
                                                                    customFormat: (row) => {
                                                                        return row.active ? <span class="badge fs-6 bg-success">Yes</span> : <span class="badge fs-6 bg-soft-dark">No</span>;
                                                                    }
                                                                },
                                                                {
                                                                    label: 'MFA',
                                                                    field: 'MFA_method',
                                                                    customFormat: (row) => {
                                                                        if (row.MFA_method === "TOTP") {
                                                                            return <span className="badge bg-success">TOTP</span>
                                                                        } else if (row.MFA_method === "EMAIL") {

                                                                            return <span className="badge bg-info">Email</span>
                                                                        } else {
                                                                            return <span className="badge bg-danger">NONE</span>
                                                                        }
                                                                    }

                                                                },
                                                                { label: 'currency', field: 'currency' },
                                                                { label: '#Positions', field: 'number_of_positions' },
                                                                {
                                                                    label: '#Accounts',
                                                                    field: 'number_of_accounts',
                                                                    customFormat: (row) => {
                                                                        if (row.number_of_accounts === 0) {
                                                                            return <span className="badge bg-danger">0</span>
                                                                        } else {
                                                                            return <>{row.number_of_accounts}</>
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    label: '#Admin Access',
                                                                    field: 'account_admin_access',
                                                                    customFormat: (row) => {
                                                                        if (row.account_admin_access > 0) {
                                                                            return <span className="badge bg-warning">{row.account_admin_access}</span>
                                                                        } else {
                                                                            return <>-</>
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    label: '#User Access',
                                                                    field: 'account_user_access',
                                                                    customFormat: (row) => {
                                                                        if (row.account_user_access > 0) {
                                                                            return <span className="badge bg-info">{row.account_user_access}</span>
                                                                        } else {
                                                                            return <>-</>
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    label: '#Read Access',
                                                                    field: 'account_read_access',
                                                                    customFormat: (row) => {
                                                                        if (row.account_read_access > 0) {
                                                                            return <span className="badge bg-info">{row.account_read_access}</span>
                                                                        } else {
                                                                            return <>-</>
                                                                        }
                                                                    }
                                                                },


                                                                { label: 'Login past 3 Months', field: 'number_of_login_past_3Months' },
                                                                { label: 'Login Last 30 days', field: 'number_of_login_past_30days' },
                                                                { label: 'Alerts Last 30 days', field: 'number_of_alert_past_30days' },


                                                            ]}


                                                        />
                                                    </>
                                                ) : < p>No Data</p>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="Clients" id="Clients">


                                <Row>
                                    <Card>
                                        <CardBody>
                                            <div class="form-group row">
                                                <label class="col-sm-1 col-form-label">Keyword :</label>
                                                <div class="col-sm-9">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        value={clientListKeyword}
                                                        onChange={e => setClientListKeyword(e.target.value)}
                                                        style={{ maxWidth: '400px', marginLeft: '1em' }}
                                                    />
                                                </div>
                                                <div class="col-sm-2">

                                                    <button className="btn btn-success" onClick={() => { setCustomerAccountId(null); toggle("createClient"); }}> <i className="bx bxs-business me-1  me-1 align-middle"> </i>{" "}Create Client</button>
                                                </div>
                                            </div>

                                        </CardBody>
                                    </Card>
                                    <Col xl={12} sm={12}>
                                        <Card>
                                            <CardBody>

                                                {(DATA.clientList && DATA.clientList.length > 0 ? (
                                                    <MoondanceTable
                                                        key={`${DATA.clientList}-${lastUpdate}`}
                                                        title="Clients list :"
                                                        rows={DATA.clientList}
                                                        defaultSort={{ key: 'company_name', direction: 'ascending' }}
                                                        paginationSize={25}
                                                        columns={[
                                                            { label: 'Id', field: 'id' },
                                                            {
                                                                label: 'Name', field: 'company_name',
                                                                customFormat: (row) => {
                                                                    return (
                                                                        <>
                                                                            <Link className="success" to={'/admin/clients/' + row.id}>{row.company_name}</Link>
                                                                            <Link style={{ marginLeft: '0.5em' }} className="fas fa-edit" onClick={() => { setCustomerAccountId(row.id); toggle("createClient"); }}> </Link>
                                                                        </>
                                                                    )
                                                                }
                                                            },
                                                            {
                                                                label: 'Contact', field: 'contact_last_name', customFormat: (row) => {
                                                                    return (
                                                                        <>{row.contact_first_name} {row.contact_last_name} &nbsp;
                                                                            <br />
                                                                           {row.contact_email}<br />
                                                                            {row.contact_tel}
                                                                        </>)
                                                                }
                                                            },



                                                            { label: '#Users', field: 'current_users_count' },
                                                            { label: '#Accounts', field: 'current_accounts_count' },
                                                            { label: '#Positions', field: 'current_positions_count' },

                                                            { label: 'Max Users', field: 'max_users_allowed' },
                                                            { label: 'Max Accounts', field: 'max_accounts_allowed' },
                                                            { label: 'Max Positions', field: 'max_positions_allowed' },


                                                        ]}


                                                    />
                                                ) : < p>No Data</p>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="Accounts" id="Accounts">
                                <Card>
                                    <CardBody>
                                        <div class="form-group row">
                                            <label class="col-sm-1 col-form-label">Keyword :</label>
                                            <div class="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    value={accountListKeyword}
                                                    onChange={e => setAccountListKeyword(e.target.value)}
                                                    style={{ maxWidth: '400px', marginLeft: '1em' }}
                                                />
                                            </div>
                                            <div class="col-sm-2">

                                                <button className="btn btn-success" onClick={() => { setAccountId(null); toggle("createAccount"); }}> <i className="bx bxs-business me-1  me-1 align-middle"> </i>{" "}Create Account</button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                {(DATA.accountList && DATA.accountList.length > 0 ?
                                    <MoondanceTable
                                        key={`accountList-${accountListKeyword}-${lastUpdate}`}
                                        title="Accounts list :"
                                        rows={DATA.accountList}
                                        defaultSort={{ key: 'company_name', direction: 'ascending' }}
                                        paginationSize={25}
                                        columns={[
                                            { label: 'Id', field: 'id' },

                                            {
                                                label: 'Active',
                                                field: 'active',
                                                customFormat: (row) => {
                                                    return row.active ? <span class="badge fs-6 bg-success">Yes</span> : <span class="badge fs-6 bg-soft-dark">No</span>;
                                                }
                                            },
                                            {
                                                label: 'Name',
                                                field: 'name',
                                                customFormat: (row) => {
                                                    return (
                                                        <>
                                                            {row.name}
                                                            <Link style={{ marginLeft: '0.5em' }} className="fas fa-edit" onClick={() => { setAccountId(row.id); toggle("createAccount"); }}> </Link>

                                                        </>
                                                    )
                                                }
                                            },
                                            {
                                                label: 'Customer', field: 'customer_account_name',
                                                customFormat: (row) => {
                                                    return (
                                                        <>
                                                            {(row.customer_account_name !== '' ?
                                                                <>
                                                                    <Link className="success" to={'/admin/clients/' + row.customer_account}>{row.customer_account_name}</Link>
                                                                </>

                                                                : 'Undefined')}
                                                        </>
                                                    )
                                                },
                                            },
                                            { label: 'Contact Email', field: 'contact_email' },
                                            { label: '#Users', field: 'number_of_users' },
                                            { label: '#Admin', field: 'number_of_admins' },
                                            { label: '# Open Positions', field: 'number_of_open_positions', showTotal: true },
                                            { label: '#Positions', field: 'number_of_positions', showTotal: true },
                                            {
                                                label: 'Admin Users',
                                                field: 'admin_user_id',
                                                customFormat: (row) => {
                                                    if (!row.admin_user_id) return null;
                                                    let admin_user_id_array = row.admin_user_id.split(",");
                                                    let admin_user_emails_array = row.admin_emails.split(",");

                                                    return admin_user_id_array.map((adminId, index) => {

                                                        return (
                                                            <Link
                                                                key={index}
                                                                className="success"
                                                                to={`/admin/users/${parseInt(adminId.trim())}`}
                                                                style={{ marginRight: '5px' }}
                                                            >
                                                                {admin_user_emails_array[index]}
                                                            </Link>

                                                        );
                                                    });
                                                },
                                                align: 'right',
                                                showTotal: false
                                            },
                                            { label: 'Created', field: 'date_created', customFormat: (row) => { return (row.date_created ? MoondanceLayout.timestampToDateString(row.date_created) : 'NA') }, },

                                        ]}
                                    />
                                    : < p>No Data</p>)}

                            </TabPane>
                            <TabPane tabId="Logs" id="Logs">

                                <Logs />

                            </TabPane>
                            <TabPane tabId="createClient" id="createClient">

                                <CrudCustomerAccount tab={activeTab} id={(customerAccountId ? customerAccountId : false)} />

                            </TabPane>
                            <TabPane tabId="createAccount" id="createAccount">

                                <CrudAccount id={(accountId ? accountId : false)} />

                            </TabPane>
                            <TabPane tabId="crudUser" id="crudUser">

                                    <CrudUser  id={(userId ? userId : false)} />

</TabPane>
                        </TabContent>
                    </Container>
                </div>
            ))}
        </React.Fragment>
    );
}
/*
{
    "id": 1767,
    "status": "",
    "date": "2023-09-28T11:13:39",
    "full_date": "28/09/2023 11:13",
    "timestamp": 1695892419,
    "event": "Fund NAV updated",
    "request": "Post /fund/70/nav",
    "user_ip": "10.244.21.198",
    "user_agent": "70",
    "user_id": 0,
    "asset_id": 0,
    "position_id": 0,
    "name": null,
    "email": null
}
*/


export default MoonBase;