import MoondanceLayout from "../lib/layout";
import Variables from '../lib/variables/variables.js';
import Format from '../lib/format.js';


let inactivityTimeout = 600000; // 10 minutes

function getStatusLabel(statusId) {
    const statusItem = MoondanceLayout.statusList.find((item) => item.id === statusId);
    return statusItem ? statusItem.label : statusId;
}




const customSelectPattern = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#0bb197' : 'white',
        color: state.isFocused ? 'white' : 'black',
    }),

};


function buildAccountParameter(e) {
    var selected = '';
    if (!Array.isArray(e)) {
        return false
    }
    e.forEach(element => {
        selected += element.value + ','
    });
    ////console.log(selected)
    return selected

}


function buildOwnerList(data_in) {
    ////console.log(data_in)
    if (data_in.data) {
        var data_out = []
        data_in.data.forEach((item) => {
            data_out.push({ value: item.id, label: item.n })
        })
        return data_out
    } else {
        return []
    }
}
function getGeographyList() {

    Variables.geographyList.sort(function (a, b) {
        var labelA = a.label.toUpperCase();
        var labelB = b.label.toUpperCase();

        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    });
    return Variables.geographyList
}
/*
const formatOptionLabel = ({ value, label, description }) => (
    <div style={{ flex: "10" }}>
        <div>{label}</div>
        <div style={{ fontSize: '80%', }}>{description}</div>

    </div>
);*/

function positionPerGroupType(dataSerie, y_key, group_by = false) {
    var statsPerType = []
    var returnData = []
    //console.log('positionPerGroupType', dataSerie);

    var data = dataSerie.listPositions;
    var typeArray = dataSerie.byType;

    const result = computeTypeSummary(typeArray, data, y_key, group_by);
    ////console.log('positionPerGroupGeographies', result);
    return result;
}
function computeTypeSummary(typeArray, data, y_key, group_by = false) {

    // Step 1: Create a mapping from position_id to its type
    const positionToType = {};
    if (typeArray.length === 0) {
        return false;
    }
    typeArray.forEach(type => {
        if (!positionToType[type.position_id]) {
            positionToType[type.position_id] = [];
        }
        positionToType[type.position_id].push(type);
    });

    // Assigning positions without type to Global and ensuring Global sums up to 100%
    data.forEach(position => {
        if (!positionToType[position.position_id]) {
            positionToType[position.position_id] = [{
                "position_id": position.position_id,
                "type_label": "Global",
                "asset_id": position.asset_id,
                "percentage": 100
            }];
        } else {
            let totalPercentage = positionToType[position.position_id].reduce((sum, type) => sum + type.percentage, 0);
            if (totalPercentage < 100) {
                positionToType[position.position_id].push({
                    "position_id": position.position_id,
                    "type_label": "Global",
                    "asset_id": position.asset_id,
                    "percentage": 100 - totalPercentage
                });
            }
        }
    });
    const statsPerType = {};
    let group_by_keys = []
    let x_keys = []
    for (const position of data) {

        for (const t of positionToType[position.position_id]) {
            if (!statsPerType[t.type_label]) {
                statsPerType[t.type_label] = {
                    x: t.type_label,
                    y: 0,
                    count: 0,
                };
            }
            const percentageFactor = t.percentage / 100;
            statsPerType[t.type_label]['count']++;
            statsPerType[t.type_label]['x'] = t.type_label;
            statsPerType[t.type_label]['y'] += parseFloat(position[y_key]) * percentageFactor;

            if (!x_keys.includes(t.type_label)) {
                x_keys.push(t.type_label)
            }

            if (group_by) {
                console.log('positionPerGroup', group_by, position[group_by])

                if (!statsPerType[t.type_label]['group_by']) {
                    statsPerType[t.type_label]['group_by'] = { id: t.type_label }
                }
                statsPerType[t.type_label]['group_by']['id'] = t.type_label
                statsPerType[t.type_label]['group_by'][position[group_by]] = (statsPerType[t.type_label]['group_by'][position[group_by]] || 0) + parseFloat(position[y_key]) * percentageFactor
                statsPerType[t.type_label]['group_by']['total'] = (statsPerType[t.type_label]['group_by']['total'] || 0) + parseFloat(position[y_key]) * percentageFactor

                if (!group_by_keys.includes(position[group_by])) {
                    group_by_keys.push(position[group_by])
                }
            }
        }
    }
    if (!group_by_keys.includes('total')) {
        group_by_keys.push('total')
    }


    let returnData = []
    returnData.x = []
    returnData.y = []
    returnData.sumY = 0
    returnData.x_keys = []
    if (group_by) {
        returnData.group_by_values = []
    }
    for (var key in statsPerType) {

        if (y_key === 'position_irr_in_user_currency') {
            statsPerType[key]['y'] = statsPerType[key]['y'] / statsPerType[key]['count']
        }
        if (y_key === 'position_tvpi_in_user_currency') {
            statsPerType[key]['y'] = 100 * statsPerType[key]['y'] / statsPerType[key]['count']
        }
        if (y_key === 'position_dpi_in_user_currency') {
            statsPerType[key]['y'] = 100 * statsPerType[key]['y'] / statsPerType[key]['count']
        }
        returnData.x.push(
            MoondanceLayout.assetType[statsPerType[key]['x']] ? MoondanceLayout.assetType[statsPerType[key]['x']].label : statsPerType[key]['x']
        )
        returnData.y.push(statsPerType[key]['y'])

        returnData.sumY += parseFloat(statsPerType[key]['y'])
        if (group_by) {
            returnData.group_by_values[statsPerType[key]['x']] = statsPerType[key]['group_by']
        }

    }
    //console.log('positionPerGroup', returnData)
    returnData.x_keys = x_keys
    if (group_by) {
        returnData.group_by_keys = ['id']
        returnData.group_by_keys.push(...group_by_keys);

    }
    return returnData

}

function positionPerGroupGeographies(dataSerie, y_key, group_by = false) {
    var statsPerType = []
    var returnData = []

    ////console.log('positionPerGroupGeographies', dataSerie,y_key)

    var data = dataSerie.listPositions;
    var geoArray = dataSerie.byGeographies;


    const result = computeGeographySummary(geoArray, data, y_key, group_by);
    return result;
}
function computeGeographySummary(geoArray, data, y_key, group_by = false) {

    // Step 1: Create a mapping from position_id to its geographies
    const positionToGeographies = {};
    if (geoArray.length === 0) {
        return false;
    }
    geoArray.forEach(geo => {
        if (!positionToGeographies[geo.position_id]) {
            positionToGeographies[geo.position_id] = [];
        }
        positionToGeographies[geo.position_id].push(geo);
    });

    // Assigning positions without geography to Global and ensuring Global sums up to 100%
    data.forEach(position => {
        if (!positionToGeographies[position.position_id]) {
            positionToGeographies[position.position_id] = [{
                "position_id": position.position_id,
                "geography_label": "Global",
                "asset_id": position.asset_id,
                "percentage": 100
            }];
        } else {
            let totalPercentage = positionToGeographies[position.position_id].reduce((sum, geo) => sum + geo.percentage, 0);
            if (totalPercentage < 100) {
                positionToGeographies[position.position_id].push({
                    "position_id": position.position_id,
                    "geography_label": "Global",
                    "asset_id": position.asset_id,
                    "percentage": 100 - totalPercentage
                });
            }
        }
    });
    ////console.log('computeGeographySummary', positionToGeographies)
    // Step 2: For each geography, compute the desired values
    const statsPerType = {};
    let group_by_keys = []
    let x_keys = []
    for (const position of data) {
        const geographies = positionToGeographies[position.position_id];

        for (const geo of geographies) {
            if (!statsPerType[geo.geography_label]) {
                statsPerType[geo.geography_label] = {
                    x: geo.geography_label,
                    y: 0,
                    count: 0,
                };
            }
            const percentageFactor = geo.percentage / 100;
            statsPerType[geo.geography_label]['count']++;
            statsPerType[geo.geography_label]['x'] = geo.geography_label;
            statsPerType[geo.geography_label]['y'] += parseFloat(position[y_key]) * percentageFactor;

            if (!x_keys.includes(geo.geography_label)) {
                x_keys.push(geo.geography_label)
            }

            if (group_by) {
                console.log('positionPerGroup', group_by, position[group_by])

                if (!statsPerType[geo.geography_label]['group_by']) {
                    statsPerType[geo.geography_label]['group_by'] = { id: geo.geography_label }
                }
                statsPerType[geo.geography_label]['group_by']['id'] = geo.geography_label
                statsPerType[geo.geography_label]['group_by'][position[group_by]] = (statsPerType[geo.geography_label]['group_by'][position[group_by]] || 0) + parseFloat(position[y_key]) * percentageFactor
                statsPerType[geo.geography_label]['group_by']['total'] = (statsPerType[geo.geography_label]['group_by']['total'] || 0) + parseFloat(position[y_key]) * percentageFactor

                if (!group_by_keys.includes(position[group_by])) {
                    group_by_keys.push(position[group_by])
                }
            }
        }
    }

    if (!group_by_keys.includes('total')) {
        group_by_keys.push('total')
    }


    let returnData = []
    returnData.x = []
    returnData.y = []

    returnData.x_keys = []
    if (group_by) {
        returnData.group_by_values = []
    }
    returnData.sumY = 0
    for (var key in statsPerType) {

        if (y_key === 'position_irr_in_user_currency') {
            statsPerType[key]['y'] = statsPerType[key]['y'] / statsPerType[key]['count']
        }
        if (y_key === 'position_tvpi_in_user_currency') {
            statsPerType[key]['y'] = 100 * statsPerType[key]['y'] / statsPerType[key]['count']
        }
        if (y_key === 'position_dpi_in_user_currency') {
            statsPerType[key]['y'] = 100 * statsPerType[key]['y'] / statsPerType[key]['count']
        }
        returnData.x.push(statsPerType[key]['x'])
        returnData.y.push(statsPerType[key]['y'])

        if (group_by) {
            returnData.group_by_values[statsPerType[key]['x']] = statsPerType[key]['group_by']
        }
        returnData.sumY += parseFloat(statsPerType[key]['y'])

    }
    returnData.x_keys = x_keys
    if (group_by) {
        returnData.group_by_keys = ['id']
        returnData.group_by_keys.push(...group_by_keys);

    }
    console.log('positionPerGroup', group_by, returnData)
    return returnData

}



function positionPerGroupSectors(dataSerie, y_key, group_by = false) {
    var statsPerType = []
    var returnData = []
    var data = dataSerie.listPositions;
    var sectorsArray = dataSerie.bySectors;
    const result = computeSectorsSummary(sectorsArray, data, y_key, group_by);
    ////console.log(result);
    return result;
}
function computeSectorsSummary(sectorsArray, data, y_key, group_by = false) {
    // Step 1: Create a mapping from position_id to its sectors
    const positionToSectors = {};
    ////console.log('computeSectorsSummary', sectorsArray)

    if (sectorsArray.length === 0) {
        return false;
    }
    sectorsArray.forEach(sector => {
        if (!positionToSectors[sector.position_id]) {
            positionToSectors[sector.position_id] = [];
        }

        if (MoondanceLayout.sectorList.find((item) => item.id === sector.sector_label)) {
            sector.sector_label = MoondanceLayout.sectorList.find((item) => item.id === sector.sector_label).label
        }
        positionToSectors[sector.position_id].push(sector);
    });

    // Assigning positions without sector to Global and ensuring Global sums up to 100%
    data.forEach(position => {
        if (!positionToSectors[position.position_id]) {
            positionToSectors[position.position_id] = [{
                "position_id": position.position_id,
                "sector_label": "Global",
                "asset_id": position.asset_id,
                "percentage": 100
            }];
        } else {
            let totalPercentage = positionToSectors[position.position_id].reduce((sum, sector) => sum + sector.percentage, 0);
            if (totalPercentage < 100) {
                positionToSectors[position.position_id].push({
                    "position_id": position.position_id,
                    "sector_label": "Global",
                    "asset_id": position.asset_id,
                    "percentage": 100 - totalPercentage
                });
            }
        }
    });
    //console.log('computeSectorsSummary', positionToSectors)
    // Step 2: For each geography, compute the desired values
    const statsPerType = {};
    let group_by_keys = []
    let x_keys = []
    for (const position of data) {
        const sectors = positionToSectors[position.position_id];
        for (const sect of sectors) {
            if (!statsPerType[sect.sector_label]) {
                statsPerType[sect.sector_label] = {
                    x: (MoondanceLayout.sectorList[sect.sector_label] ? MoondanceLayout.sectorList[sect.sector_label] : sect.sector_label),
                    y: 0,
                    count: 0,
                };
            }
            const percentageFactor = sect.percentage / 100;
            statsPerType[sect.sector_label]['count']++;
            statsPerType[sect.sector_label]['x'] = (MoondanceLayout.sectorList[sect.sector_label] ? MoondanceLayout.sectorList[sect.sector_label] : sect.sector_label);
            statsPerType[sect.sector_label]['y'] += parseFloat(position[y_key]) * percentageFactor;
            if (group_by) {
                console.log('positionPerGroup', group_by, position[group_by])

                if (!statsPerType[sect.sector_label]['group_by']) {
                    statsPerType[sect.sector_label]['group_by'] = { id: sect.sector_label }
                }
                statsPerType[sect.sector_label]['group_by']['id'] = sect.sector_label
                statsPerType[sect.sector_label]['group_by'][position[group_by]] = (statsPerType[sect.sector_label]['group_by'][position[group_by]] || 0) + parseFloat(position[y_key]) * percentageFactor
                statsPerType[sect.sector_label]['group_by']['total'] = (statsPerType[sect.sector_label]['group_by']['total'] || 0) + parseFloat(position[y_key]) * percentageFactor

                if (!group_by_keys.includes(position[group_by])) {
                    group_by_keys.push(position[group_by])
                }
            }
        }
    }
    if (!group_by_keys.includes('total')) {
        group_by_keys.push('total')
    }
    //console.log('positionPerGroup', statsPerType)
    // Step 2: For each geography, compute the desired values
    const result = {};

    let returnData = []
    returnData.x = []
    returnData.y = []
    returnData.sumY = 0
    returnData.x_keys = []
    if (group_by) {
        returnData.group_by_values = []
    }
    for (var key in statsPerType) {
        ////console.log('positionPerGroup',y_key, statsPerType[key]['y'], statsPerType[key]['count'])

        if (y_key === 'position_irr_in_user_currency') {
            statsPerType[key]['y'] = statsPerType[key]['y'] / statsPerType[key]['count']
        } else if (y_key === 'position_tvpi_in_user_currency') {
            ////console.log('positionPerGroup', statsPerType[key]['y'], statsPerType[key]['count'])
            statsPerType[key]['y'] = statsPerType[key]['y'] / statsPerType[key]['count']
        } else if (y_key === 'position_dpi_in_user_currency') {
            statsPerType[key]['y'] = 100 * statsPerType[key]['y'] / statsPerType[key]['count']
        }
        returnData.x.push(statsPerType[key]['x'])
        returnData.y.push(statsPerType[key]['y'])
        if (group_by) {
            returnData.group_by_values[statsPerType[key]['x']] = statsPerType[key]['group_by']
        }
        returnData.sumY += parseFloat(statsPerType[key]['y'])

    }
    returnData.x_keys = x_keys
    if (group_by) {
        returnData.group_by_keys = ['id']
        returnData.group_by_keys.push(...group_by_keys);

    }
    //console.log('positionPerGroup', returnData)
    return returnData
}

function positionPerGroup(data, x_key = 'asset_vintage_year', y_key,group_by = false) {
    let statsPerType = {};
    const newStatsPerType = {
        x: [],
        y: [],
        sumY: 0,
        sumY2: 0
    };
    let group_by_keys = []
    let x_keys = []
    //console.log('positionPerGroup Line 376',newStatsPerType,x_key, y_key, y2, y3, y4);
    data.forEach((item) => {
        if (item[x_key] === '') {
            item[x_key] = 'Unknown';
        }

        if (!statsPerType[item[x_key]]) {
            statsPerType[item[x_key]] = {
                x: '',
                y: 0,
                count: 0,
                sum: 0
            };
        }

        if (x_key === 'asset_type') {
            statsPerType[item[x_key]]['x'] = MoondanceLayout.assetType[item[x_key]]?.label || item[x_key];
        } else {
            statsPerType[item[x_key]]['x'] = item[x_key];
        }

        statsPerType[item[x_key]]['count']++;
        statsPerType[item[x_key]]['sum'] += item['position_commited_in_user_currency_at_inception_rate'];

        if (y_key.absoluteValue) {
            item[y_key.id] = Math.abs(item[y_key.id]);
        }

        if (y_key.show_average) {
            statsPerType[item[x_key]]['y'] += parseFloat(item[y_key.id]) * item['position_commited_in_user_currency_at_inception_rate'];
        } else {
            statsPerType[item[x_key]]['y'] += parseFloat(item[y_key.id]);
        }
        if (group_by) {
           if (!statsPerType[item[x_key]]['group_by']) {
                statsPerType[item[x_key]]['group_by'] = { id: item[y_key.id] }
            }
            //console.log('Line 523', group_by, item[group_by], item[y_key.id])
            statsPerType[item[x_key]]['group_by']['id'] = item[x_key]
            statsPerType[item[x_key]]['group_by'][item[group_by]] = ( statsPerType[item[x_key]]['group_by'][item[group_by]] || 0) + parseFloat(item[y_key.id]) 
            statsPerType[item[x_key]]['group_by']['total'] = (statsPerType[item[x_key]]['group_by']['total'] || 0) + parseFloat(item[y_key.id]) 

            if (!group_by_keys.includes(item[group_by])) {
                group_by_keys.push(item[group_by])
            }
        }

      
    });
    if (!group_by_keys.includes('total')) {
        group_by_keys.push('total')
    }
    //console.log('positionPerGroup Line 411', statsPerType);
    let returnData = {
        x: [],
        y: [],
        sumY: 0
    };
    returnData.x_keys = []
    if (group_by) {
        returnData.group_by_values = []
    }
   

    for (let key in statsPerType) {
        if (y_key.show_average) {
            statsPerType[key]['y'] /= statsPerType[key]['sum'];
        }

        returnData.x.push(statsPerType[key]['x']);
        returnData.y.push(statsPerType[key]['y']);
        returnData.sumY += parseFloat(statsPerType[key]['y']);
        if (group_by) {
            returnData.group_by_values[statsPerType[key]['x']] = statsPerType[key]['group_by']
        }
        if (group_by) {
            returnData.x_keys = x_keys
        }
        if (group_by) {
            returnData.group_by_keys = ['id']
            returnData.group_by_keys.push(...group_by_keys);
        }
    }

    // Fill gap if x_key is vintage_year
    if (x_key === 'asset_vintage_year') {


        //console.log('positionPerGroup Line 449', newStatsPerType);

        let minYear = Math.min(...returnData.x);
        let maxYear = Math.max(...returnData.x);

        let dataByYear = returnData.x.reduce((acc, year, index) => {
            acc[year] = (acc[year] || 0) + returnData.y[index];
            return acc;
        }, {});

       

        // Fill in missing years
        for (let year = minYear; year <= maxYear; year++) {
            newStatsPerType.x.push(year);

            if (dataByYear.hasOwnProperty(year)) {
                newStatsPerType.y.push(dataByYear[year]);
                newStatsPerType.sumY += parseFloat(dataByYear[year]);
                
            } else {
                newStatsPerType.y.push(0);
                
            }

           
        }
        if (group_by) {
            newStatsPerType.group_by_values = returnData.group_by_values
        }
        newStatsPerType.x_keys = x_keys
        if (group_by) {
            newStatsPerType.group_by_keys = ['id']
            newStatsPerType.group_by_keys.push(...group_by_keys);
    
        }
        //console.log('positionPerGroup Line 597',newStatsPerType);
        return newStatsPerType;
    }

    return returnData;
}


function buildAssetTypeSelectOptions() {

    const assetTypeArray = Object.keys(Variables.assetType).map((key) => {
        const { label, description } = Variables.assetType[key];
        return { value: key, label, description };
    });
    ////console.log(assetTypeArray)
    return assetTypeArray

}
function buildTableData(yearlyData, positionData) {
    var table = []
    var returnTable = []
    var currentYear = new Date().getFullYear()
    yearlyData.data.forEach((item) => {
        if (!table[item.y]) {
            table[item.y] = {
                year: item.y,
                newPosition: 0,
                commited: 0,
                capitalCall: 0,
                distribution: 0,
                balance: 0,
                expcapitalCall: 0,
                expdistribution: 0,
                expbalance: 0
            }
        }
        if (item.y < currentYear) {
            table[item.y].capitalCall += item.rcc_eur;
            table[item.y].distribution += item.rd_eur;
            table[item.y].balance += item.rcc_eur + item.rd_eur;
            table[item.y].expcapitalCall = 0;
            table[item.y].expdistribution = 0;
            table[item.y].expbalance = 0;

        } else if (item.y === currentYear) {

            if (item.rcc_eur < item.mcc_eur) {
                //console.log('buildTableData rcc < mcc', item)
            }
            table[item.y].capitalCall += item.rcc_eur;
            table[item.y].distribution += item.rd_eur;
            table[item.y].balance += item.rcc_eur + item.rd_eur;
            table[item.y].expcapitalCall += item.mcc_eur - item.rcc_eur;
            table[item.y].expdistribution += item.md_eur - item.rd_eur;
            table[item.y].expbalance += item.mcc_eur + item.md_eur;
        } else if (item.y > currentYear) {
            table[item.y].capitalCall = 0;
            table[item.y].distribution = 0;
            table[item.y].balance = 0;
            table[item.y].expcapitalCall += item.mcc_eur;
            table[item.y].expdistribution += item.md_eur;
            table[item.y].expbalance += item.mcc_eur + item.md_eur;
        }
    })
    for (var key in positionData) {
        if (table[positionData[key].asset_vintage_year]) {

            if (!table[positionData[key].asset_vintage_year]['newPosition']) {
                table[positionData[key].asset_vintage_year]['newPosition'] = 1
            } else {
                table[positionData[key].asset_vintage_year]['newPosition'] += 1
            }
            if (!table[positionData[key].asset_vintage_year]['commited']) {
                table[positionData[key].asset_vintage_year]['commited'] = positionData[key].position_commited_in_user_currency_at_inception_rate
            } else {
                table[positionData[key].asset_vintage_year]['commited'] += positionData[key].position_commited_in_user_currency_at_inception_rate
            }
        }
    }
    table.forEach((item) => {
        returnTable.push(item)
    })
    return (returnTable)
}
function buildperYear(data) {

    var returnData = []
    var currentYear = new Date().getFullYear()
    returnData['prevYear'] = { newFund: 0, committed: 0, capitalCall: 0, distribution: 0, balance: 0, expcapitalCall: 0, expdistribution: 0, expbalance: 0 }
    returnData['currentYear'] = { newFund: 0, committed: 0, capitalCall: 0, distribution: 0, balance: 0, expcapitalCall: 0, expdistribution: 0, expbalance: 0 }
    returnData['nextYear'] = { newFund: 0, committed: 0, capitalCall: 0, distribution: 0, balance: 0, expcapitalCall: 0, expdistribution: 0, expbalance: 0 }
    returnData['nextYear2'] = { newFund: 0, committed: 0, capitalCall: 0, distribution: 0, balance: 0, expcapitalCall: 0, expdistribution: 0, expbalance: 0 }
    returnData['futureYear'] = { newFund: 0, committed: 0, capitalCall: 0, distribution: 0, balance: 0, expcapitalCall: 0, expdistribution: 0, expbalance: 0 }


    data.forEach((item) => {

        if (item.y < currentYear) {
            returnData['prevYear'].capitalCall += item.rcc_eur;
            returnData['prevYear'].distribution += item.rd_eur;
            returnData['prevYear'].expcapitalCall = 0;
            returnData['prevYear'].expdistribution = 0;


        } else if (item.y === currentYear) {
            returnData['prevYear'].capitalCall += item.rcc_eur;
            returnData['prevYear'].distribution += item.rd_eur;
            returnData['currentYear'].expcapitalCall += item.mcc_eur - item.rcc_eur;
            returnData['currentYear'].expdistribution += item.md_eur - item.rd_eur;
            returnData['currentYear'].year = item.y;

        } else if (item.y === currentYear + 1) {

            returnData['nextYear'].capitalCall += item.rcc_eur;
            returnData['nextYear'].distribution += item.rd_eur;
            returnData['nextYear'].expcapitalCall += item.mcc_eur - item.rcc_eur;
            returnData['nextYear'].expdistribution += item.md_eur - item.rd_eur;
            returnData['nextYear'].year = item.y;
        } else if (item.y === currentYear + 2) {

            returnData['nextYear2'].capitalCall += item.rcc_eur;
            returnData['nextYear2'].distribution += item.rd_eur;
            returnData['nextYear2'].expcapitalCall += item.mcc_eur - item.rcc_eur;
            returnData['nextYear2'].expdistribution += item.md_eur - item.rd_eur;
            returnData['nextYear2'].year = item.y;
        } else if (item.y > currentYear + 2) {
            returnData['futureYear'].capitalCall = 0;
            returnData['futureYear'].distribution = 0;
            returnData['futureYear'].expcapitalCall += item.mcc_eur;
            returnData['futureYear'].expdistribution += item.md_eur;
        }
    })
    return returnData
}
function buildFlatSerie(data, name = null, currency = 'k€', convertToUserCurrency = true) {

    var flatSerie = { 'rcc': {}, 'rd': {}, 'md': {}, 'mcc': {}, 'total_d': 0, 'total_cc': 0 }
    var returnData = { balance: 0, expectedbalance: 0, serie: [], colors: [], min: 0, max: 0 }
    var currentYear = new Date().getFullYear()

    ////console.log(data.data)
    data.data.forEach((item) => {
        if (!flatSerie['rcc'][item.name]) { flatSerie['rcc'][item.name] = 0 }
        if (!flatSerie['rd'][item.name]) { flatSerie['rd'][item.name] = 0 }
        if (!flatSerie['md'][item.name]) { flatSerie['md'][item.name] = 0 }
        if (!flatSerie['mcc'][item.name]) { flatSerie['mcc'][item.name] = 0 }
        if (!flatSerie['mcc'][item.name]) { flatSerie['mcc'][item.name] = 0 }

        if (item.y < currentYear) {
            flatSerie['total_cc'] += (convertToUserCurrency ? item.rcc_eur : item.rcc_currency)
            flatSerie['total_d'] += (convertToUserCurrency ? item.rd_eur : item.rd_currency)

            flatSerie['rcc'][item.name] += (convertToUserCurrency ? item.rcc_eur : item.rcc_currency)
            flatSerie['rd'][item.name] += (convertToUserCurrency ? item.rd_eur : item.rd_currency)

        } else if (item.y === currentYear) {
            flatSerie['rcc'][item.name] += (convertToUserCurrency ? item.rcc_eur : item.rcc_currency)
            flatSerie['rd'][item.name] += (convertToUserCurrency ? item.rd_eur : item.rd_currency)
            flatSerie['md'][item.name] += (convertToUserCurrency ? item.md_eur - item.rd_eur : item.md_currency - item.rd_currency)
            flatSerie['mcc'][item.name] += (convertToUserCurrency ? item.mcc_eur - item.rcc_eur : item.mcc_currency - item.rcc_currency)

            flatSerie['total_cc'] += (convertToUserCurrency ? item.rcc_eur + item.mcc_eur : item.rcc_currency + item.mcc_currency)
            flatSerie['total_d'] += (convertToUserCurrency ? item.rd_eur + item.md_eur : item.rd_currency + item.md_currency)

        } else if (item.y > currentYear) {
            flatSerie['md'][item.name] += (convertToUserCurrency ? item.md_eur : item.md_currency)
            flatSerie['mcc'][item.name] += (convertToUserCurrency ? item.mcc_eur : item.mcc_currency)

            flatSerie['total_cc'] += (convertToUserCurrency ? item.mcc_eur : item.mcc_currency)
            flatSerie['total_d'] += (convertToUserCurrency ? item.mcc_eur : item.md_currency)

        }
    })


    returnData.expectedbalance = 0
    returnData.balance = 0
    returnData.max = 0
    returnData.min = 0

    for (const key in flatSerie['rcc']) {
        returnData.balance += flatSerie['rcc'][key];
        returnData.expectedbalance += flatSerie['rcc'][key];
        returnData.serie.push({ name: key + ' (rcc)', type: 'bar', data: [parseInt(flatSerie['rcc'][key] / 1000)] })
        returnData.colors.push("#ff3d60")
        returnData.min += flatSerie['rcc'][key];
    }
    for (const key in flatSerie['mcc']) {
        returnData.expectedbalance += flatSerie['mcc'][key];
        returnData.serie.push({ name: key + ' (mcc)', type: 'bar', data: [parseInt(flatSerie['mcc'][key] / 1000)] })
        returnData.colors.push("#ffd9d9")
        returnData.min += flatSerie['mcc'][key];
    }
    for (const key in flatSerie['rd']) {
        returnData.balance += flatSerie['rd'][key];
        returnData.expectedbalance += flatSerie['rd'][key];
        returnData.serie.push({ name: key + ' (rd)', type: 'bar', data: [parseInt(flatSerie['rd'][key] / 1000)] })
        returnData.colors.push("#089a5d")
        returnData.max += flatSerie['rd'][key];
    }
    for (const key in flatSerie['md']) {
        returnData.expectedbalance += flatSerie['md'][key];
        returnData.serie.push({ name: key + ' (MD)', type: 'bar', data: [parseInt(flatSerie['md'][key] / 1000)] })
        returnData.colors.push("#e8f4ec")
        returnData.max += flatSerie['md'][key];
    }

    returnData.annotations = [

        {
            y: 'Total',
            x: parseInt(returnData.expectedbalance / 1000),

            marker: {
                size: 4,
                shape: 'circle',
                fillColor: '#FFFFFF'
            },
            label: {
                offsetX: 0,
                offsetY: -10,
                text: 'Expected Balance :' + (returnData.expectedbalance ? parseInt(returnData.expectedbalance / 1000).toLocaleString('fr-FR') : 0) + currency,
            }
        },
        {
            y: 'Total',
            x: parseInt(returnData.balance / 1000),

            marker: {
                size: 4,
                shape: 'circle',
                fillColor: '#000000'
            },
            label: {
                offsetX: 0,
                offsetY: -10,
                text: 'Current Balance :' + (returnData.balance ? parseInt(returnData.balance / 1000).toLocaleString('fr-FR') : 0) + currency,
            }
        },
        {
            y: 'Total',
            x: parseInt(returnData.min / 1000),

            marker: {
                size: 4,
                shape: 'square',
                fillColor: '#000000'
            },
            label: {
                offsetX: 0,
                offsetY: -10,
                text: (returnData.min ? parseInt(returnData.min / 1000).toLocaleString('fr-FR') : 0) + currency,
            }
        },
        {
            y: 'Total',
            x: parseInt(returnData.max / 1000),

            marker: {
                size: 4,
                shape: 'square',
                fillColor: '#FFFFFF'
            },
            label: {
                offsetX: 0,
                offsetY: -10,
                text: (returnData.max ? parseInt(returnData.max / 1000).toLocaleString('fr-FR') : 0) + currency,
            }
        }
    ];
    if (returnData.max > 2000000) {
        returnData.max = 1000 * (parseInt(returnData.max / 1000000) + 1);
        returnData.min = 1000 * (parseInt(returnData.min / 1000000) - 1);

    } else {
        returnData.max = 100 * (parseInt(returnData.max / 100000) + 1);
        returnData.min = 100 * (parseInt(returnData.min / 100000) - 1);

    }
    if (returnData.max > - returnData.min) {
        returnData.min = - returnData.max
    } else {
        returnData.max = -returnData.min; returnData.min = -returnData.max
    }
    return returnData;
}
function buildYearlyChart(data, name = null, currency = 'k€', convertToUserCurrency = true) {
    var currentYear = new Date().getFullYear()
    var dd = {}
    dd.label = [];
    var temp_series = {};
    var processed_series = {};
    var firstYear = currentYear;
    var lastYear = currentYear;
    var returnData = {}
    returnData.xlabel = []
    returnData.series = []
    returnData.colors = []
    returnData.seriesLabel = []
    returnData.flat = {}
    var balance_r = {}
    var balance_m = {}
    var balance_r_serie = []
    var annotations = []
    returnData.rawData = []
    data.data.forEach((item) => {
        if (name !== null) { item.name = name }

        if (!temp_series[item.name]) { temp_series[item.name] = [] }
        if (!temp_series[item.name][item.y]) { temp_series[item.name][item.y] = [] }
        if (!balance_m[item.y]) { balance_m[item.y] = 0 }
        if (!balance_r[item.y]) { balance_r[item.y] = 0 }
        temp_series[item.name][item.y]['rcc'] = (convertToUserCurrency ? item.rcc_eur : item.rcc_currency)
        temp_series[item.name][item.y]['rd'] = (convertToUserCurrency ? item.rd_eur : item.rd_currency)
        temp_series[item.name][item.y]['md'] = (convertToUserCurrency ? item.md_eur : item.md_currency)
        temp_series[item.name][item.y]['mcc'] = (convertToUserCurrency ? item.mcc_eur : item.mcc_currency)

        if (item.rcc_currency !== 0 || item.rd_currency !== 0 || item.md_currency !== 0 || item.mcc_currency !== 0) {
            if (item.y > currentYear - 50 && item.y < firstYear) { firstYear = item.y }
            if (item.y > lastYear) { lastYear = item.y }
        }

        if (item.y < currentYear) {
            balance_r[item.y] += (convertToUserCurrency ? item.rcc_eur + item.rd_eur : item.rcc_currency + item.rd_currency)
        } else if (item.y === currentYear) {
            balance_r[item.y] += (convertToUserCurrency ? item.rcc_eur + item.rd_eur : item.rcc_currency + item.rd_currency)
            balance_m[item.y] += (convertToUserCurrency ? item.mcc_eur + item.md_eur : item.mcc_currency + item.md_currency)
        } else if (item.y > currentYear) {
            balance_m[item.y] += (convertToUserCurrency ? item.mcc_eur + item.md_eur : item.mcc_currency + item.md_currency)
        }
    });


    ////console.log(temp_series)


    for (let y = firstYear; y <= lastYear; y++) {
        returnData.xlabel.push(y)
    }

    for (const key in temp_series) {
        processed_series[key + '_rcc'] = { data: [], type: 'bar', label: key + '(rcc)' }
        returnData.seriesLabel.push('<b>' + key + '</b><br>Capital Call')

        for (let y = firstYear; y <= lastYear; y++) {
            if (temp_series[key][y]) {
                processed_series[key + '_rcc'].data.push(temp_series[key][y]['rcc'] !== 0 ? parseInt(temp_series[key][y]['rcc'] / 1000) : null)
            } else {
                processed_series[key + '_rcc'].data.push(null);
            }

        }

        returnData.colors.push("#ff3d60")
    };
    for (const key in temp_series) {
        processed_series[key + '_mcc'] = { data: [], type: 'bar', label: key + '(mcc)' }
        returnData.seriesLabel.push('<b>' + key + '</b><br>Expected capital call')
        for (let y = firstYear; y <= lastYear; y++) {
            if (temp_series[key][y] && y === currentYear) {
                processed_series[key + '_mcc'].data.push(parseInt((temp_series[key][y]['mcc'] - temp_series[key][y]['rcc']) / 1000));

            } else if (temp_series[key][y] && y > currentYear) {
                processed_series[key + '_mcc'].data.push(temp_series[key][y]['mcc'] !== 0 ? parseInt(temp_series[key][y]['mcc'] / 1000) : null)

            } else {
                processed_series[key + '_mcc'].data.push(null);
            }
        }
        returnData.colors.push("#ffd9d9")
    };
    for (const key in temp_series) {
        processed_series[key + '_rd'] = { data: [], type: 'bar', label: key + '(rd)' }
        returnData.seriesLabel.push('<b>' + key + '</b><br>Distribution')

        for (let y = firstYear; y <= lastYear; y++) {
            if (temp_series[key][y]) {
                processed_series[key + '_rd'].data.push(temp_series[key][y]['rd'] !== 0 ? parseInt(temp_series[key][y]['rd'] / 1000) : null)
            } else {
                processed_series[key + '_rd'].data.push(null);
            }
        }
        //{["#ff3d60", "#eff2f7", "#089a5d", "#eff2f7"]}
        returnData.colors.push("#089a5d")
    };


    for (const key in temp_series) {
        processed_series[key + '_md'] = { data: [], type: 'bar', label: key + '(md)' }
        returnData.seriesLabel.push('<b>' + key + '</b><br>Expected Distribution')
        for (let y = firstYear; y <= lastYear; y++) {

            if (temp_series[key][y] && y > currentYear) {
                processed_series[key + '_md'].data.push(temp_series[key][y]['md'] !== 0 ? parseInt(temp_series[key][y]['md'] / 1000) : null)

            } else if (temp_series[key][y] && y === currentYear) {
                processed_series[key + '_md'].data.push(parseInt((temp_series[key][y]['md'] - temp_series[key][y]['rd'] > 0 ? (temp_series[key][y]['md'] - temp_series[key][y]['rd']) : 0)) / 1000);

            } else {
                processed_series[key + '_md'].data.push(null);
            }



        }
        returnData.colors.push("#e8f4ec")
    };
    for (const key in processed_series) {
        returnData.series.push(processed_series[key])
    }









    for (let y = firstYear; y <= lastYear; y++) {

        if (y < currentYear) {
            balance_r_serie.push(parseInt(balance_r[y] / 1000))
            annotations.push(
                {
                    x: y,
                    y: parseInt(balance_r[y] / 1000),

                    marker: {
                        size: 4,
                        shape: 'circle',
                        fillColor: '#000000'
                    },
                    tooltip: {
                        enabled: true,
                        custom: function ({ seriesIndex, dataPointIndex, w }) {
                            return '<p>Balance : ' + (balance_r[y] ? balance_r[y].toLocaleString('fr-FR', { style: "currency", currency: 'EUR', maximumFractionDigits: 0 }) : '0') + '</p>'
                        },
                        style: {
                            fontSize: '12px'
                        }
                    },

                    label: {
                        offsetX: -30,
                        offsetY: 15,
                        text: (balance_r[y] ? parseInt(balance_r[y] / 1000).toLocaleString('fr-FR') : '0') + currency,

                    }


                }


            );
        } else if (y === currentYear) {


            balance_r_serie.push(parseInt(balance_r[y] / 1000))
            annotations.push(
                {
                    x: y,
                    y: parseInt(balance_r[y] / 1000),

                    marker: {
                        size: 4,
                        shape: 'circle',
                        fillColor: '#000000'
                    },
                    tooltip: {
                        enabled: true,
                        custom: function ({ seriesIndex, dataPointIndex, w }) {
                            var returnValue = '<p>Balance : ' + (balance_r[y] ? balance_r[y].toLocaleString('fr-FR', { style: "currency", currency: 'EUR', maximumFractionDigits: 0 }) : '0') + '</p>'
                            return returnValue
                        },
                        style: {
                            fontSize: '12px'
                        }
                    },

                    label: {
                        offsetX: -30,
                        offsetY: 15,
                        text: (balance_r[y] ? parseInt(balance_r[y] / 1000).toLocaleString('fr-FR') : 0) + currency,
                    }

                }


            );
            annotations.push(
                {
                    x: y,
                    y: parseInt(balance_m[y] / 1000),

                    marker: {
                        size: 4,
                        shape: 'circle',
                        fillColor: '#FFFFFF'
                    },

                    label: {
                        offsetX: 30,
                        offsetY: 15,
                        text: (balance_m[y] ? parseInt(balance_m[y] / 1000).toLocaleString('fr-FR') : 0) + currency
                    }

                }


            );

        } else {

            annotations.push(
                {
                    x: y,
                    y: parseInt(balance_m[y] / 1000),

                    marker: {
                        size: 4,
                        shape: 'circle',
                        fillColor: '#FFFFFF'
                    },
                    tooltip: {
                        enabled: true,
                        custom: function ({ seriesIndex, dataPointIndex, w }) {

                            return '<p>Balance : ' + (balance_m[y] ? balance_m[y].toLocaleString('fr-FR', { style: "currency", currency: 'EUR', maximumFractionDigits: 0 }) : 0) + '</p>'
                        },
                        style: {
                            fontSize: '12px'
                        }
                    },
                    label: {
                        offsetX: 30,
                        offsetY: 15,
                        text: (balance_m[y] ? parseInt(balance_m[y] / 1000).toLocaleString('fr-FR') : 0) + currency
                    }


                }


            );
        }
    }

    returnData.annotations = annotations;
    /*returnData.series.push({ name: 'Real balance', type: 'line', data: balance_r_serie })
    returnData.seriesLabel.push('Real balance')
    returnData.colors.push("#000000")
*/
    return (returnData)

}
function buildSummaryData(data) {
    ////console.log('buildSummaryData',data)
    data.display = {}
    data.display = data.data
    data.display.currency = 'EUR'
    data.display.TotalFunds = data.data.o_f;
    data.display.TotalCommitted = parseInt(data.data.t_c_eur / 1000).toLocaleString('fr-FR');
    data.display.TotalCalled = parseInt(data.data.t_cc_eur / 1000).toLocaleString('fr-FR');
    data.display.TotalRemainingCallable = (parseInt(data.data.t_c_eur / 1000) - parseInt(data.data.t_cc_eur / 1000)).toLocaleString('fr-FR');
    data.display.TotalDistributed = parseInt(data.data.t_d_eur / 1000).toLocaleString('fr-FR', { maximumFractionDigits: 0 });
    data.display.TotalNav = parseInt(data.data.nav_eur / 1000).toLocaleString('fr-FR', { maximumFractionDigits: 0 });
    data.display.nav_pct_value = (data.data.nav_eur / (data.data.nav_eur + data.data.t_d_eur));
    data.display.nav_pct_called = (data.data.nav_eur / (data.data.t_cc_eur)).toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 0 })
    data.display.TotalGainLosses = parseInt((data.data.nav_eur - data.data.t_cc_eur + data.data.t_d_eur) / 1000).toLocaleString('fr-FR');
    data.display.tgl_pct = (data.data.nav_eur - data.data.t_cc_eur + data.data.t_d_eur) / data.data.t_cc_eur;
    data.display.irr = (data.data.position_irr ? data.data.position_irr.toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 2 }) : null)
    data.display.TVPI = parseFloat((data.data.nav_eur + data.data.t_d_eur) / data.data.t_cc_eur).toLocaleString('fr-FR', { maximumFractionDigits: 2 })
    data.display.MOIC = parseFloat((data.data.nav_eur + data.data.t_d_eur) / data.data.t_cc_eur).toLocaleString('fr-FR', { maximumFractionDigits: 2 })
    data.display.CCPCT = (data.data.t_cc_eur / data.data.t_c_eur).toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 2 });
    data.display.TotalCalledPct = parseFloat(data.data.t_cc_eur / data.data.t_c_eur).toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 0 })
    data.display.TotalCalledPct_value = parseFloat(data.data.t_cc_eur / data.data.t_c_eur)
    data.display.openCommitment = (1 - parseFloat(data.data.t_cc_eur / data.data.t_c_eur)).toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 0 })
    data.display.openExpectedCommitment = (1 - parseFloat(data.data.total_expected_remaining_in_user_currency)).toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 0 })
    data.display.nav_pct_value = (data.data.nav_eur / data.data.t_c_eur)
    data.display.dist_pct_called_value = (data.data.t_d_eur / (data.data.t_cc_eur));
    data.display.dist_pct_called = (data.data.t_d_eur / (data.data.t_cc_eur)).toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 0 })

    data.display.DPI_raw = parseFloat(100 * data.data.t_d_eur / data.data.t_cc_eur)
    data.display.nav_pct_raw = parseFloat(100 * data.data.nav_eur / data.data.t_cc_eur)
    data.display.IC = parseFloat(data.data.t_cc_eur / data.data.t_c_eur)
    data.display.AVGCOMMIT = parseFloat(data.data.t_c_eur / data.data.o_f)

    return (data.display)
}
function buildFundDetail(data) {

    data.display = data
    data.display.currency = data.cur
    data.display.irr = (data.position_irr).toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 2 })
    data.display.TVPI = (data.position_tvpi_in_currency).toLocaleString('fr-FR', { maximumFractionDigits: 2 })
    data.display.MOIC = parseFloat((data.position_nav + data.position_real_distribution_in_currency) / -data.position_real_capital_call_in_currency).toLocaleString('fr-FR', { maximumFractionDigits: 2 })
    data.display.totalCalled = (-data.position_real_capital_call_in_currency).toLocaleString('fr-FR', { style: "currency", currency: data.asset_currency, maximumFractionDigits: 0 })
    data.display.totalCalled_pct = parseInt(100 * (data.position_real_capital_call_in_currency / -data.position_commitment_in_asset_currency))
    data.display.totalCalled_pct_display = (data.position_real_capital_call_in_currency / -data.position_commitment_in_asset_currency).toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 2 })

    data.display.totalCommitted = (data.position_commitment_in_asset_currency).toLocaleString('fr-FR', { style: "currency", currency: data.display.asset_currency, maximumFractionDigits: 0 })
    data.display.totalCommitted_pct = parseFloat(-data.position_real_capital_call_in_currency / data.position_commitment_in_asset_currency).toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 0 })
    data.display.openCommitment_pct = (1 - parseFloat(-data.position_real_capital_call_in_currency / data.position_commitment_in_asset_currency))
    data.display.openCommitment_pct_display = data.display.openCommitment_pct.toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 0 })

    data.display.totalRemainingCallable = (data.position_commitment_in_asset_currency + data.position_real_capital_call_in_currency).toLocaleString('fr-FR', { style: "currency", currency: data.display.asset_currency, maximumFractionDigits: 0 })

    if (data.display.currency !== data.display.position_nav_currency) {

        if (data.display.position_nav_currency === 'EUR') {
            var totalNav = data.position_nav / data.last_xrate
            data.display.totalNav = (totalNav).toLocaleString('fr-FR', { style: "currency", currency: data.display.asset_currency, maximumFractionDigits: 0 })
            data.display.totalNav_eur = data.position_nav;
            data.display.navPctOfCalled_pct = (totalNav / -data.position_real_capital_call_in_currency).toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 0 })
            data.display.navPctOfCalled_pct_value = parseInt(100 * (totalNav / data.position_commitment_in_asset_currency))

        }
    } else {
        data.display.totalNav = (data.position_nav).toLocaleString('fr-FR', { style: "currency", currency: data.asset_currency, maximumFractionDigits: 0 })
        var totalNav_eur = data.position_nav * data.last_xrate
        data.display.totalNav_eur = totalNav_eur;//).toLocaleString('fr-FR', { style: "currency", currency: data.display.currency, maximumFractionDigits: 0 })
        data.display.navPctOfCalled_pct = (data.position_nav / -data.position_real_capital_call_in_currency).toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 0 })
        data.display.navPctOfCalled_pct_value = parseInt(100 * (data.position_nav / data.position_commitment_in_asset_currency))

    }

    data.display.dist_pct_called = (data.position_real_distribution_in_currency / -data.position_real_capital_call_in_currency).toLocaleString('fr-FR', { style: "percent", maximumFractionDigits: 0 })
    data.display.nav_pct_called = 0
    data.display.totalDistributed = (data.position_real_distribution_in_currency).toLocaleString('fr-FR', { style: "currency", currency: data.asset_currency, maximumFractionDigits: 0 })
    data.display.totalDistributed_pct = parseInt(100 * (data.position_real_distribution_in_currency / data.position_commitment_in_asset_currency))
    data.display.TVPI = (data.position_tvpi_in_currency).toLocaleString('fr-FR', { maximumFractionDigits: 2 })
    data.display.MOIC = parseFloat((data.position_nav + data.position_real_distribution_in_currency) / -data.position_real_capital_call_in_currency).toLocaleString('fr-FR', { maximumFractionDigits: 2 })
    data.display.totalGainLosses = parseInt((data.nav + data.position_real_capital_call_in_currency + data.position_real_distribution_in_currency)).toLocaleString('fr-FR', { style: "currency", currency: data.asset_currency, maximumFractionDigits: 0 })
    data.display.totalGainLosses_eur = parseInt(((data.position_nav * data.last_xrate) + data.position_real_capital_call_in_eur + data.position_real_distribution_in_eur)).toLocaleString('fr-FR', { style: "currency", currency: 'EUR', maximumFractionDigits: 0 })
    data.display.tgl_pct = parseInt(100 * (data.nav + data.position_real_capital_call_in_currency + data.position_real_distribution_in_currency) / -data.position_real_capital_call_in_currency);

    return (data.display)


}
function groupAndSortPositions(positions) {
    let grouped = {};
    ////console.log('positions',positions)
    positions.forEach(position => {
        const statusId = position.position_status_id;
        const accountName = position.position_account_name;

        if (!grouped[statusId]) {
            grouped[statusId] = {};
        }

        if (!grouped[statusId][accountName]) {
            grouped[statusId][accountName] = [];
        }

        grouped[statusId][accountName].push(position);
    });

    let output = [];
    // Get the status IDs and sort them
    const sortedStatusIds = Object.keys(grouped).sort((a, b) => parseInt(a) - parseInt(b));
    for (let statusId of sortedStatusIds) {
        for (let accountName in grouped[statusId]) {
            let options = grouped[statusId][accountName].sort((a, b) => a.asset_name.localeCompare(b.asset_name)).map(position => {
                return {
                    value: position.position_id,
                    label: position.asset_name,
                    description: position.asset_vintage_year + ' - ' + position.asset_currency + ' - ' + position.position_account_name + ' - ' + position.position_status_label,
                    user_can_edit: position.user_can_edit,
                    user_can_add_transaction: position.user_can_add_transaction,
                    user_can_update_nav: position.user_can_update_nav,

                };
            });

            output.push({
                label: `${accountName} - ${grouped[statusId][accountName][0].position_status_label} Positions`, // Using the first fund's status_label for the group label
                options: options
            });
        }
    }
    return output;
}


function buildSelectFundList(data) {
    var returnData = []
    data.forEach((item) => {
        returnData.push({ id: item.fund_id, label: item.fund_name, description: item.asset_vintage_year + ' - ' + item.fund_currency + ' - ' + item.fund_account_name })
    })
    return returnData
}
function buildCurrencyList(data = Variables.currencyList) {
    var return_data = []
    return_data.push({ id: "EUR", label: "EUR", description: 'Euro' })
    data.forEach((item) => {
        return_data.push({ id: item.id, value: item.id, label: item.id, description: item.description + (item.rate ? ' (' + formatNumber(item.rate, 4) + ' ' + item.id + '/EUR ' + timestampToddmmyyyy(item.timestamp) + ')' : '') })
    })
    return return_data;
}
function buildStatusList() {
    return MoondanceLayout.statusList
}
function buildTransactionTypeList() {
    return Variables.transactionType
}
function formatDate(date, format = 'dd/mm/yyyy') {
    ////console.log('formatDate', date)
    //check if date is a valid date object

    return MoondanceLayout.formatDate(date, format);
}

function echangeRatePerCurrency(data, from = 'EUR') {
    var return_data = []

    data.forEach((item) => {
        // //console.log(item)
        return_data.push({ id: item.id, description: item.description + ' (' + item.rate + ' ' + item.id + '/EUR)' })
    })
    return return_data;
}

function formatCurrency(value, currency = 'EUR') {

    return MoondanceLayout.formatCurrency(value, currency)

}
function formatPercentage(value, digits = 2) {

    return MoondanceLayout.formatPercentage(value, digits)
}
function formatDateToDMY(date) {
    let day = date.getDate();  // Get day
    let month = date.getMonth() + 1;  // Get month (getMonth() returns a value from 0-11)
    let year = date.getFullYear();  // Get full year

    // Ensure day and month are two digits
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    return day + '/' + month + '/' + year.toString();  // Convert to d/m/y format
}
function isValidDate(d) {
    return d instanceof Date && !isNaN(d.getTime());
}

function formatNumber(value, digits = 2) {
    return MoondanceLayout.formatNumber(value, digits)

}
function getTotal(data, key) {
    let total = 0;
    data.forEach((item) => {
        total += parseFloat(item[key]);
    });
    return total;
};

function getAverage(data, key, ignoreNull = false) {
    let total = 0;
    let count = 0;
    data.forEach((item) => {
        if (item[key]) {

            if (item[key] !== 0 || !ignoreNull) {
                total += parseFloat(item[key]);
                count++
            }
        }
    });
    return total / count;
};

function timestampToddmmyyyy(timestamp) {
    const date = new Date(timestamp * 1000);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;

}
function ddmmyyyyToDate(dateString) {
    // Split the date string into day, month, and year
    var parts = dateString.split('/');
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10) - 1; // Months are zero-based in JavaScript

    var year = parseInt(parts[2], 10);

    // Create a Date object with the transformed values
    var transformedDate = new Date(year, month, day);

    return transformedDate;

}
function formatDate_DMY_to_YMD(dateString) {
    var parts = dateString.split('/');
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    if (month < 10) {
        month = '0' + month;
    }
    var year = parseInt(parts[2], 10);
    var transformedDate = year + '-' + month + '-' + day;

    //console.log('transformedDate', transformedDate)
    return transformedDate;

}
function unixTimestampToDate(unixTimestamp, withHours = true) {
    const date = new Date(unixTimestamp * 1000); // Convert to milliseconds by multiplying by 1000

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed in JavaScript
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    if (withHours) {
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    } else {
        return `${day}/${month}/${year}`;
    }
}


function buildReachartsSerie(data) {
    var tempData = [];
    var serieNames = [];
    var returnData = {
        data: [],
        series: []
    };

    data.data.forEach((item) => {

        if (!tempData[item.y]) { tempData[item.y] = {} }

        tempData[item.y].name = item.y

        if (item.rcc_eur !== 0) {
            tempData[item.y][item.name + ' Capital Call'] = item.rcc_eur
            if (!serieNames[item.name + ' Capital Call']) {
                serieNames[item.name + ' Capital Call'] = {}
                serieNames[item.name + ' Capital Call'].name = item.name + '_rcc';
                serieNames[item.name + ' Capital Call'].fill = '#ff3d60';
                serieNames[item.name + ' Capital Call'].stack = 'a';
            }
        }
        if (item.rd_eur !== 0) {
            tempData[item.y][item.name + ' Distribution'] = item.rd_eur
            if (!serieNames[item.name + ' Distribution']) {
                serieNames[item.name + ' Distribution'] = {}
                serieNames[item.name + ' Distribution'].name = item.name + ' Distribution';
                serieNames[item.name + ' Distribution'].fill = '#089a5d';
                serieNames[item.name + ' Distribution'].stack = 'b';
            }
        }
        if (item.mcc_eur !== 0) {
            tempData[item.y][item.name + ' Expected Capital Call'] = item.mcc_eur
            if (!serieNames[item.name + ' Expected Capital Call']) {
                serieNames[item.name + ' Expected Capital Call'] = {}
                serieNames[item.name + ' Expected Capital Call'].name = item.name + ' Expected Capital Call';
                serieNames[item.name + ' Expected Capital Call'].fill = '#ffd9d9'
                serieNames[item.name + ' Expected Capital Call'].stack = 'a'

            }
        }
        if (item.md_eur !== 0) {
            tempData[item.y][item.name + ' Expected Distribution'] = item.md_eur
            if (!serieNames[item.name + ' Expected Distribution']) {
                serieNames[item.name + ' Expected Distribution'] = {}
                serieNames[item.name + ' Expected Distribution'].name = item.name + ' Expected Distribution';
                serieNames[item.name + ' Expected Distribution'].fill = '#e8f4ec'
                serieNames[item.name + ' Expected Distribution'].stack = 'b'
            }
        }

    })
    for (var key in tempData) {
        returnData.data.push(tempData[key]);
    }
    for (var serie in serieNames) {
        returnData.series.push(serieNames[serie]);
    }

    return returnData

}

function isValidEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex pattern
    return emailRegex.test(email);
}

function isPasswordStrong(password) {
    // Check if the password is at least 8 characters long
    if (password.length < 8) {
        return false;
    }

    // Check if the password contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
        return false;
    }

    // Check if the password contains at least one lowercase letter
    if (!/[a-z]/.test(password)) {
        return false;
    }

    // Check if the password contains at least one digit
    if (!/\d/.test(password)) {
        return false;
    }

    // Check if the password contains at least one special character (non-alphanumeric)
    if (!/[^A-Za-z0-9]/.test(password)) {
        return false;
    }

    // Check if the password is not a common word (optional)
    const commonWords = ['password', '123456', 'qwerty', 'abc123']; // Add more common words if needed
    if (commonWords.includes(password.toLowerCase())) {
        return false;
    }

    // If all checks passed, the password is strong enough
    return true;
}


function testurl(needle, haystack) {
    const regex = new RegExp(needle);
    return regex.test(haystack);
}

function setupInactivityRedirect(redirectURL = '/logout') {
    const TEN_MINUTES = 60 * 60 * 1000; // 60 minutes in milliseconds

    const resetTimeout = () => {
        // Clear the existing timeout
        clearTimeout(inactivityTimeout);

        // Set a new timeout
        inactivityTimeout = setTimeout(() => {
            var url = window.location.href;
            if (
                !testurl('login', url) &&
                !testurl('register', url) &&
                !testurl('forgot-password', url) &&
                !testurl('reset-password', url) &&
                !testurl('confirm-signup', url)

            ) {
                window.location.href = redirectURL;
            }
        }, TEN_MINUTES);
    };

    // Event listeners
    document.addEventListener('mousemove', resetTimeout);
    document.addEventListener('keydown', resetTimeout);
    document.addEventListener('click', resetTimeout);

    // Start the initial timeout
    resetTimeout();
}
const formatOptionLabel = (option, { context }) => {
    if (context === "menu") {
        return (
            <div>
                <div>{option.label}</div>
                {option.description ? <div style={{ fontSize: '80%', }}>{option.description}</div> : null}
            </div>); // customize your option label for the dropdown menu here
    }
    return <div>{option.label}</div>; // for singleValue (the label when an option is selected)
};
const groupStyles = {
    backgroundColor: '#212529',
    padding: '10px 15px',
    marginLeft: '-12px',
    marginRight: '-12px',
    fontSize: '1.25em',
    color: '#FFF',
    //... add more styles as per your design requirements
};
const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        {data.label}
    </div>
);

export {
    positionPerGroupType,
    customSelectPattern,
    unixTimestampToDate,
    positionPerGroupSectors,
    positionPerGroupGeographies,
    groupAndSortPositions,
    testurl,
    setupInactivityRedirect,

    getStatusLabel,
    buildperYear,
    isPasswordStrong,
    isValidEmail,
    buildReachartsSerie,
    buildAccountParameter,
    buildOwnerList,
    getGeographyList,
    echangeRatePerCurrency,
    formatOptionLabel,
    formatGroupLabel,
    positionPerGroup,
    ddmmyyyyToDate,
    buildAssetTypeSelectOptions,
    buildStatusList,
    formatDate,
    buildTransactionTypeList,
    buildCurrencyList,
    buildSelectFundList,
    buildFundDetail,
    buildSummaryData,
    buildTableData,
    buildFlatSerie,
    buildYearlyChart,
    formatCurrency,
    formatPercentage,
    getTotal,
    getAverage,
    formatNumber,
    timestampToddmmyyyy,
    formatDateToDMY,
    isValidDate,
    formatDate_DMY_to_YMD
}