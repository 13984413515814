import React, { useState, useContext, useEffect } from "react";
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Card, CardBody, Col, Row, InputGroup } from "reactstrap";
import UseMoondanceApi from "../lib/api";
import { MoondanceContext } from '../../App.js';
import { set } from "lodash";


const CrudCustomerAccount = (props) => {
    const moondanceApi = UseMoondanceApi();
    const { id } = props;

    const timestamp = Date.now();
    const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);
    const [submitResult, setSubmitResult] = useState(false);
    const { addAlert } = useContext(MoondanceContext);
    const [errors, setErrors] = useState({});
    const [clientData, setClientData] = useState({})
    const [defaultValues, setDefaultValues] = useState({
        max_accounts_allowed: 15,
        max_users_allowed: 15,
        max_positions_allowed: 150,
        price_plan_monthly_fee: 300,
        price_plan_user_included: 4,
        price_plan_account_included: 4,
        price_per_additional_user: 100,
        price_per_additional_account: 100,
        discount: 0,
    });

    async function fetchClientDetails(id) {
        const clientDetails = await moondanceApi.admin_get_client({ id: id });
        if (clientDetails && clientDetails.data) {
            setClientData(clientDetails.data)
            console.log('clientDetails', clientDetails.data)
        }
    }

    useEffect(() => {
        setClientData({})
        setSubmitResult(false)
    }, [lastUpdate]);
    useEffect(() => {
        if (id && id !== clientData.id) {
            setSubmitResult(false)
            fetchClientDetails(props.id)
        }
    }
        , [id]);

    function setVariable(key, value) {
        // console.log('setVariable', key, value)
        setClientData({ ...clientData, [key]: value })
    }

    function saveClient() {
        //console.log('saveClient', clientData)
        let requestData = clientData;

        if (clientData && clientData.id) {
            console.log('update client', clientData)
            moondanceApi.admin_update_client(requestData).then(response => {
                setErrors({})
                setSubmitResult(response)
                addAlert({
                    status: 'success',
                    title: 'Client Updated',
                    message: 'Client has been updated successfully'
                })
            })
                .catch(error => {
                    setErrors({})
                    setSubmitResult(error)
                    addAlert({
                        status: 'danger',
                        title: 'Client NOT Updated',
                        message: 'Client has NOT been updated successfully'
                    })
                });
        } else {
            moondanceApi.admin_create_client(requestData).then(response => {
                setErrors({})
                setSubmitResult(response)
                addAlert({
                    status: 'success',
                    title: 'Client created',
                    message: 'Client has been created successfully'
                })
            })
                .catch(error => {
                    setErrors({})
                    setSubmitResult(error)
                    addAlert({
                        status: 'danger',
                        title: 'Client NOT created',
                        message: 'Client has NOT been created successfully'
                    })
                });
        }

        setLastUpdate(timestamp)
    }

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col>
                        <h1>{(props && props.new ? 'Create' : 'Edit')} Client</h1>
                    </Col>
                </Row>

                {(!submitResult ?
                    <form >

                        
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Company Name</Form.Label >
                            <Col sm="4">

                                <input
                                    type="text"
                                    className="form-control "
                                    value={clientData.company_name}
                                    onChange={e => setVariable('company_name', e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Contact First Name</Form.Label >
                            <Col sm="4">

                                <input
                                    type="text"
                                    className="form-control "
                                    value={clientData.contact_first_name}
                                    onChange={e => setVariable('contact_first_name', e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Contact Last Name</Form.Label >
                            <Col sm="4">

                                <input
                                    type="text"
                                    className="form-control "
                                    value={clientData.contact_last_name}
                                    onChange={e => setVariable('contact_last_name', e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Contact Email</Form.Label >
                            <Col sm="4">

                                <input
                                    type="text"
                                    className="form-control "
                                    value={clientData.contact_email}
                                    onChange={e => setVariable('contact_email', e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Company Address</Form.Label >
                            <Col sm="4">

                                <input
                                    type="text"
                                    className="form-control "
                                    value={clientData.company_address_1}
                                    onChange={e => setVariable('company_address_1', e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Company Postcode</Form.Label >
                            <Col sm="4">

                                <input
                                    type="text"
                                    className="form-control "
                                    value={clientData.company_postcode}
                                    onChange={e => setVariable('company_postcode', e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Company City</Form.Label >
                            <Col sm="4">

                                <input
                                    type="text"
                                    className="form-control "
                                    value={clientData.company_city}
                                    onChange={e => setVariable('company_city', e.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Company Country</Form.Label >
                            <Col sm="4">

                                <input
                                    type="text"
                                    className="form-control "
                                    value={clientData.company_country}
                                    onChange={e => setVariable('company_country', e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Company VAT Number</Form.Label >
                            <Col sm="4">

                                <input
                                    type="text"
                                    className="form-control "
                                    value={clientData.company_vat}
                                    onChange={e => setVariable('company_vat', e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <hr />
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Maximum Accounts allowed</Form.Label >
                            <Col sm="4">
                                <  InputGroup   >
                                    <input
                                        type="number"
                                        className="form-control "
                                        defaultValue={defaultValues.max_accounts_allowed}
                                        value={clientData.max_accounts_allowed}
                                        onChange={e => setVariable('max_accounts_allowed', e.target.value)}
                                    />
                                    <span className="input-group-text" >Accounts</span>

                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Maximum Users allowed</Form.Label >
                            <Col sm="4">
                                <InputGroup>
                                    <input
                                        type="number"
                                        className="form-control"
                                        defaultValue={defaultValues.max_users_allowed}
                                        value={clientData.max_users_allowed}
                                        onChange={e => setVariable('max_users_allowed', e.target.value)}
                                    />
                                    <span className="input-group-text" >Users</span>
                                </InputGroup>

                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Maximum Positions allowed</Form.Label >
                            <Col sm="4">

                                <input
                                    type="number"
                                    className="form-control"
                                    defaultValue={defaultValues.max_positions_allowed}
                                    value={clientData.max_positions_allowed}
                                    onChange={e => setVariable('max_positions_allowed', e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <hr />
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Monthly fee</Form.Label >
                            <Col sm="4">
                                <InputGroup>

                                    <input
                                        type="number"
                                        className="form-control "
                                        defaultValue={defaultValues.price_plan_monthly_fee}
                                        value={clientData.price_plan_monthly_fee}
                                        onChange={e => setVariable('price_plan_monthly_fee', e.target.value)}
                                    />
                                    <span className="input-group-text" >€</span>
                                </InputGroup>


                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Users included in price plan</Form.Label >
                            <Col sm="4">
                                <InputGroup>

                                    <input
                                        type="number"
                                        className="form-control"
                                        defaultValue={defaultValues.price_plan_user_included}
                                        value={clientData.price_plan_user_included}
                                        onChange={e => setVariable('price_plan_user_included', e.target.value)}
                                    />
                                    <span className="input-group-text" >Users</span>
                                </InputGroup>


                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Accounts included in price plan</Form.Label >
                            <Col sm="4">
                                <InputGroup>

                                    <input
                                        type="number"
                                        className="form-control "
                                        defaultValue={defaultValues.price_plan_account_included}
                                        value={clientData.price_plan_account_included}
                                        onChange={e => setVariable('price_plan_account_included', e.target.value)}
                                    />
                                    <span className="input-group-text" >Accounts</span>
                                </InputGroup>


                            </Col>
                        </Form.Group>

                        <hr />
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Price per additional user /month </Form.Label >
                            <Col sm="4">
                                <InputGroup>

                                    <input
                                        type="number"
                                        className="form-control "
                                        defaultValue={defaultValues.price_per_additional_user}
                                        value={clientData.price_per_additional_user}
                                        onChange={e => setVariable('price_per_additional_user', e.target.value)}
                                    />
                                    <span className="input-group-text" >€/user/month</span>
                                </InputGroup>


                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Price per additional account /month </Form.Label >
                            <Col sm="4">
                                <InputGroup>

                                    <input
                                        type="number"
                                        className="form-control "
                                        defaultValue={defaultValues.price_per_additional_account}
                                        value={clientData.price_per_additional_account}
                                        onChange={e => setVariable('price_per_additional_account', e.target.value)}
                                    />
                                    <span className="input-group-text" >€/account/month</span>
                                </InputGroup>


                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="4">Discount (in %) </Form.Label >
                            <Col sm="4">
                                <InputGroup>

                                    <input
                                        type="number"
                                        className="form-control "
                                        defaultValue={defaultValues.discount}
                                        value={clientData.discount}
                                        onChange={e => setVariable('discount', e.target.value)}
                                    />
                                    <span className="input-group-text" >%</span>
                                </InputGroup>


                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Col sm="4">
                            </Col>
                            <Col sm="4">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => { saveClient() }}

                                >Save</button>
                            </Col>
                        </Form.Group>


                    </form>
                    :
                    <div>
                        <p>Client has been saved</p>
                        <button className="btn btn-outline-primary" onClick={() => { setSubmitResult(false); setClientData({}); }}>Create new Client</button>
                    </div>

                )}
            </CardBody>
        </Card>
    )

}

export default CrudCustomerAccount;

/*
{
    "id": 2,
    "company_name": "Beside You",
    "contact_first_name": "Charles-Henri",
    "contact_last_name": "Maus",
    "contact_email": "ch@besideyou.be",
    "company_address_1": "",
    "company_postcode": "",
    "company_city": "",
    "company_country": "",
    "last_update": "2024-09-03T14:33:39",
    "current_users_count": 7,
    "current_accounts_count": 16,
    "current_positions_count": 397,
    "max_accounts_allowed": 25,
    "max_users_allowed": 20,
    "max_positions_allowed": 25000
}
*/