import React, { useState, useEffect } from "react";
import * as XLSX from 'xlsx';
import UseMoondanceApi from "../../lib/api";
import {
  Card, CardBody, CardTitle, Col, Container, Button, Nav, Table,
  NavItem, NavLink, Row, TabContent, TabPane, Dropdown, UncontrolledAlert
} from "reactstrap";
import { useParams, Link, useNavigate } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import MoondanceLayout from '../../lib/layout.js';

export default function MoondanceTable(props) {
  const [sortConfig, setSortConfig] = useState(props.defaultSort || { key: null, direction: 'ascending' } );
 // const [useDefaultSort, setUseDefaultSort] = useState(true);
  const [pagination_size, set_pagination_size] = useState(props.paginationSize || 15);
  const [starting_position, set_starting_position] = useState(props.starting_position || 0);
  const [step, set_step] = useState(props.step || (props.paginationSize || 15));
  const [exportTriggered, setExportTriggered] = useState(false);
  const [prevStartingPosition, setPrevStartingPosition] = useState(null);
  const [prevPaginationSize, setPrevPaginationSize] = useState(null);
  const [key, setKey] = useState('table');
  const moondanceApi = UseMoondanceApi();
  const [ai_reply, setAiReply] = useState('');
  const [groupByField, setGroupByField] = useState(props.groupBy || null);
  const [sortedRows, setSortedRows] = useState([]);
  const [columns, setColumns] = useState([]);

  // Initial setup and data processing
  useEffect(() => {
   // console.log('MoondanceTable sortConfig',sortConfig);
    const initialRows = Object.values(props.rows || []);
    const initialColumns = props.columns || [];
    const processedRows = groupByField ? groupBy(groupByField, initialRows, initialColumns).newRows : initialRows;
    setSortedRows(sortRows(processedRows, sortConfig));
    setColumns(groupByField ? groupBy(groupByField, initialRows, initialColumns).newColumns : initialColumns);
  }, [props.rows, props.columns, groupByField, sortConfig]);

  // Set default sorting to groupByField when it changes
  useEffect(() => {
    if (groupByField) {
      setSortConfig({ key: groupByField, direction: 'ascending' });
    }
  }, [groupByField]);


  // Export handling
  useEffect(() => {
    if (exportTriggered) {
      exportToExcel(key);
      setExportTriggered(false);
      set_starting_position(prevStartingPosition);
      set_pagination_size(prevPaginationSize);
    }
  }, [exportTriggered, prevStartingPosition, prevPaginationSize]);

  // Table ID and AI reply setup
  useEffect(() => {
    setKey(props.tableId || Math.random().toString(36).substring(7));
    setAiReply('');
  }, [props.tableId]);

  const sortRows = (rows, sort) => {
    let sortableRows = [...rows];
    if (sort && sort.key !== null) {
      sortableRows.sort((a, b) => {
        let first = a[sort.key];
        let second = b[sort.key];
        if (first == null) return sort.direction === 'ascending' ? -1 : 1;
        if (second == null) return sort.direction === 'ascending' ? 1 : -1;
        if (typeof first === 'number' && typeof second === 'number') {
          return sort.direction === 'ascending' ? first - second : second - first;
        }
        first = String(first).toLowerCase();
        second = String(second).toLowerCase();
        if (first < second) return sort.direction === 'ascending' ? -1 : 1;
        if (first > second) return sort.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
    return sortableRows;
  };

  const handleExport = () => {
    setPrevStartingPosition(starting_position);
    setPrevPaginationSize(pagination_size);
    set_starting_position(0);
    set_pagination_size(sortedRows.length);
    setExportTriggered(true);
  };

  async function analyse_ai() {
    let table = document.getElementById(key);
    let tableArray = tableToTableData(table);
    setAiReply(<><span className="spinner-border text-primary" style={{ height: '1em', width: '1em', marginRight: '1em' }} role="status"></span> Waiting for AI to analyse this table ...</>);
    try {
      let new_ai_reply = await moondanceApi.ai_analyse({ data: tableArray });
      if (new_ai_reply?.data?.content) {
        setAiReply(new_ai_reply.data.content.replace(/\\n/g, "<br>"));
      } else {
        setAiReply('No data returned from AI');
      }
    } catch (error) {
      setAiReply('AI is not responding');
      console.log(error);
    }
  }

  const tableToTableData = (table) => {
    const tableArray = [];
    if (props.title) tableArray.push([props.title]);
    for (let row of table.rows) {
      const rowData = [];
      for (let cell of row.cells) {
        let cellValue = cell.innerText.trim();
        const format = cell.getAttribute('data-format');
        if (format === 'number' || format === 'percentage' || format === 'currency') {
          let numberValue = parseFloat(cellValue.replace(/[^0-9,-\s.]/g, '').replace(',', '.').replace(/\s/g, ''));
          if (!isNaN(numberValue)) {
            if (/[kK]/.test(cellValue)) numberValue *= 1000;
            rowData.push(numberValue);
          } else {
            rowData.push(cell.innerText);
          }
        } else {
          rowData.push(cell.innerText);
        }
      }
      tableArray.push(rowData);
    }
    return tableArray;
  };

  const exportToExcel = (tableId, fileName = 'table.xlsx') => {
    const table = document.getElementById(tableId);
    let tableArray = tableToTableData(table);
    const ws = XLSX.utils.aoa_to_sheet(tableArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, fileName);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    set_starting_position(0);
    setSortConfig({ key, direction });
  };

  const getSortDirectionIcon = (column) => {
    if (sortConfig && sortConfig.key === column.field) {
      return sortConfig.direction === 'ascending' ? <span className="mdi mdi-chevron-down"></span> : <span className="mdi mdi-chevron-up"></span>;
    }
    return null;
  };

  const getColumnTotal = (column) => {
    let total = 0;
    sortedRows.forEach(row => {
      total += parseFloat(row[column.field] || 0);
    });
    return total;
  };
  const groupBy = (groupby_field, rows, columns) => {
    let groupedRows = {};
    // Create new columns with hide property

    let countColumn = { field: 'count', label: 'Count', showTotal: true, hide: false, align: 'right' };
    
    let newColumns = columns.map(col => ({ ...col, hide: col.field !== groupby_field && !col.showTotal }));
    newColumns.unshift(countColumn);
    // Group the rows
    rows.forEach(row => {
      const groupKey = row[groupby_field] || 'Uncategorized';
      if (!groupedRows[groupKey]) {
        groupedRows[groupKey] = { [groupby_field]: `${groupKey}`, count: 1 };
      } else {
        groupedRows[groupKey].count += 1;
        groupedRows[groupKey][groupby_field] = `${groupKey}`;
      }
  
      columns.forEach(column => {
        if (column.field !== groupby_field && column.showTotal) {
          groupedRows[groupKey][column.field] = (groupedRows[groupKey][column.field] || 0) + parseFloat(row[column.field] || 0);
        }
      });
    });
  
    const newRows = Object.values(groupedRows);
  
    // Reorder columns to move the grouped column to the first position
    const groupByColumnIndex = newColumns.findIndex(col => col.field === groupby_field);

    if (groupByColumnIndex !== -1) {
      const [groupByColumn] = newColumns.splice(groupByColumnIndex, 1); // Remove the column
      newColumns.unshift(groupByColumn); // Add it to the beginning
    }
  
    return { newRows, newColumns };
  };
  return (
    <div className="table-responsive">
      {!props.printMode && (
        <>
          <Row className="d-flex justify-content-between align-items-center">
            {props.title && !props.hideTitle && (
              <Col xs={12} sm={6}>
                <h4 className="mb-3">{props.title}</h4>
              </Col>
            )}
            <Col xs={12} sm={!props.title ? 12 : 6} className="text-end">
              {pagination_size < sortedRows.length && (
                <Button className="btn btn-sm btn-outline-primary" style={{ marginRight: '0.5em' }} onClick={() => { set_starting_position(0); set_pagination_size(sortedRows.length); }}>
                  Show All
                </Button>
              )}
              {starting_position > 0 && (
                <Button className="btn btn-sm btn-outline-primary" style={{ marginRight: '0.5em' }} onClick={() => { set_starting_position(Math.max(starting_position - step, 0)); }}>
                  <span className="fas fa-chevron-left"></span> Previous {step}
                </Button>
              )}
              {starting_position + pagination_size < sortedRows.length && (
                <Button className="btn btn-sm btn-outline-primary" style={{ marginRight: '0.5em' }} onClick={() => { set_starting_position(starting_position + step); }}>
                  Next {step} <span className="fas fa-chevron-right"></span>
                </Button>
              )}
              {!props.hideExportButton && (
                <button className="btn btn-sm btn-outline-primary" onClick={handleExport}>
                  <i className="mdi mdi-microsoft-excel"></i> Export
                </button>
              )}
            </Col>
          </Row>
          <div className="d-flex bd-highlight">
            <div className="me-auto" style={{ marginBottom: "1em" }}></div>
            <div className="ms-auto" style={{ marginBottom: "1em" }}>
              {props.showAiButton && (
                <button className="btn btn-info" style={{ marginRight: '0.5em' }} onClick={analyse_ai}>
                  <i className="mdi mdi-robot"></i> Ask AI
                </button>
              )}
            </div>
          </div>
          {pagination_size < sortedRows.length && (
            <p className="me-auto">
              Showing {starting_position + 1} to {Math.min(starting_position + pagination_size, sortedRows.length)} of {sortedRows.length} entries
            </p>
          )}
          {ai_reply && (
            <UncontrolledAlert color="info">
              <h5>Asking AI:</h5>
              <p style={{ whiteSpace: 'pre-line' }}>{ai_reply}</p>
            </UncontrolledAlert>
          )}
        </>
      )}
      <Table id={key} className={props.printMode ? 'MoondanceTable_print table-centered' : 'MoondanceTable table-centered'} style={{ width: '100%', maxWidth: '100%' }}>
        <thead className="thead-light">
          <tr style={{ backgroundColor: MoondanceLayout.colorSchemesecondary_backgroundColor }}>
            {props.actions && <th></th>}
            {columns.map((column, index) => (
              !column.hide && (
                <th
                  key={index}
                  style={{ wordWrap: 'normal', width: column.width || '', textAlign: column.align || 'left', ...(column.style || {}) }}
                  className={column.className || ''}
                >
                  <div>
                  <span className={(column.align  ==='right' ? 'd-flex flex-row justify-content-end' : 'd-flex flex-row')}  >
                     {!props.printMode ? (
                      <Button className='btn btn-link' onClick={() => requestSort(column.field)}>
                        {column.label}{getSortDirectionIcon(column)}
                      </Button>
                    ) : (
                      column.label
                    )}
                    {column.allowGroupBy && !props.printMode && (
                      groupByField === column.field ? (
                        <Button onClick={() => setGroupByField(null)} className="btn btn-link">
                          <span className="mdi mdi-arrow-expand-all"></span>
                        </Button>
                      ) : (
                        <Button onClick={() => setGroupByField(column.field)} className="btn btn-link">
                          <span className="mdi mdi-arrow-collapse-all"></span>
                        </Button>
                      )
                    )}
                  </span>
                  </div>
                </th>
              )
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedRows.map((row, rowIndex) => (
            (rowIndex >= starting_position && rowIndex < starting_position + pagination_size) && (
              <tr key={rowIndex}>
                {props.actions && (
                  <td style={{ width: '100px' }}>
                    {props.actions.map((action, actionIndex) => {
                      const showAction = action.condition ? row[action.condition] === 1 : true;
                      return showAction ? (
                        <Button
                          key={actionIndex}
                          onClick={() => action.onClick && action.onClick(row)}
                          className="btn-sm btn-rounded waves-effect waves-light btn-outline-primary"
                        >
                          {action.icon && <span className={action.icon}></span>} {action.label}
                        </Button>
                      ) : null;
                    })}
                  </td>
                )}
                {columns.map((column, columnIndex) => (
                  !column.hide && (
                    <td
                      key={columnIndex}
                      style={{ width: column.width || '', textAlign: column.align || 'left', ...(column.style || {}) }}
                      className={column.className || ''}
                      data-format={column.format || undefined}
                    >
                      {column.customFormat
                        ? column.customFormat(row)
                        : (column.format
                          ? MoondanceLayout.formatValue(row[column.field], column.format, column.formatUnit || 2)
                          : row[column.field] || '-')}

                    </td>
                  )
                ))}
              </tr>
            )
          ))}
        </tbody>
        <tfoot>
          <tr key="total" style={{ backgroundColor: MoondanceLayout.colorSchemesecondary_backgroundColor }}>
            {props.actions && <th style={{ width: '100px' }}>Total</th>}
            {columns.map((column, columnIndex) => (
              !column.hide && (
                <th
                  key={columnIndex}
                  style={{ width: column.width || '', textAlign: column.align || 'left', ...(column.style || {}) }}
                  className={column.className || ''}
                >
                  {column.showTotal ? (
                    column.format
                      ? MoondanceLayout.formatValue(getColumnTotal(column), column.format, column.formatUnit || 2)
                      : getColumnTotal(column)
                  ) : ''}
                </th>
              )
            ))}
          </tr>
        </tfoot>
      </Table>
      {!props.printMode && (
        <Row>
          <div className="d-flex justify-content-end">
            {starting_position > 0 && (
              <Button className="btn btn-outline-primary" style={{ marginRight: '0.5em' }} onClick={() => set_starting_position(Math.max(starting_position - step, 0))}>
                <span className="fas fa-chevron-left"></span> Previous {step}
              </Button>
            )}
            {starting_position + pagination_size < sortedRows.length && (
              <Button className="btn btn-outline-primary" onClick={() => set_starting_position(starting_position + step)}>
                Next {step} <span className="fas fa-chevron-right"></span>
              </Button>
            )}
          </div>
        </Row>
      )}
    </div>
  );
}