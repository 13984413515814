
import React, { useState, useContext, useEffect } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { MoondanceContext } from '../../App';
import UseMoondanceApi from "../lib/api";

import MoondanceLayout from "../lib/layout.js";
import MoondanceTable from "../lib/components/MoondanceTable.js";
import Logs from '../admin/logs.js'
import Accounts from '../admin/accounts.js'
import { Link } from 'react-router-dom';
import { set } from "lodash";


const Clients = () => {
    let Navigate = useNavigate();

 
    const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
    const moondanceApi = UseMoondanceApi();
    const { lastUpdate } = useContext(MoondanceContext);
    const [showAdminMenu, setShowAdminMenu] = useState(false);
    const [clientData, setClientData] = useState({})
    const [userList, setUserList] = useState({})
    const [accountList, setAccountList] = useState({})
    const [positionsList, setPositionsList] = useState({})
    const [activeTab, setactiveTab] = useState("Users");
    const toggle = (tab) => { if (activeTab !== tab) { setactiveTab(tab); } };
    const params = useParams();
    const [numberOfActiveUsers, setNumberOfActiveUsers] = useState(0);
    const [numberOfActiveAccounts, setNumberOfActiveAccounts] = useState(0);
    const id = params.id;

    useEffect(() => {
        fetchClientDetails();
        fetchClientUserList();
        fetchClientAccountList();

    }, [lastUpdate, params, activeTab]);



    async function fetchClientDetails() {
        const clientDetails = await moondanceApi.admin_get_client({ id: params.id });
        if (clientDetails && clientDetails.data) {
            setClientData(clientDetails.data)
            console.log('client details ', clientDetails.data)
        }
    }
    async function fetchClientUserList() {
        const userList = await moondanceApi.admin_get_client_users({ id: params.id });
        if (userList && userList.data) {
            setUserList(userList.data)
            setNumberOfActiveUsers(userList.data.filter(user => user.active).length)
            //console.log('user list ', userList.data)
        }
    }
    async function fetchClientAccountList() {
        const accountList = await moondanceApi.admin_get_client_accounts({ id: params.id });
        if (accountList && accountList.data) {
            setAccountList(accountList.data)
            setNumberOfActiveAccounts(accountList.data.filter(account => account.active).length)
        }
    }
    async function fetchClientPositionsList() {
        const positionsList = await moondanceApi.admin_get_client_positions({ id: params.id });
        //console.log(positionsList)
        if (positionsList && positionsList.data) {
            setPositionsList(positionsList.data)
        }
    }
    return (
        <React.Fragment >

            <div className='page-content' >

                <Row style={{ marginBottom: '1em' }}>
                    <div className="flex">

                        <h1>
                            <i className="mdi mdi-desktop-mac-dashboard"></i> {clientData.company_name} </h1>
                        <Link className="btn btn-outline-primary" to={'/admin'}>Back to Admin home</Link>

                    </div>
                </Row>
                <hr />
                <Container fluid={true}>

                    <Card>
                        <CardBody>
                            <Row>
                                <h2>{clientData.company_name} </h2>
                                <Col xl={6} sm={12}>
                                    <p>
                                        Contact : {clientData.contact_first_name} {clientData.contact_last_name}<br />
                                        Email : {clientData.contact_email}<br />
                                        Company Name : {clientData.company_name} (ref:#{clientData.id})<br />
                                        Address : {clientData.company_address_1}<br />
                                        Postcode - City : {clientData.company_postcode} {clientData.company_city}<br />
                                        Country : {clientData.company_country}<br />
                                    </p>
                                </Col>
                                <Col xl={6} sm={12}>
                                    <p>
                                        Montlhy fee : {clientData.price_plan_monthly_fee} €<br />
                                        Included Users : {clientData.price_plan_user_included}<br />
                                        Included Accounts : {clientData.price_plan_account_included}<br />
                                        Price per additional User : {clientData.price_per_additional_user} €<br />
                                        Price per additional Account : {clientData.price_per_additional_account} €<br />
                                        Discount : {clientData.discount} % Discount on total<br />
                                    </p>
                                </Col>
                                </Row>
                                <hr/>
                                <Row>

                                <Col xl={6} sm={12}>
                                    <p>
                                        Number of Positions : {clientData.current_positions_count}<br />
                                        Number of Accounts (Portofolio) : {clientData.current_accounts_count} / {clientData.max_accounts_allowed} (Max)<br />
                                        Number of users (Login) : {clientData.current_users_count} / {clientData.max_users_allowed} (Max)<br />

                                        Number of Active Users : {numberOfActiveUsers}<br />
                                        Number of Active Accounts : {numberOfActiveAccounts}<br />

                                    </p>
                                </Col>
                                <Col xl={6} sm={12}>
                                    <p>
                                    


                                    </p>
                                </Col>

                                <Nav tabs>

                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={(activeTab === 'Users' ? "active nav-link " : "")}
                                            onClick={() => { toggle("Users"); }}
                                        >
                                            <i className="mdi mdi-account me-1 align-middle"> </i>{" "}Users
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={(activeTab === 'Accounts' ? "active nav-link " : "")}
                                            onClick={() => { toggle("Accounts"); }}
                                        >
                                            <i className="mdi mdi-account me-1 align-middle"> </i>{" "}Accounts
                                        </NavLink>
                                    </NavItem>

                                </Nav>
                                <Row>

                                    <TabContent activeTab={activeTab} className="p-3">
                                        <TabPane tabId="Users" id="Users">
                                            {(userList && userList.length > 0 ? (
                                                <MoondanceTable
                                                    key={`${userList}-${lastUpdate}`}
                                                    title="Users  List"
                                                    rows={userList}
                                                    defaultSort={{ key: 'last_name', direction: 'ascending' }}
                                                    paginationSize={25}
                                                    hideAiButton={true}
                                                    columns={[
                                                        {
                                                            label: 'Id', field: 'id',
                                                            customFormat: (row) => {
                                                                return (
                                                                    <Link className="success" to={'/admin/users/' + row.id}>{row.id}</Link>)
                                                            },


                                                        },
                                                        { label: 'Customer', field: 'customer_account_name' },
                                                        {
                                                            label: 'User',
                                                            field: 'last_name',
                                                            customFormat: (row) => {
                                                                return (
                                                                    <><Link className="success" to={'/admin/users/' + row.id}>{row.first_name} {row.last_name}</Link> &nbsp;
                                                                        {row.superadmin_access_level === 'admin' ? <span className="badge fs-6 bg-danger">Backoffice Access</span> : ''}
                                                                        {row.confirmed === 0 ? <span className="badge fs-6 bg-warning">UNCONFIRMED</span> : ''}
                                                                        <br />{row.email}
                                                                        <br />{row.tel}
                                                                    </>)
                                                            }
                                                        },
                                                        {
                                                            label: 'Active',
                                                            field: 'active',
                                                            customFormat: (row) => {
                                                                return row.active ? <span class="badge fs-6 bg-success">Yes</span> : <span class="badge fs-6 bg-soft-dark">No</span>;
                                                            }
                                                        },
                                                        {
                                                            label: 'MFA',
                                                            field: 'MFA_method',
                                                            customFormat: (row) => {
                                                                if (row.MFA_method === "TOTP") {
                                                                    return <span className="badge fs-6 bg-success">TOTP</span>
                                                                } else if (row.MFA_method === "EMAIL") {

                                                                    return <span className="badge fs-6 bg-info">Email</span>
                                                                } else {
                                                                    return <span className="badge fs-6 bg-danger">NONE</span>
                                                                }
                                                            }

                                                        },
                                                        { label: 'currency', field: 'currency' },
                                                        {
                                                            label: '#Admin Access',
                                                            field: 'account_admin_access',
                                                            customFormat: (row) => {
                                                                if (row.account_admin_access > 0) {
                                                                    return <span className="badge fs-6 bg-warning">{row.account_admin_access}</span>
                                                                } else {
                                                                    return <>-</>
                                                                }
                                                            }
                                                        },
                                                        {
                                                            label: '#User Access',
                                                            field: 'account_user_access',
                                                            customFormat: (row) => {
                                                                if (row.account_user_access > 0) {
                                                                    return <span className="badge fs-6 bg-info">{row.account_user_access}</span>
                                                                } else {
                                                                    return <>-</>
                                                                }
                                                            }
                                                        },
                                                        {
                                                            label: '#Read Access',
                                                            field: 'account_read_access',
                                                            customFormat: (row) => {
                                                                if (row.account_read_access > 0) {
                                                                    return <span className="badge fs-6 bg-info">{row.account_read_access}</span>
                                                                } else {
                                                                    return <>-</>
                                                                }
                                                            }
                                                        },
                                                        { label: '#Positions Access', field: 'number_of_positions' },
                                                        { label: 'Login past 3 Months', field: 'number_of_login_past_3Months' },
                                                        { label: 'Login Last 30 days', field: 'number_of_login_past_30days' },
                                                        { label: 'Alerts Last 30 days', field: 'number_of_alert_past_30days' },


                                                    ]}


                                                />
                                            ) : < p>No Data</p>
                                            )}
                                        </TabPane>
                                        <TabPane tabId="Accounts" id="Accounts">

                                            {(accountList && accountList.length > 0 ? (
                                                <MoondanceTable
                                                    key={`accountList-${lastUpdate}`}
                                                    title="Accounts list"
                                                    rows={accountList}
                                                    defaultSort={{ key: 'company_name', direction: 'ascending' }}
                                                    paginationSize={25}
                                                    hideAiButton={true}
                                                    columns={[
                                                        { label: 'Id', field: 'id' },
                                                        { label: 'Name', field: 'name' },
                                                        {
                                                            label: 'Active', field: 'active',
                                                            customFormat: (row) => {
                                                                return row.active ? <span class="badge fs-6 bg-success">Yes</span> : <span class="badge fs-6 bg-soft-dark">No</span>;
                                                            }

                                                        },
                                                        { label: '#Users', field: 'number_of_users' },
                                                        { label: '#Admin', field: 'number_of_admins' },
                                                        { label: '# Open Positions', field: 'number_of_open_positions', showTotal: true },
                                                        { label: '#Positions', field: 'number_of_positions', showTotal: true },
                                                        {
                                                            label: 'Admin Users',
                                                            field: 'admin_user_id',
                                                            customFormat: (row) => {
                                                                if (!row.admin_user_id) return null;
                                                                let admin_user_id_array = row.admin_user_id.split(",");
                                                                let admin_user_emails_array = row.admin_emails.split(",");

                                                                return admin_user_id_array.map((adminId, index) => {

                                                                    return (
                                                                        <Link
                                                                            key={index}
                                                                            className="success"
                                                                            to={`/admin/users/${parseInt(adminId.trim())}`}
                                                                            style={{ marginRight: '5px' }}
                                                                        >
                                                                            {admin_user_emails_array[index]}
                                                                        </Link>
                                                                    );
                                                                });
                                                            },
                                                            align: 'right',
                                                            showTotal: false
                                                        },
                                                        { label: 'Created', field: 'date_created', customFormat: (row) => { return (row.date_created ? MoondanceLayout.timestampToDateString(row.date_created) : 'NA') }, },

                                                    ]}
                                                />
                                            ) : < p>No Data</p>
                                            )}
                                        </TabPane>

                                    </TabContent>

                                </Row>
                            </Row>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>

    )

}



export default Clients;
