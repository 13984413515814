import * as XLSX from "xlsx";
import Variables from "../../lib/variables/variables";
import { t } from "i18next";



const fundType = Object.values(Variables.assetType).map((item, index) => ({
    id: index,
    value: item.label,
    label: item.label
}))

const ImportLib = {
    async handleFile(event) {
        return new Promise((resolve, reject) => {
            const file = event.target.files[0];
            if (!file) {
                reject(new Error("No file selected."));
                return;
            }

            const reader = new FileReader();

            reader.onload = (event) => {
                try {
                    const data = event.target.result;
                    const workbook = XLSX.read(data, { type: "binary" });

                    if (!workbook || !workbook.SheetNames || workbook.SheetNames.length === 0) {
                        reject(new Error("Invalid workbook structure."));
                        return;
                    }

                    console.log("File read successfully:", workbook);
                    resolve(workbook); // ✅ Corrected - now properly resolving with the workbook
                } catch (error) {
                    console.error("Error reading file:", error);
                    reject(error);
                }
            };

            reader.onerror = (error) => reject(error);
            reader.readAsBinaryString(file);
        });
    },

    async loadXlsSheet(workbook, sheetId, outputFormat = "json") {
        return new Promise((resolve, reject) => {
            try {
                if (!workbook || !workbook.Sheets) {
                    reject(new Error("Invalid or missing workbook."));
                    return;
                }

                if (!workbook.Sheets[sheetId]) {
                    reject(new Error(`Sheet "${sheetId}" not found.`));
                    return;
                }
                const worksheet = workbook.Sheets[sheetId];
                let returnData;
                if (outputFormat === "json") {
                    returnData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                } else if (outputFormat === "csv") {
                    returnData = XLSX.utils.sheet_to_csv(worksheet);
                } else {
                    reject(new Error(`Invalid output format: ${outputFormat}`));
                    return;
                }
                resolve(returnData);
            } catch (error) {
                reject(error);
            }
        });
    },

    importTypeList: [
        {
            id: 'position',
            value: 'position',
            label: 'Positions',
            keys: {
                asset_id: {
                    label: 'Asset Id',
                    formStyle: {
                        maxWidth: '18em',
                        minWidth: '10em'
                    },
                    type: 'int',
                    options: [],
                    required: true,
                    alternate: ['Id', 'ID', 'Position ID', 'assetId', 'asset_id'],
                    explanation: 'ID of the position, this can be left empty for new position (ID should match position ID on Moondance)'
                },
                subscription_date: {
                    label: 'Subscription Date',
                    formStyle: {
                        maxWidth: '8em',
                        minWidth: '8em'
                    },
                    type: 'dateString',
                    required: true,
                    alternate: ['Subscription Date', 'Subscription Date (dd/mm/yyyy)'],
                    explanation: 'Date of subscription  (Excel Timestamp, unix timestamp,dd/mm/yyyy or yyyy-mm-dd)'
                },

                shares: {
                    label: 'Shares',
                    type: 'float',
                    formStyle: {
                        maxWidth: '8em',
                        minWidth: '6em'
                    },
                    required: false,
                    alternate: ['Shares', 'Number of Shares'],
                    explanation: 'Number of shares or 0 (optional)'
                },
                commitment: {
                    label: 'Commitment',
                    type: 'float',
                    formStyle: { maxWidth: '8em', minWidth: '8em' },
                    required: true,
                    alternate: ['Commitment', 'Committed Amount'],
                    explanation: 'Committed amount must be numeric value'
                },

                status: {
                    label: 'Status',
                    type: 'int',
                    formStyle: { maxWidth: '8em', minWidth: '6em' },
                    required: false,
                    alternate: ['Status', 'Position Status'],
                    explanation: 'Status of the position, default is 1 (open)'
                },

                name: {
                    label: 'Name',
                    type: 'string',
                    formStyle: { maxWidth: '16em', minWidth: '14em' },
                    required: true,
                    alternate: ['Name', 'Asset Name'],
                    explanation: 'Name of the position'
                },

                description: {
                    label: 'Description',
                    type: 'string',
                    formStyle: { maxWidth: '14em', minWidth: '14em' },
                    required: false,
                    alternate: ['Description', 'Asset Description'],
                    explanation: 'Description of the position (optional)'
                },

                currency: {
                    label: 'Currency',
                    type: 'string',
                    formStyle: { maxWidth: '4em', minWidth: '4em' },
                    required: true,

                    options: Variables.currencyList,
                    alternate: ['Currency', 'Asset Currency'],
                    explanation: 'Position currency in international code (EUR, USD, GBP, ...)'
                },

                inception_date: {
                    label: 'Inception Date',
                    type: 'dateString',
                    formStyle: { maxWidth: '8em', minWidth: '8em' },
                    required: false,
                    alternate: ['Inception Date', 'Inception Date (dd/mm/yyyy)'],
                    explanation: 'Inception (Excel Timestamp, Unix timestamp or dd/mm/yyyy), default = subscription date'
                },

                first_year: {
                    label: 'Vintage',
                    type: 'int',
                    formStyle: { maxWidth: '5em', minWidth: '5em' },
                    required: false,
                    alternate: ['First Year', 'Vintage'],
                    explanation: 'First year of the position'
                },

                investment_period: {
                    label: 'Investment Period',
                    type: 'int',
                    formStyle: { maxWidth: '4em', minWidth: '4em' },
                    required: false,
                    alternate: ['Investment Period', 'Investment Period (years)'],
                    explanation: 'Investment period in years, default = 5'
                },

                detention_period: {
                    label: 'Detention Period',
                    type: 'int',
                    formStyle: { maxWidth: '4em', minWidth: '4em' },
                    required: false,
                    alternate: ['Detention Period', 'Detention Period (years)'],
                    explanation: 'Detention period in years, default = 5'
                },

                expected_drawdown: {
                    label: 'Expected Drawdown',
                    type: 'float',
                    formStyle: { maxWidth: '4em', minWidth: '4em' },
                    required: false,
                    alternate: ['Expected Drawdown', 'Expected Drawdown (%)'],
                    explanation: 'Expected drawdown in percentage (0 to 1), default = 1'
                },

                expected_irr: {
                    label: 'Expected IRR',
                    type: 'float',
                    formStyle: { maxWidth: '4em', minWidth: '4em' },
                    required: false,
                    alternate: ['Expected IRR', 'Expected IRR (%)'],
                    explanation: 'Expected IRR in percentage (0 to 1), default = 0.10'
                },

                expected_tvpi: {
                    label: 'Expected TVPI',
                    type: 'float',
                    formStyle: { maxWidth: '4em', minWidth: '4em' },
                    required: false,
                    alternate: ['Expected TVPI', 'Expected TVPI'],
                    explanation: 'Expected TVPI in multiple of invested capital, default = 1.60'
                },

                intermediate: {
                    label: 'Intermediate',
                    type: 'string',
                    formStyle: { maxWidth: '10em', minWidth: '10em' },
                    required: false,
                    alternate: ['Intermediate', 'Intermediate'],
                    explanation: 'Intermediate (optional)'
                },

                feederName: {
                    label: 'Feeder Name',
                    type: 'string',
                    formStyle: { maxWidth: '10em', minWidth: '10em' },
                    required: false,
                    alternate: ['name of feeder', 'feederName'],
                    explanation: 'Feeder Name (optional)'
                },

                feederType: {
                    label: 'Feeder Type',
                    type: 'string',
                    formStyle: { maxWidth: '10em', minWidth: '10em' },
                    required: false,
                    alternate: ['Type of feeder', 'Feeder', 'feederType'],
                    explanation: 'Feeder Type (optional)'
                },
                fundType: {
                    label: 'fund Type',
                    type: 'string',
                    formStyle: { maxWidth: '10em', minWidth: '10em' },
                    required: false,
                    options: fundType,
                    default: 'other',
                    multiple: true,
                    alternate: ['Type', 'Type of fund'],
                    explanation: 'Type of fund (optional)'
                }
            },

            prompt: `
                Using the provided  data at the end of this prompt, meticulously extract all relevant position information from the data array.
                Important Considerations:
                
                - Ensure every found position is included in the output, without prematurely stopping the analysis.
                - Maintain a high level of detail in the data parsing, focusing on accuracy and completeness.
                - Thoroughly process each entry in the data array.
                - Handle errors or uncertainties in data with detailed logging in the comment field of each transaction.
                - Do not omit any data, even if confidence in the analysis is low.
                - Continue processing until all transactions from the input data have been analyzed. Stop if the analyse takes more than 15 minutes.
                - DO NOT add other comment, ensuring all numeric values are formatted with two decimals and all data adhere to the defined structure.
            
                - Status must be selected from the ID in the following list  
                    { id: 1, label: "Open", value: 1, default: true, description: 'Additional calls and distribution are expected' },
                    { id: 4, label: "Liquidated", value: 4, default: false, description: 'NO additional calls or distribution are expected' },
                    { id: 2, label: "Draft", value: 2, default: false, description: 'Draft fund, will not be included in reporting' },
                    { id: 100, label: "Deleted", value: 100, default: false, description: 'Deleted, will not be included in reporting' },
            
                    - if position_id is not found in the list below, set it to 0
                    - If subscription_date  or inception_date ate not found but vintage (first_year) is found, set the inception_date to the first day of the first month of the vintage.
                    - irr should be a float value (0 to 1) and should be formatted with 2 decimals, if not found use 0.10
                    - irr should be a float value  and should be formatted with 2 decimals, if not found use 1.60
                    - Investment period and detention period should be integer value, if not found use 5
                    - Expected drawdown should be a float value (0 to 1) and should be formatted with 2 decimals, if not found use 1
                
                    Remember that we are looking for position details, NOT transactions. there should not be  several lines with the exact same name. 
                    The source data  might not be organized  in table or column and we might only find one position in a given sheet.
                    
                    - IMPORTANT : The reply should ONLY be a json array with the following format
                    {
                    success: true,
                    position: {  
                        content: Array with following columns: [{position_id,subscription_date,shares,commitment,status,name,description,currency,inception_date,first_year,investment_period,detention_period,expected_drawdown,expected_irr,expected_tvpi,intermediate,feederName,feederType}],
                        comment: Any comment you may have on this request, should be empty if no comment is needed,
                    },
                    cost:  the cost of the request in token
                    }

                IMPORTANT : Make sure to follow the instructions and the structure of the output data.
            `
        },
        {
            id: 'transaction',
            value: 'transaction',
            label: 'Transactions',
            keys: {
                date: {
                    label: 'date',
                    type: 'dateString',
                    formStyle: { maxWidth: '8em', minWidth: '6em' },
                    required: true,
                    alternate: ['Date', 'Transaction Date', 'Transaction Date (dd/mm/yyyy)'],
                    explanation: 'Date of transaction is required (Excel Timestamp, Unix timestamp, or dd/mm/yyyy)'
                },

                position_id: {
                    label: 'position_id',
                    type: 'int',
                    formStyle: { maxWidth: '12em', minWidth: '10em' },
                    required: true,
                    alternate: ['Position ID', 'ID'],
                    explanation: 'ID of the position',

                },

                /*positionName: {
                    label: 'positionName',
                    formStyle: { maxWidth: '15em', minWidth: '10em',},
                    type: 'string',
                    required: false,
                    alternate: ['Position Name', 'Asset Name'],
                    explanation: 'Name of the position (optional)'
                    
                },*/

                type: {
                    label: 'type',
                    type: 'string',

                    formStyle: { maxWidth: '10em', minWidth: '10em' },
                    required: true,
                    options: Variables.transactionType,
                    alternate: ['Type', 'Transaction Type'],
                    explanation: 'Transaction type must be selected from the value in the following list (capital_call;distribution;recallable;dividend;setup_fee;recalled_distributions)'
                },

                value: {
                    label: 'value',
                    type: 'float',

                    formStyle: { maxWidth: '5em', minWidth: '5em' },
                    required: true,
                    alternate: ['Value', 'Amount', 'Transaction Amount'],
                    explanation: 'Value must be a numeric value'
                },

                shares: {
                    label: 'shares',
                    type: 'float',

                    formStyle: { maxWidth: '5em', minWidth: '5em' },
                    required: false,
                    alternate: ['Shares', 'Number of Shares'],
                    explanation: 'Number of shares or 0 (optional)'
                },

                currency: {
                    label: 'currency',
                    type: 'string',
                    required: true,

                    formStyle: { maxWidth: '4em', minWidth: '4em' },
                    options: Variables.currencyList,
                    alternate: ['Currency', 'Transaction Currency'],
                    explanation: 'Transaction currency in international code (EUR, USD, GBP, ...)'
                },

                bank: {
                    label: 'bank',
                    type: 'string',

                    formStyle: { maxWidth: '6em', minWidth: '6em' },
                    required: false,
                    alternate: ['Bank', 'Bank Name'],
                    explanation: 'Name of the bank if available (optional)'
                },

                note: {
                    label: 'note',
                    type: 'string',

                    formStyle: { maxWidth: '10em', minWidth: '10em' },
                    required: false,
                    alternate: ['Note', 'Description'],
                    explanation: 'Transaction description (optional)'
                }
            },

            prompt: /*`
            At the bottom of this promot you will find an array od data in csv format, we are looking to format those data as a list of transaction in a json format.
the returned data must be in the following format: 
{
                    success: true,
                    position: {  
                        content: Array with following columns: [{position_id,date,position_name,type,value,shares,currency,bank,note,position_name}],
                        comment: Any comment you may have on this request, should be empty if no comment is needed,
                    },
                    cost:  the cost of the request in token
}
  Where :
                date (DD/MM/YYYY) Convert Excel timestamps to DD/MM/YYYY. Assume Excel's date origin is January 0, 1900, for conversion.
                position_id (Id of the position, if no position_id is explicitely supplied, search for the position name or the feeder name in the position list, and take the id of the first match, If no match is found, set position_id to 0);
                type (transaction type or null, must be selected from the value in the following list : capital_call;distribution;recallable;dividend;setup_fee;recalled_distributions)
                value (numeric value with 2 decimals);
                shares (number of shares or 0);
                currency (transaction currency);
                bank (name of the bank if available, should not be an integer, can be the same for all transactions if the document appear to be a bank statement)
                note (transaction description, you can use the any description found in the source data in full);
                comment (any comment you may habe on the transaction, should be empty if no comment is needed);
                position_name: name of the position, most often it is written in the transaction description in he source data
                - IMPORTANT :Ensure every transaction is included in the output, without prematurely stopping the analysis.
                - IMPORTANT: The response must ONLY contain CSV-formatted data with NO explanations, comments, or code blocks.
                `*/

            `
                Using the provided  data at the end of this prompt, meticulously extract all relevant transaction information from the data array. 
                    
                IMPORTANT Considerations:
                
                - IMPORTANT :Ensure every transaction is included in the output, without prematurely stopping the analysis.
                - Maintain a high level of detail in the data parsing, focusing on accuracy and completeness.
                - Thoroughly process each entry in the data array.
                - Handle errors or uncertainties in data with detailed logging in the comment field of each transaction.
                - Do not omit any data, even if confidence in the analysis is low.
                - Continue processing until all transactions from the input data have been analyzed. Stop if the analyse takes more than 15 minutes.
                - DO NOT add other comment, ensuring all numeric values are formatted with two decimals and all data adhere to the defined structure.
                - A negative value is a capital call (capital_call) a Recalled distributions (recalled_distributions) or a setup fee (setup_fee), value MUST be negative for this type of operation
                - A positive value is a distribution (distribution) a recallable (recallable) or a dividend (dividend), value MUST be positive for this type of operation
                - check the value of the transaction to determine the type of transaction, then the description to find what kind of transaction it is.
                - IMPORTANT : The reply should ONLY be a json array with the following format
                    {
                    success: true,
                    transaction: {  //if no transaction is found, set transaction to an empty array
                        content: Array with following columns: [{date,position_id,type,value,shares,currency,bank,note,}];,
                        comment: Any comment you may have on this request, should be empty if no comment is needed,
                    },
                    cost:  the cost of the request in token
                    }
                Where :
                date (DD/MM/YYYY) Convert Excel timestamps to DD/MM/YYYY. Assume Excel's date origin is January 0, 1900, for conversion.
                position_id (Id of the position, if no position_id is explicitely supplied, search for the position name or the feeder name in the position list, and take the id of the first match, If no match is found, set position_id to 0);
                type (transaction type or null, must be selected from the value in the following list : capital_call;distribution;recallable;dividend;setup_fee;recalled_distributions)
                value (numeric value with 2 decimals);
                shares (number of shares or 0);
                currency (transaction currency);
                bank (name of the bank if available, should not be an integer, can be the same for all transactions if the document appear to be a bank statement)
                note (transaction description, you can use the any description found in the source data in full);
                comment (any comment you may habe on the transaction, should be empty if no comment is needed);
                
                IMPORTANT : Make sure to follow the instructions and the structure of the output data.
      
    `
        },
        {
            id: 'nav',
            value: 'nav',
            label: 'Nav',
            keys: {
                position_id: {
                    label: 'position_id',
                    type: 'int',
                    formStyle: { maxWidth: '12em', minWidth: '10em' },
                    required: true,
                    alternate: ['Position ID', 'ID'],
                    explanation: 'ID of the position',

                },
                date: {
                    label: 'date',
                    type: 'dateString',
                    formStyle: { maxWidth: '8em', minWidth: '6em' },
                    required: true,
                    alternate: ['Date', 'Nav Date','nav_date', 'Nav Date (dd/mm/yyyy)'],
                    explanation: 'Date of transaction is required (Excel Timestamp, Unix timestamp, or dd/mm/yyyy)'
                },
                value: {
                    label: 'value',
                    type: 'float',

                    formStyle: { maxWidth: '5em', minWidth: '5em' },
                    required: true,
                    alternate: ['Value', 'NAV', 'Nav_value'],
                    explanation: 'Value must be a numeric value'
                },

                shares: {
                    label: 'shares',
                    type: 'float',

                    formStyle: { maxWidth: '5em', minWidth: '5em' },
                    required: false,
                    alternate: ['Shares', 'Number of Shares'],
                    explanation: 'Number of shares or 0 (optional)'
                },
               /* currency: {
                    label: 'currency',
                    type: 'string',
                    required: true,

                    formStyle: { maxWidth: '4em', minWidth: '4em' },
                    options: Variables.currencyList,
                    alternate: ['Currency', 'Transaction Currency'],
                    explanation: 'Transaction currency in international code (EUR, USD, GBP, ...)'
                },*/
            },
        }

        /*  { id: 'position', value: 'position', label: 'Positions', prompt: null },
           { id: 'nav', value: 'nav', label: 'Net Asset Values', prompt: null }*/
    ]





};

export default ImportLib;
