import React, { useState, useContext, useEffect } from "react";
import {    Card, Container, CardBody,CardTitle, Col, Row,} from "reactstrap";

import MoondanceLayout from "../../lib/layout";
import BarChart from "../../lib/charts/barchart";

import { MoondanceContext } from '../../../App';



function TransactionsGraph(props) {

    const [DATAPERGROUP, setDataPerGroup] = useState({});
    const [loading, setLoading] = useState(true);
    const [groupTransaction, setGroupTransaction] = useState('M');
    const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
    const [selectedCurrency, setSelectedCurrency] = useState(userCurrency);
    // Get the start date (24 months ago from now)
    const getStartDate = () => {
        const today = new Date();
        return new Date(today.setMonth(today.getMonth() - 18));
    };

    function groupTransactionsPerMonth(transactions) {
        //console.log('groupTransactionsPerMonth',transactions)
          
        const groupedTransactions = transactions.reduce((acc, transaction) => {
            const date = new Date(transaction.timestamp * 1000);
            const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
            if (!acc[yearMonth]) {
                acc[yearMonth] = { positive: 0, negative: 0 };
            }

            if (transaction.value_in_user_currency > 0) {
                    acc[yearMonth].positive += (selectedCurrency === userCurrency ? transaction.value_in_user_currency : transaction.value_in_transaction_currency) ;
            } else {
                acc[yearMonth].negative += (selectedCurrency === userCurrency ? transaction.value_in_user_currency :transaction.value_in_transaction_currency) ;
            }
            return acc;
        }, {});

        // Fill empty months
        let startDate = getStartDate();
        const endDate = new Date();

        while (startDate <= endDate) {
            const key = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}`;
            if (!groupedTransactions[key]) {
                groupedTransactions[key] = { positive: 0, negative: 0 };
            }
            startDate.setMonth(startDate.getMonth() + 1);
        }

        const sortedKeys = Object.keys(groupedTransactions).sort();
        // Build a sorted result
        const sortedTransactions = {};
        for (let key of sortedKeys) {
            sortedTransactions[key.substring(5,8)+'/'+key.substring(0,4)] = groupedTransactions[key];
        }
        return sortedTransactions;
    }
    function groupTransactionsPerQuarter(transactions) {
        const groupedTransactions = transactions.reduce((acc, transaction) => {
            const date = new Date(transaction.timestamp * 1000);
            const quarter = Math.ceil((date.getMonth() + 1) / 3);
            const yearQuarter = `${date.getFullYear()}-Q${quarter}`;

            if (!acc[yearQuarter]) {
                acc[yearQuarter] = { positive: 0, negative: 0 };
            }

            if (transaction.value_in_user_currency > 0) {
                acc[yearQuarter].positive += (selectedCurrency === userCurrency ? transaction.value_in_user_currency : transaction.value_in_transaction_currency);
            } else {
                acc[yearQuarter].negative += (selectedCurrency === userCurrency  ? transaction.value_in_user_currency : transaction.value_in_transaction_currency);
            }
            return acc;

        }, {});

        // Fill empty quarters
        let startDate = getStartDate();
        const endDate = new Date();

        while (startDate <= endDate) {
            const quarter = Math.ceil((startDate.getMonth() + 1) / 3);
            const key = `${startDate.getFullYear()}-Q${quarter}`;
            if (!groupedTransactions[key]) {
                groupedTransactions[key] = { positive: 0, negative: 0 };
            }
            startDate.setMonth(startDate.getMonth() + 3); // Increment by 3 months to move to the next quarter
        }

        // Extract keys and sort them
        const sortedKeys = Object.keys(groupedTransactions).sort();

        // Build a sorted result
        const sortedTransactions = {};
        for (let key of sortedKeys) {
            sortedTransactions[key.substring(5,8)+'/'+key.substring(0,4)] = groupedTransactions[key];
        }

        return sortedTransactions;
    }

    function groupTransactionsPerYear(transactions) {
        const groupedTransactions = transactions.reduce((acc, transaction) => {
            const date = new Date(transaction.timestamp * 1000);
            const year = date.getFullYear();

            if (!acc[year]) {
                acc[year] = { positive: 0, negative: 0 };
            }

            if (transaction.value_in_user_currency > 0) {
                acc[year].positive += (selectedCurrency === userCurrency ?  transaction.value_in_user_currency : transaction.value_in_transaction_currency)  ;
            } else {
                acc[year].negative += (selectedCurrency === userCurrency ? transaction.value_in_user_currency : transaction.value_in_transaction_currency) ;
            }
            return acc;
        }, {});

        // Fill empty years
        let startDate = getStartDate();
        const endDate = new Date();

        while (startDate <= endDate) {
            const key = startDate.getFullYear();
            if (!groupedTransactions[key]) {
                groupedTransactions[key] = { positive: 0, negative: 0 };
            }
            startDate.setFullYear(startDate.getFullYear() + 1); // Increment by 1 year
        }

        return groupedTransactions;
    }

    function formatDataForBarChart(groupedTransactions) {
        //console.log('groupedTransactions',groupedTransactions)
        var returnData = {}
        returnData.data = [
            {
                name: 'Capital Call',
                type: 'column',
                data: []
            },
            {
                name: 'Distribution',
                type: 'column',
                data: []

            }
        ];
        //console.log('groupTransaction',groupTransaction)
        returnData.options = {
            colors: [
                MoondanceLayout.colorScheme.danger,

                MoondanceLayout.colorScheme.success
            ],
            xaxis: {
                type: 'category',
                categories: [],
                labels: (groupTransaction === 'M' ? {
                    rotate: -45,
                    rotateAlways: true,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    trim: true,
                    minHeight: undefined,
                    maxHeight: 120,
                    style: {
                        colors: [],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                }: {
                    rotate: 0,
                    rotateAlways: false,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    trim: true,
                    minHeight: undefined,
                    maxHeight: 120,
                    style: {
                        colors: [],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    }
                    }),
                
            },
            
        };
        // Convert the object to an array and sort it by the date in descending order
        var sortedTransactions = Object.entries(groupedTransactions)


        if (groupTransaction !== 'Q') {
           // sortedTransactions = sortedTransactions.sort((a, b) => new Date(b[0]) - new Date(a[0])).slice(0, 36).reverse()
        }

        sortedTransactions.forEach(([date, item]) => {
            returnData.options.xaxis.categories.push(date);
            returnData.data[1].data.push(item.positive);
            returnData.data[0].data.push(item.negative);
        });
        return returnData
    }

    useEffect(() => {
        if (props.currency !== selectedCurrency && props.currency) {
            setSelectedCurrency(props.currency);
        }
    }, [props.currency])

    useEffect(() => {
        if (props.data) {
            if (groupTransaction == 'Q') {
                var groupedData = groupTransactionsPerQuarter(props.data);

            } else if (groupTransaction == 'Y') {
                var groupedData = groupTransactionsPerYear(props.data);

            } else {
                var groupedData = groupTransactionsPerMonth(props.data);
            }

            var formattedData = formatDataForBarChart(groupedData);
            setDataPerGroup(formattedData);

        }

    }, [props.data, groupTransaction, selectedCurrency]);

    return (
            <Row>

                    {(DATAPERGROUP.options && DATAPERGROUP.data &&
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4E d-flex justify-content-between align-items-center">
                                    {(groupTransaction === 'Q' ? 'Transactions per Quarter' :
                                        (groupTransaction === 'Y' ? 'Transactions per Year' : 'Transactions per Month'))}

                                    <div
                                        className="btn-group"
                                        role="group"
                                        aria-label="Basic mixed styles example"
                                    >
                                        <button
                                            type="button"
                                            className={groupTransaction === 'M' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}

                                            onClick={() => { setGroupTransaction('M'); }}>Month</button>
                                        <button
                                            type="button"
                                            className={groupTransaction === 'Q' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
                                            onClick={() => { setGroupTransaction('Q'); }}>Quarter</button>

                                        <button
                                            type="button"
                                            className={groupTransaction === 'Y' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
                                            onClick={() => { setGroupTransaction('Y'); }}>Year</button>


                                    </div>
                                </CardTitle>

                                <BarChart
                                    data={DATAPERGROUP.data}
                                    options={DATAPERGROUP.options}
                                    currency={selectedCurrency}
                                /> 
                                 </CardBody>
                        </Card> 
                    )}

              
            </Row>
    )


    
}

export default TransactionsGraph;