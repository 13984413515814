import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink,
    Dropdown,
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";

import logoDark from "../../assets/images/moondance_logo.png";


const TopBar = () => {

    const [serviceDropDown, setServiceDropDown] = useState('All');
    return (
        <React.Fragment >
          <header id="home-topbar" style={{ position: 'fixed', width: '100%', top: '0', zIndex: '10000', overflowX: 'hidden' }}>
    <nav className="navbar navbar-expand-lg">
        <div className="d-flex justify-content-between align-items-center" style={{ width: '100%', padding: '0 15px' }}>
            <div >
                <span className="logo-sm">
                    <Link to="/home"><img src={logoDark} alt="logo-sm-dark" height="60" /></Link>
                </span>
            </div>
            <div  style={{ paddingTop: '0.5em' }}>
                <a style={{ marginRight: '0.5em', backgroundColor: 'blue' }} className="btn btn-bg public_btn-outline-gold hideOnMobile" href="#cta">Schedule Demo</a>
                <Link style={{ marginRight: '0em', backgroundColor: 'red' }} className="btn public_btn-gold" to="/login">Log in</Link>
            </div>
        </div>
    </nav>
</header>
            <div style={{ height: '60px' }}>&nbsp;</div>

        </React.Fragment>
    )
}


export default TopBar;