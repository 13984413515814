import React, { useState, useEffect, useContext } from "react";
import { Card, CardBody, Col, Row, Container, Button, Modal } from "reactstrap";
import UseMoondanceApi from "../lib/api";
import { MoondanceContext } from '../../App.js';
import { QRCodeSVG as QRCode } from 'qrcode.react';
import Select from "react-select";

import { formatOptionLabel } from "../lib/formatData.js";
import Form from 'react-bootstrap/Form';
import MoondanceLayout from "../lib/layout.js";

const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            No
        </div>
    );
};

const OnSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            Yes
        </div>
    );
};
const CrudUser = (props) => {

    const moondanceApi = UseMoondanceApi();

    const [id, setId] = useState("");
    const [availableCurrency, setAvailableCurrency] = useState([
        { value: 'EUR', label: 'Euro €' },
        { value: 'USD', label: 'US Dollar' },
        { value: 'GBP', label: 'UK Sterling Pound' },
        { value: 'CHF', label: 'Swiss Franc' },
        { value: 'INR', label: 'Indian Rupee' }
    ]);
    const [accounts, setAccounts] = useState([]);
    const [initialValue, setInitialValue] = useState({});
    const [submitResult, setSubmitResult] = useState(false);
    const [mfaMethod, setMfaMethod] = useState('0');
    const [accountAccessRights, setAccountAccessRights] = useState({});
    const [errors, setErrors] = useState({});
    const [totpSecret, setTotpSecret] = useState(false);
    const [userData, setUserData] = useState([]);
    const [totpCode, setTotpCode] = useState(false);
    const [totpVerified, setTotpVerified] = useState(false);
    const [totpError, setTotpError] = useState(false);
    const [totpModalisOpen, toggleTotpModal] = useState(false);
    const [availableAuthMethod, setAvailableAuthmethod] = useState([{ value: 0, label: 'None' }, { value: 'EMAIL', label: 'Email' }, { value: 'TOTP', label: 'Time-based one time password (Google Authenticator)' }]);
    const [clientList, setClientList] = useState([]);
    const { addAlert } = useContext(MoondanceContext);
    const { setUserCurrency } = useContext(MoondanceContext);

    const fetchClientList = async () => {
        const newClientList = await moondanceApi.admin_get_clients_list({});
        if (newClientList && newClientList.data) {
            let clientListOptions = []
            newClientList.data.forEach(client => {
                clientListOptions.push({ value: client.id, label: client.company_name + ' (#' + client.id + ')' })
            });
            //console.log('newClientList', clientListOptions)
            setClientList(clientListOptions)
        }
    };
    useEffect(() => {
        fetchClientList();
    }, []);

    function setVariable(key, value) {
        console.log('setVariable', key, value)
        setUserData({ ...userData, [key]: value })
    }

    function changeMfaMethod(value) {
        setVariable('MFA', value)
        if (value === 'TOTP' && !totpVerified) {
            var requestData = {
                id: id
            }
            moondanceApi.createTotpSecret(requestData).then(response => {
                if (response.data) {
                    setTotpSecret(response.data)
                    toggleTotpModal(true)
                }

            })
                .catch(error => {
                    console.log(error)
                });
        } else {
            setTotpSecret(false)
        }

    }


    function validateTotpSetup() {
        var requestData = {
            user_id: id,
            otp: totpCode
        }
        moondanceApi.checkOtp(requestData).then(response => {
            console.log('otp', response)
            if (response && response.success) {
                setTotpVerified(true)
                setTotpError(false)

            } else {
                setTotpVerified(false)
                setTotpError(true)
            }

        })
            .catch(error => {
                console.log(error)
                setTotpVerified(false)
                setTotpError(true)
            });
    }

    function closeTotpModal() {

        toggleTotpModal(false)
    }

    async function fetchUserProfile(id) {

        var requestData = {
            id: id
        }
        const thisUserProfile = await moondanceApi.admin_get_user(requestData);
        if (thisUserProfile && thisUserProfile.data) {
            console.log(thisUserProfile.data)
            setUserData(thisUserProfile.data)
        }
    };
    useEffect(() => {
        if (props && props.id) {
            fetchUserProfile(props.id)
        }
    }, [props.id])



 
    function submitForm() {
        console.log('submit Form')
        console.log(userData)

        var requestData = {
            id: (userData.id ? userData.id : null),
            first_name: userData.first_name,
            last_name: userData.last_name,
            company: userData.company,
            customer_account: userData.customer_account,
            email: userData.email,
            vat: userData.vat,
            currency: userData.currency,
            tel: userData.tel,
            MFA: (userData.MFA ? userData.MFA : 'NONE')
        }
        if (userData.id) {

            moondanceApi.admin_update_user(requestData).then(response => {
                setErrors({})

                if (response && response.success) {
                    setSubmitResult(true)
                    addAlert({
                        status: 'success',
                        title: 'User Updated',
                        message: 'User has been updated successfully'
                    })
                } else {
                    setSubmitResult(false)
                    addAlert({
                        status: 'danger',
                        title: 'User NOT updated',
                        message: 'User has NOT been updated successfully'
                    })
                }

            })
                .catch(error => {
                    setErrors({})
                    setSubmitResult(false)
                    addAlert({
                        status: 'danger',
                        title: 'User NOT updated',
                        message: 'User has NOT been updated successfully'
                    })
                });
        }




        //Check if anything change in  profile
        /*if (firstName !== initialValue.firstName || lastName !== initialValue.lastName || company !== initialValue.company || vat !== initialValue.vat || currency !== initialValue.currency || tel !== initialValue.tel || mfaMethod !== initialValue.MFA) {
            console.log('profile changed')

            var requestData = {
                id: id,
                first_name: firstName,
                last_name: lastName,
                company: company,
                vat: vat,
                currency: currency,
                tel: tel,
                MFA: mfaMethod
            }
            console.log('Request data', requestData)
            moondanceApi.updateProfile(requestData).then(response => {
                setErrors({})
                setSubmitResult(response)
                addAlert({
                    status: 'success',
                    title: 'Profile Updated',
                    message: 'Your profile has  been updated'
                })
                setUserCurrency(currency)
            })
                .catch(error => {
                    setErrors({})
                    setSubmitResult(error)
                    console.log(submitResult)
                    return false
                });
        }

        //Check if anything change in  accounts
        if (JSON.stringify(accounts) !== JSON.stringify(initialValue.accounts)) {

            accounts.forEach(function (account) {

                if (account.selectedByDefault !== initialValue.accounts[account.id].selectedByDefault) {
                    var requestData = {
                        id: account.id,
                        selectedByDefault: (account.selectedByDefault ? 1 : 0),
                        uid: id,
                    }
                    moondanceApi.updateUserAccountsSelection(requestData).then(response => {
                        setSubmitResult(response)
                        console.log(submitResult)
                    })
                        .catch(error => {
                            setSubmitResult(error)
                            console.log(submitResult)
                        });

                }
            });
        }
        */

    }



    return (

        <React.Fragment>
            <Container fluid={true}>
                <Row>
                    <Col xl={8} sm={8}>
                        <Card >
                            <CardBody>
                                <form className="user">
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm="4" >First Name</Form.Label>
                                        <Col sm="6">
                                            <input
                                                type="text"
                                                className="form-control "
                                                value={userData.first_name}
                                                onChange={e => setVariable('first_name', e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm="4" >Last Name</Form.Label>
                                        <Col sm="6">
                                            <input
                                                type="text"
                                                className="form-control "
                                                value={userData.last_name}
                                                onChange={e => setVariable('last_name', e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm="4">Email </Form.Label>
                                        <Col sm="6">
                                            <input
                                                type="tel"
                                                className="form-control "
                                                value={userData.email}
                                                onChange={e => setVariable('email', e.target.value)}
                                            /></Col>

                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm="4">Tel </Form.Label>
                                        <Col sm="6">
                                            <input
                                                type="tel"
                                                className="form-control "
                                                value={userData.tel}
                                                onChange={e => setVariable('tel', e.target.value)}
                                            /></Col>

                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm="4">Reference currency</Form.Label>
                                        <Col sm="6">
                                            <Select
                                                menuPortalTarget={document.body}
                                                menuPosition={'fixed'}
                                                value={availableCurrency.find(option => option.value === userData.currency)}
                                                onChange={e => setVariable('currency', e.value)}
                                                placeholder="Select Currency"
                                                options={availableCurrency}
                                                classNamePrefix="select2-selection"
                                                styles={MoondanceLayout.select2customStyles}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm="4">Multi factor authentication</Form.Label>
                                        <Col sm="6">
                                            <select
                                                className="form-control "
                                                value={userData.MFA}
                                                onChange={e => changeMfaMethod(e.target.value)}
                                            >
                                                {availableAuthMethod.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>

                                    </Form.Group>
                                    <Form.Group>
                                        {(mfaMethod === 'EMAIL' ? <p className="text-muted">A confirmation code will be sent to your email each time you try to login from an unknown device</p> : "")}
                                        {((mfaMethod === 0 || mfaMethod === '0') ? <p className="alert alert-danger text-danger">For your safety, we highly recommend choosing some form of muti factor authentication (MFA) to keep your account more secure. Please choose an MFA method to double-protect your account.</p> : "")}

                                    </Form.Group>



                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm="4" >Company</Form.Label>
                                        <Col sm="6">
                                            <input
                                                type="text"
                                                className="form-control "
                                                value={userData.company}
                                                onChange={e => setVariable('company', e.target.value)}
                                            />

                                            <p id="signup_form_company-error" className="error mt-2 text-danger"></p>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm="4" >VAT number</Form.Label>
                                        <Col sm="6">
                                            <input
                                                type="text"
                                                className="form-control "
                                                value={userData.vat}
                                                onChange={e => setVariable('vat', e.target.value)}

                                            />

                                            <p id="signup_form_vat-error" className="error mt-2 text-danger"></p>
                                        </Col>
                                    </Form.Group><hr />
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm="4" >Client account</Form.Label>
                                        <Col sm="6">
                                            <Select
                                                id="floatingSelectCustomerAccount"
                                                value={clientList ?
                                                    clientList.find(option => option.value === parseInt(userData.customer_account))
                                                    : null}
                                                onChange={e => setVariable('customer_account', e.value)}
                                                placeholder="Select Customer"
                                                options={clientList}
                                                formatOptionLabel={formatOptionLabel}
                                                styles={MoondanceLayout.select2customStyles}
                                            />

                                            <p id="signup_form_company-error" className="error mt-2 text-danger"></p>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3">
                                        <Button className="btn btn-success btn-user btn-block "
                                            onClick={() => { submitForm() }} >Save profile
                                        </Button>
                                        {submitResult && submitResult.success && (<Form.Label column sm="4" className="error mt-2 text-success"><b>Profile updated !</b></Form.Label>)}

                                    </Form.Group>



                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={totpModalisOpen} >
                <div className="modal-header">
                    <h5>Setting up Time-based One-Time Password</h5>
                    <button
                        type="button"
                        onClick={closeTotpModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                {(totpVerified ?

                    <div className="modal-body">
                        <p>Your account is now secured using  Time-based One-Time Password  !</p>
                    </div>

                    :
                    <div className="modal-body">
                        <p>A Time-based One-Time Password (TOTP) is an algorithm that generates a temporary one-time password, typically valid for 30 seconds. Using TOTP enhances the security of your accounts.</p>
                        <p><strong>Follow these steps to secure your account:</strong></p>
                        <ol> {/* Ordered list for step-by-step instructions */}
                            <li><strong>Download the Google Authenticator app</strong> (or another TOTP-compatible app) from your device's app store.</li>
                            <li><strong>Scan the QR code</strong> provided below.</li>
                            <div style={{ textAlign: 'center', margin: '1em' }}> {/* Centers and adds margin to the QR code */}
                                <QRCode value={totpSecret} />
                            </div>
                            <li><strong>Enter the 6-digit code</strong> generated by the Google Authenticator app into the service's verification field to complete the setup.
                                <input
                                    type="number"
                                    className="form-control "
                                    value={totpCode}
                                    onChange={e => { setTotpCode(e.target.value); setTotpError(false) }}
                                /></li>
                            {(totpCode.length == 6 ? <li>
                                < Button style={{ margin: '1em' }} className="btn btn-success" onClick={() => { validateTotpSetup() }}>Click here to validate your TOTP setup</Button>

                            </li> : '')}
                            {(totpError ? <p className="alert alert-danger text-danger">The code you entered is not valid. Please try again.</p> : "")}


                        </ol>
                    </div>

                )}
                <div className="modal-footer">

                    <button
                        type="button"
                        onClick={closeTotpModal}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Close
                    </button>

                </div>

            </Modal>
        </React.Fragment >




    )

}
/*
  <h4>Your Accounts</h4>
                                        <Form.Group className="mb-3" >
 
                                            <Col xl={6} sm={6}>Account Name</Col>
                                            <Col xl={6} sm={6}>Displayed by default</Col>
                                        </Row>
 
                                        {accounts ?
                                            accounts.map((account, index) => (
                                                <Row className="mb-3" >
                                                    <Col xl={6} sm={6}>
 
                                                        <label >{account.n}</label>
 
 
                                                    </Col>
                                                    <Col xl={2} sm={2}>
                                                        <Switch
                                                            uncheckedIcon={<Offsymbol />}
                                                            checkedIcon={<OnSymbol />}
                                                            className="me-1 mb-sm-8 mb-2"
                                                            onColor="#02a499"
                                                            onChange={() => {
                                                                const updatedSelection = { ...accountSelected };
                                                                updatedSelection[account.id] = !accountSelected[account.id];
                                                                changeSelectedAccount(account.id, !accountSelected[account.id])
                                                                setAccountSelected(updatedSelection);
                                                            }}
                                                            checked={(accountSelected[account.id] ? accountSelected[account.id] : 0)} />
 
                                                    </Col>
 
                                                </Row>
 
                                            )) : ""}
                                            
 <Row className="mb-3">
                                            <label for="email">Email</label>
                                            <input type="email" className="form-control "
                                                id="signup_form_email" required />
 
                                            <p id="signup_form_email-error" className="error mt-2 text-danger"></p>
                                        </Row>
                                        <Row className="mb-3">
 
                                            <label for="email">Choose a password</label>
                                            <input type="password" className="form-control "
                                                id="signup_form_password" placeholder="Password" />
 
                                            <p id="signup_form_password-error" className="error mt-2 text-danger"></p>
                                        </Row>
                                        <Row className="mb-3">
 
                                            <label for="email">Repeat password</label>
                                            <input type="password" className="form-control "
                                                id="signup_form_confirm_password" placeholder="Password" />
 
                                            <p id="signup_form_confirm_password-error" className="error mt-2 text-danger"></p>
                                        </Row>
                                        <Row className="mb-3">
 
                                            <label for="email">Multiple factor authentication</label>
                                            <select className="form-control" id="signup_form_MFA">
                                                <option value="null">No Double factor authentication (NOT Recommended)</option>
                                                <option value="sms">Send a confirmation code by SMS each time I try to login from an unknown device</option>
                                                <option value="email">Send a confirmation code by EMAIL each time I try to login from an unknown device</option>
                                            </select>
                                        </Row>
*/

export default CrudUser;