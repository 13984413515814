import React, { useState, useContext, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import { Card, CardBody, Col, Row, InputGroup } from "reactstrap";
import UseMoondanceApi from "../lib/api";
import { MoondanceContext } from '../../App.js';
import Select from "react-select";
import { formatOptionLabel } from "../lib/formatData.js";
import MoondanceLayout from "../lib/layout.js";

const CrudAccount = (props) => {
    const moondanceApi = UseMoondanceApi();
    const { id } = props;
    
    const timestamp = Date.now();
    const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);
    const [submitResult, setSubmitResult] = useState(false);
    const { addAlert } = useContext(MoondanceContext);
    const [clientList, setClientList] = useState([]);
    const [errors, setErrors] = useState({});

    const [defaultValues, setDefaultValues] = useState({name: '', customer_id: 0})

    const [accountData, setAccountData] = useState({})

    async function fetchAccountDetails(id) {
        const accounDetails = await moondanceApi.admin_get_account({ id: id });
        if (accounDetails && accounDetails.data) {
            setAccountData(accounDetails.data)
            console.log('accounDetails', accounDetails.data)
        }
    }
    const fetchClientList = async () => {
        const newClientList = await moondanceApi.admin_get_clients_list({});
        if (newClientList && newClientList.data) {
            let clientListOptions = []
            newClientList.data.forEach(client => {
                clientListOptions.push({ value: client.id, label: client.company_name + ' (#' + client.id + ')' })
            });
            //console.log('newClientList', clientListOptions)
            setClientList(clientListOptions)
        }
    };
    useEffect(() => {
        setAccountData(defaultValues)
        setSubmitResult(false)
    }, [lastUpdate]);

    useEffect(() => {
        fetchClientList();
    }, []);
    useEffect(() => {
        if (id && id  !== accountData.id) {
            setSubmitResult(false)
            fetchAccountDetails(props.id)
        }
    }, [id]);

    function setVariable(key, value) {
        console.log('setVariable', key, value)
        setAccountData({ ...accountData, [key]: value })
    }

    function saveAccount() {
        console.log('saveAccount', accountData)
        let requestData = accountData;

        if (accountData && accountData.id) {
            console.log('update account', accountData)
            moondanceApi.admin_update_account(requestData).then(response => {
                setErrors({})
                console.log(response)
                if(response && response.success){

                    setSubmitResult(true)
                    addAlert({
                        status: 'success',
                        title: 'Account Updated',
                        message: 'Account has been updated successfully'
                    })
                } else {
                    setSubmitResult(false)
                    addAlert({
                        status: 'danger',
                        title: 'Account NOT Updated',
                        message: 'Account has NOT been updated successfully'
                    })
                }
            })
                .catch(error => {
                    setErrors({})
                    setSubmitResult(false)
                    addAlert({
                        status: 'danger',
                        title: 'Account NOT Updated',
                        message: 'Account has NOT been updated successfully'
                    })
                });
        } else {
            moondanceApi.admin_create_account(requestData).then(response => {
                setErrors({})
                
                if(response && response.success){
                    setSubmitResult(true)
                    addAlert({
                        status: 'success',
                        title: 'Account created',
                        message: 'Account has been created successfully'
                    })
                } else {
                    setSubmitResult(false)
                    addAlert({
                        status: 'danger',
                        title: 'Account NOT created',
                        message: 'Account has NOT been created successfully'
                    })
                }
               
            })
                .catch(error => {
                    setErrors({})
                    setSubmitResult(false)
                    addAlert({
                        status: 'danger',
                        title: 'Account NOT created',
                        message: 'Account has NOT been created successfully'
                    })
                });
        }

        setLastUpdate(timestamp)
    }

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col>
                        <h1>{(props && props.new ? 'Create' : 'Edit')} Account</h1>
                    </Col>
                </Row>

                <form >
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="4">Account Name</Form.Label >
                        <Col sm="4">

                            <input
                                type="text"
                                className="form-control "
                                value={accountData.name}
                                onChange={e => setVariable('name', e.target.value)}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="4">Customer Account Name</Form.Label >
                        <Col sm="4">


                            <Select
                                id="floatingSelectCustomerAccount"
                                value={clientList ?
                                    clientList.find(option => option.value === parseInt(accountData.customer_id))
                                    : null}
                                onChange={e => setVariable('customer_id', e.value)}
                                placeholder="Select Customer"
                                options={clientList}
                                formatOptionLabel={formatOptionLabel}
                                styles={MoondanceLayout.select2customStyles}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4">
                        </Col>
                        <Col sm="4">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => { saveAccount() }}

                            >Save</button>
                        </Col>
                    </Form.Group>


                </form>

                {(submitResult ?

                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4"> <p>Account has been saved</p>
                        </Col>
                        <Col sm="4">
                            <button className="btn btn-outline-primary" onClick={() => { setSubmitResult(false); setAccountData(defaultValues); }}>Create new Account</button>

                        </Col>
                    </Form.Group>
                    : null

                )}
            </CardBody>
        </Card>
    )

}

export default CrudAccount;

/*
{
    "id": 2,
    "company_name": "Beside You",
    "contact_first_name": "Charles-Henri",
    "contact_last_name": "Maus",
    "contact_email": "ch@besideyou.be",
    "company_address_1": "",
    "company_postcode": "",
    "company_city": "",
    "company_country": "",
    "last_update": "2024-09-03T14:33:39",
    "current_users_count": 7,
    "current_accounts_count": 16,
    "current_positions_count": 397,
    "max_accounts_allowed": 25,
    "max_users_allowed": 20,
    "max_positions_allowed": 25000
}
*/