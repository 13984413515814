import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, CardBody, CardTitle, Col, Row, Container, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledAlert } from "reactstrap";
import { Link } from 'react-router-dom';
import PositionChart from '../../lib/charts/positionChart';
import UseMoondanceApi from "../../lib/api";
import { MoondanceContext } from '../../../App';
import { buildAccountParameter, positionPerGroup, positionPerGroupGeographies, positionPerGroupSectors, positionPerGroupType } from "../../lib/formatData";
import MoondanceLayout from "../../lib/layout.js";

import MoondanceTable from "../../lib/components/MoondanceTable.js";

const Chart = (props) => {

  const chartRef = useRef(null);
  const moondanceApi = UseMoondanceApi();
  document.title = "List Positions | Moondance";
  const { selectedAccountList } = useContext(MoondanceContext);
  const { selectedStatus } = useContext(MoondanceContext);

  const [chartOption] = useState([
    // Commitment Charts
    { id: 'commit_asset_type', label: 'Commitment Per Type', x: 'asset_type', y: 'position_commited_in_user_currency_at_inception_rate', horizontal: true },
    { id: 'commit_asset_vintage_year', label: 'Commitment Per Vintage', x: 'asset_vintage_year', y: 'position_commited_in_user_currency_at_inception_rate', horizontal: false, order: 'ysecAsc' },
    { id: 'commit_geography', label: 'Commitment Per Geography', x: 'geography', y: 'position_commited_in_user_currency_at_inception_rate', horizontal: true },
    { id: 'commit_sectors', label: 'Commitment Per Sector', x: 'sectors', y: 'position_commited_in_user_currency_at_inception_rate', horizontal: true },
    { id: 'open_commit_asset_vintage_year', label: 'Open Commitment Per Vintage', x: 'asset_vintage_year', y: 'position_expected_remaining_callable_capital_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'open_commit_position', label: 'Open Commitment Per Position', x: 'asset_name', y: 'position_expected_remaining_callable_capital_in_user_currency', horizontal: true, order: 'ysecAsc' },
  
    // NAV (Net Asset Value) Charts
    { id: 'nav_account', label: 'NAV Per Account', x: 'position_account_name', y: 'position_last_nav_in_user_currency', horizontal: true, order: 'ysecAsc' },
    { id: 'nav_asset_currency', label: 'NAV Per Currency', x: 'asset_currency', y: 'position_last_nav_in_user_currency', horizontal: true },
    { id: 'nav_asset_type', label: 'NAV Per Type', x: 'asset_type', y: 'position_last_nav_in_user_currency', horizontal: true },
    { id: 'nav_asset_vintage_year', label: 'NAV Per Vintage', x: 'asset_vintage_year', y: 'position_last_nav_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'nav_currency', label: 'NAV Per currency', x: 'asset_currency', y: 'position_last_nav_in_user_currency', horizontal: true, order: 'ysecAsc' },
    { id: 'nav_geography', label: 'NAV Per Geography', x: 'geography', y: 'position_last_nav_in_user_currency', horizontal: true },
    { id: 'nav_intermediate', label: 'NAV Per Intermediate', x: 'asset_intermediate', y: 'position_last_nav_in_user_currency', horizontal: true },
    { id: 'nav_position', label: 'NAV Per Position', x: 'asset_name', y: 'position_last_nav_in_user_currency', horizontal: true, order: 'ysecAsc' },
    { id: 'nav_sectors', label: 'NAV Per Sector', x: 'sectors', y: 'position_last_nav_in_user_currency', horizontal: true },
  
    // Value Creation Charts
    { id: 'gl_account', label: 'Value Creation Per Account', x: 'position_account_name', y: 'position_gain_and_losses_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'gl_asset_currency', label: 'Value Creation Per Currency', x: 'asset_currency', y: 'position_gain_and_losses_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'gl_asset_type', label: 'Value Creation Per Asset Type', x: 'asset_type', y: 'position_gain_and_losses_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'gl_asset_vintage_year', label: 'Value Creation Per Vintage', x: 'asset_vintage_year', y: 'position_gain_and_losses_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'gl_geography', label: 'Value Creation Per Geography', x: 'geography', y: 'position_gain_and_losses_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'gl_position', label: 'Value Creation Per Position', x: 'asset_name', y: 'position_gain_and_losses_in_user_currency', horizontal: true, order: 'ysecAsc' },
    { id: 'gl_sectors', label: 'Value Creation Per Sector', x: 'sectors', y: 'position_gain_and_losses_in_user_currency', horizontal: false, order: 'ysecAsc' },
  
    // Capital Call Charts
    { id: 'cc_account', label: 'Capital Call Per Account', x: 'position_account_name', y: 'position_cumulated_capital_calls_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'cc_asset_currency', label: 'Capital Call Per Currency', x: 'asset_currency', y: 'position_cumulated_capital_calls_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'cc_asset_type', label: 'Capital Call Per Type', x: 'asset_type', y: 'position_cumulated_capital_calls_in_user_currency', horizontal: true, order: 'ysecAsc' },
    { id: 'cc_asset_vintage_year', label: 'Capital Call Per Vintage', x: 'asset_vintage_year', y: 'position_cumulated_capital_calls_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'cc_geography', label: 'Capital Call Per Geography', x: 'geography', y: 'position_cumulated_capital_calls_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'cc_sectors', label: 'Capital Call Per Sector', x: 'sectors', y: 'position_cumulated_capital_calls_in_user_currency', horizontal: false, order: 'ysecAsc' },
  
    // Distribution Charts
    { id: 'dis_account', label: 'Distribution Per Account', x: 'position_account_name', y: 'position_cumulated_distributions_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'dis_asset_currency', label: 'Distribution Per Currency', x: 'asset_currency', y: 'position_cumulated_distributions_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'dis_asset_type', label: 'Distribution Per Type', x: 'asset_type', y: 'position_cumulated_distributions_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'dis_asset_vintage_year', label: 'Distribution Per Vintage', x: 'asset_vintage_year', y: 'position_cumulated_distributions_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'dis_geography', label: 'Distribution Per Geography', x: 'geography', y: 'position_cumulated_distributions_in_user_currency', horizontal: false, order: 'ysecAsc' },
    { id: 'dis_sectors', label: 'Distribution Per Sector', x: 'sectors', y: 'position_cumulated_distributions_in_user_currency', horizontal: false, order: 'ysecAsc' },
  
    // Performance Metrics Charts
    { id: 'dpi_position', label: 'DPI Per Position', x: 'asset_name', y: 'position_dpi_in_user_currency', horizontal: true, order: 'ysecAsc' },
    { id: 'irr_position', label: 'IRR Per Position', x: 'asset_name', y: 'position_irr_in_user_currency', horizontal: true, order: 'ysecAsc' },
    { id: 'tvpi_position', label: 'TVPI Per Position', x: 'asset_name', y: 'position_tvpi_in_user_currency', horizontal: true, order: 'ysecAsc' },
  ]);
  
  

  // X-Axis Options
  const [x_option, set_x_option] = useState([
    { id: 'asset_name', label: 'Per Position', horizontal: true, showSecYAxis: "asset_vintage_year", inGroupBy: false },
    { id: 'asset_type', label: 'Asset type', inGroupBy: false },
    { id: 'asset_vintage_year', label: 'Vintage', inGroupBy: true },
    { id: 'position_account_name', label: 'Account', inGroupBy: true },
    { id: 'asset_currency', label: 'Currency', inGroupBy: true },
    { id: 'asset_intermediate', label: 'Intermediate', inGroupBy: true },
    { id: 'geography', label: 'Geography', inGroupBy: false },
    { id: 'sectors', label: 'Sectors', inGroupBy: false },
    { id: 'asset_currency', label: 'Currencies', inGroupBy: true },
  ]);
  // Y-Axis Options
  const [y_option, set_y_option] = useState([
    { id: 'position_irr_in_user_currency', label: 'Internal Rate of Return (IRR)', y_axis_format: 'percentage', data_label_format: 'percentage', show_average: true, showTotal: false },
    { id: 'position_tvpi_in_user_currency', label: 'Total Value to Paid-In (TVPI)', y_axis_format: 'number', data_label_format: 'number', show_average: true, showTotal: false },
    { id: 'position_dpi_in_user_currency', label: 'Distributions to Paid-In (DPI)', y_axis_format: 'number', data_label_format: 'number', show_average: true, showTotal: false },
    { id: 'position_commited_in_user_currency_at_inception_rate', label: 'Commitment', y_axis_format: 'currency', show_average: false, showTotal: true },
    { id: 'position_last_nav_in_user_currency', label: 'Last NAV', y_axis_format: 'currency', show_average: false, showTotal: true },
    { id: 'position_expected_remaining_callable_capital_in_user_currency', label: 'Estimated open Commitment', show_average: false },
    { id: 'position_gain_and_losses_in_user_currency', label: 'Value Creation', show_average: false,showTotal: true },
    { id: 'position_cumulated_capital_calls_in_user_currency', label: 'Capital called', show_average: false, absoluteValue: true,showTotal: true },
    { id: 'position_remaining_callable_capital_in_user_currency', label: 'Remaining Commitment called', show_average: false,showTotal: true },
    { id: 'position_cumulated_distributions_in_user_currency', label: 'Total Distributed', show_average: false,showTotal: true },
  ]);

  const [order_options] = useState([
    { id: 'A-Z', label: 'By label (Ascending)' },
    { id: 'Z-A', label: 'By label (Descending)' },
    { id: 'valueAsc', label: 'By value (Ascending)' },
    { id: 'valueDesc', label: 'By value (Descending)' },
    { id: 'ysecAsc', label: 'By vintage (Ascending)' }
  ]);

  const [OrderDropDown, set_orderDropDown] = useState(false);
  const [selected_chartOption, set_selected_chartOption] = useState('commit_asset_vintage_year');

  const [group_by_dropdownn, set_group_by_dropdown] = useState(false)
  const [selected_group_by, set_selected_group_by] = useState(null)

  const [height, set_height] = useState((props.height ? props.height : 500));
  const [DATA, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);

  const [chart_type_dropdown, set_chart_type_dropdown] = useState(false)

  const [actionDropDown_y, set_actionDropDown_y] = useState(false)
  const [y_key, set_y_key] = useState('position_account_name')
  const [y_key_label, set_y_key_label] = useState('Account')

  const [actionDropDown_x, set_actionDropDown_x] = useState(false)
  const [x_key, set_x_key] = useState('position_account_name')
  const [x_key_label, set_x_key_label] = useState('Account')

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  const [total, setTotal] = useState(null);
  const [average, setAverage] = useState(null);

  const [selected_y, set_selected_y] = useState(false);
  const [selected_x, set_selected_x] = useState('a');
  const [horizontal, set_horizontal] = useState(true);
  const [order, set_order] = useState('valueAsc');
  const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);
  const [tableData, setTableData] = useState({})

  useEffect(() => {
    if (props.horizontal)
      set_horizontal(props.horizontal)
  }, [props.horizontal])

  useEffect(() => {
    fetchData();
  }, [selected_y, selectedAccountList, selectedStatus, selected_x, lastUpdate, userCurrency, selected_group_by, selected_chartOption]);

  useEffect(() => {
    // Find the selected chart option
    const selectedOption = chartOption.find(option => option.id === selected_chartOption);

    if (!selectedOption) return; // Exit if not found

    // Ensure x exists before setting state
    if (selectedOption.x) {
      const newSelectedX = x_option.find(x => x.id === selectedOption.x);
      if (newSelectedX) set_selected_x(newSelectedX);
    }

    // Ensure y exists before setting state
    if (selectedOption.y) {
      const newSelectedY = y_option.find(y => y.id === selectedOption.y);
      if (newSelectedY) set_selected_y(newSelectedY);
    }


    // Set order with a default value ('A-Z')
    if(selectedOption.order){
      set_order(selectedOption.order)
    }

    // Set horizontal flag (ensures boolean value)
    if (props.horizontal != undefined)
      set_horizontal(props.horizontal)
    else {
      set_horizontal(!!selectedOption.horizontal);
    }
  }, [selected_chartOption]);


  useEffect(() => {
    if (props.option) {
      set_selected_chartOption(props.option)

    }
  }, [props.option])

  // format X axis values based on the selected format
  function format_y_axis(value, format, sum = false, addpercentage = false) {
    if (format === 'percentage') {
      return MoondanceLayout.formatPercentage(value, 2);
    } else if (format === 'number') {
      return MoondanceLayout.formatNumber((Math.round(value * 100) / 100));
    } else if (format === 'percentageOfSum') {
      return MoondanceLayout.formatPercentage(value / sum, 2)
    } else if (format === 'currency') {
      return MoondanceLayout.formatCurrency(value)
    }
    if (!value || value == 0) {
      return '-'
    } else {
      return MoondanceLayout.formatNumber(value , 0)  + (props.currency ? (MoondanceLayout.currencyShort[props.currency] ? MoondanceLayout.currencyShort[props.currency] : ' ' + props.currency) : '')
    }
  }
  async function fetchData() {
    //console.log(' x,y ', selected_x, selected_y)
    var api_parameters = {
      account: buildAccountParameter(selectedAccountList),
      status: buildAccountParameter(selectedStatus),
      calculateIrr: true,
      userCurrency: userCurrency,
    }
    try {
      if (api_parameters.account && api_parameters.account.length > 0) {
        var returnData = {}
        var data = await moondanceApi.fetchPositionsList(api_parameters);
        //console.log('step1', data)
        if (data.data.length > 0) {
          returnData.listPositions = data.data
          if (selected_x.id === 'geography') {
            returnData.byGeographies = await moondanceApi.fetchAssetsGeographiesList(api_parameters);
            returnData.byGeographies = returnData.byGeographies.data
            returnData.statsPerType = positionPerGroupGeographies(returnData, selected_y.id, selected_group_by)
          } else if (selected_x.id === 'asset_type') {
            returnData.byType = await moondanceApi.fetchAssetsTypeList(api_parameters);
            returnData.byType = returnData.byType.data
            returnData.statsPerType = positionPerGroupType(returnData, selected_y.id, selected_group_by)
          } else if (selected_x.id === 'sectors') {
            returnData.bySectors = await moondanceApi.fetchAssetsSectorsList(api_parameters);
            returnData.bySectors = returnData.bySectors.data
            returnData.statsPerType = positionPerGroupSectors(returnData, selected_y.id, selected_group_by)
          } else {
            returnData.statsPerType = positionPerGroup(data.data, selected_x.id, selected_y, selected_group_by)

          }
          formatTable(returnData)
          //console.log('step2', returnData.statsPerType)



          //console.log('step3', returnData.statsPerType)
          setData(returnData)
        }
        setLoading(false)
      }
    } catch (error) {
      console.log(error);
    }

  }

  function formatTable(returnData) {
    let newTableData = {}
    if (returnData.statsPerType.group_by_keys && returnData.statsPerType.group_by_keys.length > 0) {
      //console.log('format table', selected_y,returnData)

      if (!selected_y.showTotal) {
        //console.log('remove total')
        returnData.statsPerType.group_by_keys = returnData.statsPerType.group_by_keys.filter(key => key !== "total");
    }
    //console.log('format table', returnData)
      newTableData.columns = []
      returnData.statsPerType.group_by_keys.map((key, index) => {
        newTableData.columns.push(
          {
            field: key,
            label: (key === 'id' ? selected_x.label : key),
            showTotal: (selected_y.showTotal ? selected_y.showTotal : false),
            format: (selected_y.y_axis_format ? selected_y.y_axis_format : 'currency'), 
            formatUnit: userCurrency,
            customFormat: (row) => {
              if (key !== 'id') {
                return format_y_axis(row[key], (selected_y.y_axis_format ? selected_y.y_axis_format : false), false, true)
              } else if(selected_x.id === 'asset_type') {
                return (MoondanceLayout.assetType[row['id']].label ? MoondanceLayout.assetType[row['id']].label : row['id'])
                
              } else {

                return row[key]
              }
            },
            align: 'right'
          })
          
      })

      if (returnData.statsPerType.group_by_values) {
        newTableData.data = returnData.statsPerType.group_by_values
      }
    } else if (returnData.statsPerType.x && returnData.statsPerType.x.length > 0 && returnData.statsPerType.y && returnData.statsPerType.y.length > 0) {
      newTableData.columns = [
        { field: 'x', label: selected_x.label, },
        {
          field: 'y',
          label: selected_y.label,
          customFormat: (row) => {
            return format_y_axis(row['y'], (selected_y.y_axis_format ? selected_y.y_axis_format : false), false, true)
          },
          showTotal: true ,
          format: 'currency', formatUnit: userCurrency,
          align: 'right'

        }
      ]
      newTableData.data = returnData.statsPerType.x.map((x, index) => {
        return { x: x, y: returnData.statsPerType.y[index] }
      })
   
    }
    setTableData(newTableData)

  }


  if (loading) {
    return (
      <React.Fragment>
        <div className="page-content">
          <Col xl={12} sm={12}>
            <Card >
              <CardBody>

                <CardTitle>  <span className="spinner-border text-primary" style={{ height: '1em', width: '1em', marginRight: '1em' }} role="status"></span> Loading data ...</CardTitle>

              </CardBody>
            </Card>
          </Col>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        {(props && props.hideControls) ? null :
          <Row>
            <Col lg="8" className="d-flex flex-row ">
              <Dropdown
                isOpen={chart_type_dropdown}
                toggle={() => set_chart_type_dropdown(!chart_type_dropdown)}
              >
                Chart :<DropdownToggle className="btn btn-link" caret={true}>{chartOption.find(y => y.id === selected_chartOption).label}<i className="mdi mdi-chevron-down" /></DropdownToggle>
                <DropdownMenu>
                  {chartOption.length > 0 && chartOption.map((item, index) => (
                    <DropdownItem key={index} onClick={() => { set_selected_chartOption(item.id) }} >
                      <Link>{item.label}</Link>
                    </DropdownItem>
                  ))}
                </DropdownMenu>

              </Dropdown>


            </Col>
          </Row>
        }

        {(selected_x && selected_y &&

          ((props && props.hideControls) ?
          <>
            {(props.showChart && selected_chartOption && selected_x && selected_y && DATA  ?
             
              <PositionChart
                id={selected_chartOption}
                data={DATA}
                x={selected_x}
                y={selected_y}
                currency={userCurrency}
                horizontal={horizontal}
                order={order}
                height={(props.height ? props.height : 400)}
                hideControls={true}
              />
              : null)}


              
              {(props.showTable  && selected_chartOption &&tableData && tableData.columns && tableData.data ?
                <MoondanceTable
                  rows={tableData.data}
                  columns={tableData.columns}
                  hideAiReply={true}
                  hideAiButton={true}
                  defaultSort={{ key: 'x', direction: 'ascending' }}
                  hideExportButton={true}
                  printMode={true}

                />
                : null)}
                </>
               
        :
        <>
          <Row>
            <Col xl={(selected_group_by ? 12 : 8)} sm={12}>
              {(selected_x && selected_y && DATA ?
                <PositionChart
                  id={selected_chartOption}
                  data={DATA}
                  x={selected_x}
                  y={selected_y}
                  currency={userCurrency}
                  horizontal={horizontal}
                  order={order}
                  height={400}
                  hideControls={(props && props.hideControls ? true : false)}
                /> : null)}


              <div className="d-flex d-flex-row">
                <div className="my-2"> Customize: </div>
                <button className="btn btn-link" onClick={() => { set_horizontal(!horizontal) }} ><span className="mdi mdi-autorenew"></span> Rotate </button>

                <Dropdown
                  isOpen={actionDropDown_y}
                  toggle={() => set_actionDropDown_y(!actionDropDown_y)}
                >
                  <DropdownToggle className="btn btn-link" caret={true}>
                    {selected_y.label}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {y_option.length > 0 && y_option.map((item, index) => (
                      <DropdownItem key={index} onClick={() => { set_selected_y(y_option.find(y => y.id === item.id)) }} >
                        <Link>{item.label}</Link>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                <div className="my-2"> per </div>
                <Dropdown
                  isOpen={actionDropDown_x}
                  toggle={() => set_actionDropDown_x(!actionDropDown_x)}
                >
                  <DropdownToggle className="btn btn-link" caret={true}>
                    {selected_x.label}  <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {x_option.length > 0 && x_option.map((item, index) => (
                      <DropdownItem key={index} onClick={() => { set_selected_x(x_option.find(x => x.id === item.id)) }} >
                        <Link>{item.label}</Link>
                      </DropdownItem>
                    ))}

                  </DropdownMenu>
                </Dropdown>
                <div className="my-2">order by</div>
                <Dropdown
                  isOpen={OrderDropDown}
                  toggle={() => set_orderDropDown(!OrderDropDown)}
                >
                  <DropdownToggle className="btn btn-link" caret={true}>
                    {order_options.find(x => x.id === order).label}

                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {order_options.length > 0 && order_options.map((item, index) => (
                      <DropdownItem key={index} onClick={() => { set_order(item.id) }} >
                        <Link>{item.label}</Link>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>

              </div>
            </Col>

            <Col xl={(selected_group_by ? 12 : 4)} sm={12}>
              <Dropdown
                isOpen={group_by_dropdownn}
                toggle={() => set_group_by_dropdown(!group_by_dropdownn)}
              >
                Group by :<DropdownToggle className="btn btn-link" caret={true}>{(x_option.find(x => x.id === selected_group_by) ? x_option.find(x => x.id === selected_group_by).label : 'All grouped')}<i className="mdi mdi-chevron-down" /></DropdownToggle>
                <DropdownMenu>
                  {(selected_group_by ? <DropdownItem onClick={() => { set_selected_group_by(null) }} >  <Link> All grouped</Link></DropdownItem> : null)}
                  {x_option.length > 0 && x_option.map((item, index) => (
                    (item.inGroupBy ?
                      <DropdownItem key={index} onClick={() => { set_selected_group_by(item.id) }} >
                        <Link>{item.label}</Link>
                      </DropdownItem>
                      : null)
                  ))}
                </DropdownMenu>
              </Dropdown>
              {(selected_group_by ? <hr/> : null)}
              {(tableData && tableData.columns && tableData.data ?
                <MoondanceTable
                  rows={tableData.data}
                  
                  columns={tableData.columns}
                  hideAiReply={true}
                  hideAiButton={true}

                />
                : null)}
            </Col>
          </Row>
        </>
        )
        )}


      </React.Fragment>
    );
  }
};// 

export default Chart;
