import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import TopBar from '../publicWebsite/topbar.js';
import Carousel from '../publicWebsite/carousel.js';
import RequestDemoForm from '../publicWebsite/requestDemoForm.js';
import PublicFooter from '../publicWebsite/publicFooter.js';


import img1 from "../../assets/images/public/jcurve.png";
import video from "../../assets/images/2025_about_moondance.mp4";



const PublicWebsite = () => {
    const [message, set_message] = useState('');

    const [error_message, set_error_message] = useState('');
    useEffect(() => {
        document.body.className = "homePageBody";
    });


    return (

        <React.Fragment>
            <TopBar />
            <div className="'page-content publicHomePage" >

                <div id="homeBanner">
                    <Container
                        id="homeBannerContainer"
                        className="container-lg homeContainer d-flex flex-column align-items-center justify-content-center text-center"

                    >
                        <Row className="w-100">
                            <h1>Gain Clarity & Control Over Your Private Equity Investments</h1>
                            <p style={{ marginTop: "1em" }}>
                                Turn Private Equity funds complexity into strategic clarity with
                                automated tracking, instant reporting, and predictive cash flow
                                forecasting.
                            </p>
                        </Row>
                        <Row className="w-100 d-flex justify-content-center">
                            <a

                                href="#cta"
                                className="button public_cta"
                                style={{
                                    backgroundColor: "#C1A036",
                                    color: "#1A1C27",
                                    fontWeight: "600",
                                    fontSize: "1.2em",
                                    width: "25em",
                                    padding: "1em",
                                    margin: "2em",
                                    textAlign: "center",
                                }}
                            >
                                Schedule my custom Demo now
                            </a>
                        </Row>
                        <div className="public_social-proof">
                            <p>Developed by investment professionals who lived the challenges you face daily.</p>
                        </div>

                    </Container>
                </div>
                <div>
                    <section className="public_problem public_section">
                        <div className="public_container">
                            <h2>Why It Matters</h2>

                            <div className="public_pain-points">
                                <div className="public_pain-point">
                                    <h3>Time Drain</h3>
                                    <p>Managing private equity investments across multiple entities is consuming your strategic thinking time</p>
                                </div>

                                <div className="public_pain-point">
                                    <h3>Financial Uncertainty</h3>
                                    <p>Unpredictable capital calls create liquidity anxiety and risk overcommitment</p>
                                </div>

                                <div className="public_pain-point">
                                    <h3>Hidden Insights</h3>
                                    <p>Critical performance patterns remain buried in disconnected spreadsheets and PDFs</p>
                                </div>

                                <div className="public_pain-point">
                                    <h3>Reporting Burden</h3>
                                    <p>Hours spent manually compiling data for each client review</p>
                                </div>
                            </div>


                        </div>
                    </section>

                    <section className="public_solution public_section">
                        <div className="public_container">
                            <h2>Introducing MoonDance LP Companion</h2>
                            <p>MoonDance transforms how family offices manage private equity investments:</p>

                            <div className="features-brief">
                                <div className="feature-brief">
                                    <p>✅ <strong>Multi-Entity Management</strong> — Efficiently handle multiple investing companies under one roof</p>
                                </div>
                                <div className="feature-brief">
                                    <p>✅ <strong>Intelligent Cash Flow Forecasting</strong> — Anticipate capital calls and distributions with precision</p>
                                </div>
                                <div className="feature-brief">
                                    <p>✅ <strong>Comprehensive Performance Analytics</strong> — Generate instant insights across dimensions</p>
                                </div>
                                <div className="feature-brief">
                                    <p>✅ <strong>Automated Reporting</strong> — Create beautiful, client-ready reports in seconds</p>
                                </div>
                            </div>

                        </div>
                    </section>

                    <section className="public_features-detailed public_section">
                        <div className="public_container">
                            <h2>Key Features &amp; Benefits</h2>

                            <div className="public_feature-card">
                                <h3>1. Multi-Entity Portfolio Management</h3>
                                <p className="public_subtitle">Handle multiple investing companies without multiplying your workload.</p>
                                <p>Manage relationships between entities, track overlapping positions, and generate entity-specific reporting—all from a single dashboard.</p>
                            </div>

                            <div className="public_feature-card">
                                <h3>2. Complete Transaction Intelligence</h3>
                                <p className="public_ubtitle">End spreadsheet chaos for good.</p>
                                <p>Track historical cash flows with component breakdown (capital calls, setup fees, equalizations) and support for secondary transactions.</p>
                            </div>

                            <div className="public_feature-card">
                                <h3>3. Cash Flow Forecasting Engine</h3>
                                <p className="public_subtitle">Never face a liquidity surprise again.</p>
                                <p>Anticipate capital needs with precision.</p>
                            </div>

                            <div className="public_feature-card">
                                <h3>4. Currency-Intelligent Analytics</h3>
                                <p className="public_subtitle">Seamless multi-currency management.</p>
                                <p>Record transactions in native currencies with historical FX rates for accurate performance tracking across your global portfolio.</p>
                            </div>

                            <div className="public_feature-card">
                                <h3>5. Client-Ready Reporting</h3>
                                <p className="public_subtitle">From hours to seconds.</p>
                                <p>Generate sophisticated reports per investing entity with customizable metrics: IRR, TVPI, DPI, value creation, and exposure analysis.</p>
                            </div>
                        </div>
                    </section>

                    <section className="public_proof-points public_section">
                        <div className="public_container">
                            <h2>Proof Points &amp; Differentiation</h2>

                            <div className="public_capabilities">
                                <h3>System Capabilities</h3>
                                <ul className="public_capability-list">
                                    <li>Handles multiple positions and transactions</li>
                                    <li>Supports multiple investing entities under one user account</li>
                                    <li>Customizable access levels (admin, user, read-only)</li>
                                    <li>Advanced analytics across multiple dimensions</li>
                                </ul>
                            </div>

                            <h3>How We Compare</h3>
                            <table className="public_comparison-table">
                                <thead>
                                    <tr>
                                        <th>Feature</th>
                                        <th>MoonDance</th>
                                        <th>Spreadsheets</th>
                                        <th>Enterprise Solutions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Multi-Entity Support</td>
                                        <td>✅ Native</td>
                                        <td>❌ Limited</td>
                                        <td>⚠️ Complex</td>
                                    </tr>
                                    <tr>
                                        <td>Implementation Time</td>
                                        <td>✅ Days</td>
                                        <td>⚠️ Weeks</td>
                                        <td>❌ Months</td>
                                    </tr>
                                    <tr>
                                        <td>Cost-Effectiveness</td>
                                        <td>✅ High</td>
                                        <td>⚠️ Intermediate</td>
                                        <td>❌ Low</td>
                                    </tr>
                                    <tr>
                                        <td>Data Insights</td>
                                        <td>✅ Comprehensive</td>
                                        <td>❌ Limited</td>
                                        <td>✅ Comprehensive</td>
                                    </tr>
                                    <tr>
                                        <td>Ease of Use</td>
                                        <td>✅ Intuitive</td>
                                        <td>⚠️ Manual</td>
                                        <td>❌ Complex</td>
                                    </tr>
                                    <tr>
                                        <td>Risk of Error</td>
                                        <td>✅ Low</td>
                                        <td>❌ High</td>
                                        <td>✅ Low</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>

                    <section id="cta" className="public_cta-section public_section">
                        <RequestDemoForm />
                    </section>
                    <section className="public_faq-section public_section">
                        <div className="public_container">
                            <h2>Frequently Asked Questions</h2>

                            <div className="public_faq-container">
                                <div className="public_faq-item">
                                    <h3>Q: Can MoonDance handle our portfolio of multiple positions across multiple entities?</h3>
                                    <p>A: Absolutely. MoonDance currently manages over 500 positions and 5,000+ transactions for users with similar multi-entity structures.</p>
                                </div>

                                <div className="public_faq-item">
                                    <h3>Q: How does the system handle complex transactions with multiple components?</h3>
                                    <p>A: MoonDance allows you to record each component (capital call, setup fee, discount) as separate transactions, providing complete transparency and accuracy.</p>
                                </div>

                                <div className="public_faq-item">
                                    <h3>Q: Can we customize reporting for each of our investing entities?</h3>
                                    <p>A: Yes. MoonDance creates entity-specific reports with tailored metrics while maintaining consolidated views for overall portfolio management.</p>
                                </div>

                                <div className="public_faq-item">
                                    <h3>Q: How does MoonDance handle foreign exchange for international investments?</h3>
                                    <p>A: The system automatically applies historical exchange rates from a trusted API, with upcoming capability to override with your actual transaction rates if needed.</p>
                                </div>

                                <div className="public_faq-item">
                                    <h3>Q: How quickly can we import our historical data?</h3>
                                    <p>A: MoonDance offers a streamlined import process for transactions and positions, with personalized support to ensure smooth data migration.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="public_final-cta">
                        <div className="public_container">
                            <h2>Transform Your Private Equity Management Today</h2>
                            <a href="#cta" className="public_button">Get Started Now</a>

                            <div className="public_final-social-proof">
                                <p>"MoonDance continuously improves based on real feedback from family offices—our product roadmap is shaped by the professionals who use it every day"</p>
                            </div>
                        </div>
                    </section>
                </div>













            </div>
            <PublicFooter />

        </React.Fragment>
    );

}


export default PublicWebsite;
/*<div className="page-content publicHomePage" >
                <Container id="homeCarousel" className="container-lg homeContainer" >
                    <Carousel />
                </Container>
            </div>
            */