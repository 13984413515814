
import React, { useState, useContext, useEffect } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { MoondanceContext } from '../../App';
import UseMoondanceApi from "../lib/api";

import CrudUser from "../admin/crud_user.js";
import Logs from '../admin/logs.js'
import Accounts from '../admin/accounts.js'
import { Link } from 'react-router-dom';


const Users = () => {
    let Navigate = useNavigate();
    const moondanceApi = UseMoondanceApi();
    const [showAdminMenu, setShowAdminMenu] = useState(false);
    const [activeTab, setactiveTab] = useState("userInfo");
  
    const params = useParams();
    const id = params.id;
    const [userData, setUserData] = useState({});
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };

    // this page should not be displayed if the user is not an admin
    useEffect(() => {
        const fetchUserProfile = async () => {
            const profile = await moondanceApi.getLoggedinUser();

            if (
                profile
                && profile.data
                && profile.data.access_level
                && profile.data.access_level === 'admin') {
                console.log('Admin user verified')
                
                setShowAdminMenu(true);
              
            } else {
                setShowAdminMenu(false);
                Navigate('/dashboard')

            }
        };
        fetchUserProfile();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            const user = await moondanceApi.admin_get_user({ id: params.id });
            let new_userData = { ...userData };
            new_userData.userInfo = user.data;
            setUserData(new_userData);
            //console.log('fetchUser', user)

        }
        fetchUser();

    }, [id]);




    return (
        <React.Fragment >
            <div className='page-content' >

                <Container fluid={true}>

                    <Row style={{ marginBottom: '1em' }}>
                        <div className="flex">
                           
                            <h1>
                                <i className="mdi mdi-desktop-mac-dashboard"></i> User :{(userData && userData.userInfo ? <span>{userData.userInfo.id} : {userData.userInfo.first_name} {userData.userInfo.last_name}</span> :'')}  </h1>
                                <Link className="btn btn-outline-primary" to={'/admin'}>Back to Admin home</Link>
                      
                        </div>
                    </Row>
                    <hr />
                    <Nav tabs>
                       
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={(activeTab === 'userInfo' ? "active nav-link " : "")}
                                onClick={() => { toggle("userInfo"); }}
                            >
                                <i className="mdi mdi-account me-1 align-middle"> </i>{" "}User Informations
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={(activeTab === 'Accounts' ? "active nav-link " : "")}
                                onClick={() => { toggle("Accounts"); }}
                            >
                                <i className="mdi mdi-account me-1 align-middle"> </i>{" "}Account
                            </NavLink>
                        </NavItem>

                       
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={(activeTab === 'Stats' ? "active nav-link " : "")}
                                onClick={() => { toggle("Logs"); }}
                            >
                                <i className="dripicons-home me-1 align-middle"> </i>{" "}Logs
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={(activeTab === 'Edit' ? "active nav-link " : "")}
                                onClick={() => { toggle("Edit"); }}
                            >
                                <i className="mdi mdi-cog  me-1 align-middle"> </i>{" "}Edit
                            </NavLink>
                        </NavItem>
                        
                    </Nav>
                    <TabContent activeTab={activeTab} className="p-3">
                        <TabPane tabId="userInfo" id="userInfo">
                            <h5>User Informations</h5>
                            {(userData && userData.userInfo ?
                                <div>
                                    <p>ID : {(userData.userInfo.id ? userData.userInfo.id : ' - ')}</p>
                                    <p>First Name : {(userData.userInfo.first_name ? userData.userInfo.first_name : ' - ')}</p>
                                    <p>last Name : {(userData.userInfo.last_name ? userData.userInfo.last_name : ' - ')}</p>
                                    <p>Company : {(userData.userInfo.company ? userData.userInfo.company : ' - ')}</p>
                                    <p>VAT: {(userData.userInfo.vat ? userData.userInfo.vat : ' - ')}</p>
                                    <p>Email: {(userData.userInfo.email ? userData.userInfo.email : ' - ')}</p>
                                    <p>TEL: {(userData.userInfo.tel ? userData.userInfo.tel : ' - ')}</p>
                                    <p>Currency: {(userData.userInfo.currency ? userData.userInfo.currency : ' - ')}</p>
                                    <p>Password Security: {(userData.userInfo.MFA ? userData.userInfo.MFA : ' - ')}</p>
                                    <p>Account linked : {(userData.userInfo.customer_account_name ? userData.userInfo.customer_account_name : ' - ')}</p>
                                    <p>Max accounts allowed : {(userData.userInfo.max_accounts_allowed ? userData.userInfo.max_accounts_allowed : ' - ')}</p>
                                    <p>Max users allowed : {(userData.userInfo.max_users_allowed ? userData.userInfo.max_users_allowed : ' - ')}</p>
                                    <p>Max positions allowed : {(userData.userInfo.max_positions_allowed ? userData.userInfo.max_positions_allowed : ' - ')}</p>
                                    <p>Current positions count : {(userData.userInfo.current_positions_count ? userData.userInfo.current_positions_count : ' - ')}</p>
                                    <p>Current accounts count : {(userData.userInfo.current_accounts_count ? userData.userInfo.current_accounts_count : ' - ')}</p>
                                    <p>Current users count : {(userData.userInfo.current_users_count ? userData.userInfo.current_users_count : ' - ')}</p> 
                                </div>
                                : <p>Can't fetch user data...</p>)}
                        </TabPane>
                        <TabPane tabId="Logs" id="Logs">
                        {(userData && userData.userInfo && userData.userInfo.id ?  
                                <Logs user_id={userData.userInfo.id} />
                                : <p>Can't fetch user data...</p>)}
                        </TabPane>
                        <TabPane tabId="Accounts" id="Accounts">
                        {(userData && userData.userInfo && userData.userInfo.id ?  
                                <Accounts user_id={userData.userInfo.id} />
                                : <p>Can't fetch user data...</p>)}
                        </TabPane>
                        <TabPane tabId="Edit" id="Edit">
                        {(activeTab =='Edit' ?  
                                <CrudUser id={userData.userInfo.id} />
                                : <p>Can't fetch user data...</p>)}
                        </TabPane>
                        
                    </TabContent>



                </Container>
            </div>
        </React.Fragment >
    );

}
export default Users;

/*{
    "id": 1,
    "MFA": "TOTP",
    "access_level": "admin",
    "customer_account_name": "Cogespar",
    "max_accounts_allowed": 2,
    "max_users_allowed": 1,
    "max_positions_allowed": 300,
    "current_positions_count": 34,
    "current_accounts_count": 2,
    "current_users_count": 1
}
                */