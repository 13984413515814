import React, { useEffect, useState } from "react";

import Form from 'react-bootstrap/Form';
import { UncontrolledCarousel } from "reactstrap";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink, InputGroup
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import UseMoondanceApi from "../lib/api.js";

const RequestDemoForm = () => {

    const moondanceApi = UseMoondanceApi();
    const [from_name, set_from_name] = useState('');
    const [from_email, set_from_email] = useState('');
    const [subject, set_subject] = useState('Filling Contact Form on Moondance.financial');
    const [message, set_message] = useState('');
    const [error_name, set_error_name] = useState('');
    const [error_email, set_error_email] = useState('');
    const [error_message, set_error_message] = useState('');
    const [entities_number, set_entities_number] = useState(1);
    const [positions_number, set_positions_number] = useState(null);
    const [challenge, set_challenge] = useState(null);
    const [message_sent, set_message_sent] = useState('');

    async function sendMessage() {
        let error = false;

        if (isValidEmail(from_email)) {
            set_error_email('')
        } else {
            set_error_email('Invalid email address')
            error = true;
        }
        if (from_name.length < 1) {
            set_error_name('Please enter your name')
            error = true;
        } else {
            set_error_name('')
        }

        if (message.length < 1) {
            set_error_message('Please enter a message')
            error = true;
        } else {
            set_error_message('')
        }

        if (!error) {
 
            let fullMessages = `Company: ${from_name}\n\nNumber of investment entities managed: ${entities_number}\n\nCurrent PE positions: ${positions_number}\n\nBiggest challenge: ${challenge}\n\nMessage: ${message}`
           
            let requestData = {
                from_name: from_name,
                from_email: from_email,

                subject: subject,
                message: fullMessages,
                type: 'contactForm'
            }
            try {
                var result = await moondanceApi.sendContactForm(requestData)
                if (result.success) {
                    set_message_sent('An email has been dispatched to verify your email address. Please click the link contained within this email to complete the verification process. Note that your message to our team will not be processed until your email address has been confirmed.')
                } else {
                    console.log("Error sending message", result)
                    if (result.data && result.data === 'IPSPAM') {
                        set_message_sent('This IP address has generated an unusually high volume of unverified messages. Please wait and attempt your request again later.')
                    } else {
                        set_message_sent('Error sending message')
                    }
                }
            } catch (error) {
                console.log("Error sending message", error)
                set_message_sent('Error sending message')
            }
        }
    }


    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    return (

        <>
            <div className="public_container">
                <h2 style={{ color: '#ffffff' }}>Scale Your Private Equity Operations Without Scaling Your Team</h2>

                <div className="public_form-container">
                    <form className="public_contact-form">
                        <div className="public_form-group">
                            <label for="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                className="public_form-control"
                                required=""

                                value={from_name}
                                onChange={(e) => set_from_name(e.target.value)}
                            />

                            {(error_name) ? <span style={{ color: 'red' }}>{error_name}</span> : ''}

                        </div>

                        <div className="public_form-group">
                            <label for="email">Email</label>
                            <input 
                                type="email" 
                                id="email"
                                className="public_form-control"
                                required="" 
                                value={from_email}
                                onChange={(e) => set_from_email(e.target.value)}
                            />
                            {(error_email) ? <span style={{ color: 'red' }}>{error_email}</span> : ''}
                        </div>

                        <div className="public_form-group">
                            <label for="company">Company</label>
                            <input 
                                type="text"
                                id="company"
                                className="public_form-control"
                                required=""
                            />
                        </div>

                        <div className="public_form-group">
                            <label for="entities">Number of investment entities managed</label>
                            <input 
                                type="number"
                                id="entities" 
                                className="public_form-control"
                                required=""
                                min="1"
                                onChange={(e) => set_entities_number(e.target.value)}
                                value={entities_number}
                            />
                        </div>

                        <div className="public_form-group">
                            <label for="positions">Current PE positions</label>
                            <select id="positions" 
                                className="public_form-control"
                                required=""
                                onChange={(e) => set_positions_number(e.target.value)}
                            >
                                <option value="">Select an option</option>
                                <option value="<25">&lt;25</option>
                                <option value="25-75">25-75</option>
                                <option value="75-200">75-200</option>
                                <option value="200+">200+</option>
                            </select>
                        </div>

                        <div className="public_form-group">
                            <label for="challenge">Biggest challenge</label>
                            <select 
                                id="challenge"
                                className="public_form-control"
                                required=""
                                onChange={(e) => set_challenge(e.target.value)}
                            >
                                <option value="">Select an option</option>
                                <option value="time">Time management</option>
                                <option value="liquidity">Cash flow forecasting</option>
                                <option value="reporting">Reporting complexity</option>
                                <option value="entity">Multi-entity management</option>
                                <option value="data">Data accuracy &amp; insights</option>
                            </select>
                        </div>
                        <div className="public_form-group">
                            <label column sm="3">Your Message</label>
                            <textarea

                                rows={5}
                                className="public_form-control"
                                type="text"
                                onChange={(e) => set_message(e.target.value)}
                            />

                            <span style={{ color: 'red' }}>{error_message}</span>

                        </div>
                        <button type="button" onClick={() => sendMessage()} className="btn public_btn-gold">Schedule Your Personalized Demo</button>
                        <p >{message_sent}</p>
                    </form>
                </div>
            </div>
        </>

    )
}


export default RequestDemoForm;
