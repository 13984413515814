import React, { useState, useContext, useEffect, useRef } from "react";
import { Col, Row, Dropdown, Card, CardBody, DropdownMenu, DropdownItem, Container, DropdownToggle, UncontrolledAlert } from "reactstrap";
import ReactApexChart from "react-apexcharts"
import { Link } from 'react-router-dom';

import { MoondanceContext } from '../../../App';
import UseMoondanceApi from "../../lib/api";
import MoondanceLayout from "../../lib/layout.js";
import MoondanceTable from "../../lib/components/MoondanceTable.js";
import { buildAccountParameter, positionPerGroup, positionPerGroupGeographies, positionPerGroupSectors } from "../../lib/formatData";


function PositionChart(props) {
  // API HOOK
  const moondanceApi = UseMoondanceApi();
  // CONTEXT VALUES
  const { selectedStatus, userCurrency, selectedAccountList } = useContext(MoondanceContext);
  // STATE VARIABLES
  // AI Response State
  const [ai_reply, setAiReply] = useState('');
  // Data and Table State
  const [CHART_DATA, setChartData] = useState(null);
  const [TABLE_DATA, setTableData] = useState({});
  // UI State
  const [fullScreen, setFullScreen] = useState(false);
  const [chart_type] = useState('bar');
  const chartRef = useRef(null);
  const [height, set_height] = useState(500);
  const [defaultHeight, setDefaultHeight] = useState(400);
  const [horizontal, set_horizontal] = useState(props.horizontal === true);
  // Chart Options
  const [chart_options, set_chart_options] = useState({
    chart: {
      type: 'bar',
      toolbar: { show: false }, // Hide toolbar
      bar: { horizontal: false, borderWidth: 0 },
    },
  });
  // X-Axis Options
  const [x_option, set_x_option] = useState([
    { id: 'asset_name', label: 'Per Position', horizontal: true, showSecYAxis: "asset_vintage_year" },
    { id: 'asset_type', label: 'Asset type' },
    { id: 'asset_vintage_year', label: 'Vintage' },
    { id: 'position_account_name', label: 'Account' },
    { id: 'asset_currency', label: 'Currency' },
    { id: 'asset_intermediate', label: 'Intermediate' },
    { id: 'geography', label: 'Geography' },
    { id: 'sectors', label: 'Sectors' }
  ]);
  // Y-Axis Options
  const [y_option, set_y_option] = useState([
    { id: 'position_irr_in_user_currency', label: 'Internal Rate of Return (IRR)', y_axis_format: 'percentage', data_label_format: 'percentage', show_average: true },
    { id: 'position_tvpi_in_user_currency', label: 'Total Value to Paid-In (TVPI)', y_axis_format: 'number', data_label_format: 'number', show_average: true },
    { id: 'position_dpi_in_user_currency', label: 'Distributions to Paid-In (DPI)', y_axis_format: 'number', data_label_format: 'number', show_average: true },
    { id: 'position_commited_in_user_currency_at_inception_rate', label: 'Commitment', y_axis_format: 'currency', show_average: false },
    { id: 'position_last_nav_in_user_currency', label: 'Last NAV', y_axis_format: 'currency', show_average: false },
    { id: 'position_expected_remaining_callable_capital_in_user_currency', label: 'Estimated open Commitment', show_average: false },
    { id: 'position_gain_and_losses_in_user_currency', label: 'Value Creation', show_average: false },
    { id: 'position_cumulated_capital_calls_in_user_currency', label: 'Capital called', show_average: false, absoluteValue:true },
    { id: 'position_remaining_callable_capital_in_user_currency', label: 'Remaining Commitment', show_average: false, }
  ]);
  // Order Options
  const [order_options] = useState([
    { id: 'A-Z', label: 'By label (Ascending)' },
    { id: 'Z-A', label: 'By label (Descending)' },
    { id: 'valueAsc', label: 'By value (Ascending)' },
    { id: 'valueDesc', label: 'By value (Descending)' },
    { id: 'ysecAsc', label: 'By vintage (Ascending)' }
  ]);
  // Selections and Dropdown States
  const [selected_y, set_selected_y] = useState([]);
  const [selected_x, set_selected_x] = useState([]);
  const [data_label, set_data_label] = useState('value');
  const [actionDropDown_y, set_actionDropDown_y] = useState(false);
  const [actionDropDown_x, set_actionDropDown_x] = useState(false);
  const [OrderDropDown, set_orderDropDown] = useState(false);
  const [order, set_order] = useState('A-Z');
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////  USE EFFECTS  //////////////////////////////////////////////////////////////////////////////////////
  // Update the horizontal state based on the props
  useEffect(() => {
    if (props.horizontal !== undefined) {
      set_horizontal(props.horizontal)
    }
  }, [props.horizontal]);
  // Update the selected x and y based on the props
  useEffect(() => {
    set_selected_x(props.x)
  }, [props.x]);
  useEffect(() => {
    set_selected_y(props.y)
  }, [props.y]);
  // Update sort order based on the props
  useEffect(() => {
    let newOrder = order_options.find(sort => sort.id === props.order)
    if (newOrder.id && newOrder.id !== order) {
      set_order(newOrder.id)
    }
  }, [props.order]);
  //set to ful screen if requested
  useEffect(() => {
    if (props.fullScreen) {
      setFullScreen(true)
    }
  }, [props.fullScreen]);

  useEffect(() => {
    if (props.hideControls) {
      set_height(props.height)
    } else if (horizontal && CHART_DATA && CHART_DATA.options && CHART_DATA.options.yaxis && CHART_DATA.options.yaxis.categories && CHART_DATA.options.yaxis.categories.length > 5 && CHART_DATA.options.yaxis.categories.length < 10) {
      //console.log(dd.options.yaxis.categories)
      set_height(Math.min(CHART_DATA.options.yaxis.categories.length * 50, 1500))
      setDefaultHeight(Math.min(CHART_DATA.options.yaxis.categories.length * 50, 1500))
    } else if (horizontal && CHART_DATA && CHART_DATA.options && CHART_DATA.options.yaxis.categories && CHART_DATA.options.yaxis.categories.length > 10) {
      //console.log(dd.options.yaxis.categories)
      set_height(Math.min(CHART_DATA.options.yaxis.categories.length * 30, 1500))
      setDefaultHeight(Math.min(CHART_DATA.options.yaxis.categories.length * 30, 1500))
    } else {
      set_height(Math.min(window.innerHeight * 0.6, 700))
      setDefaultHeight(Math.min(window.innerHeight * 0.6, 700))

    }

  }, [fullScreen, CHART_DATA, horizontal, props.height,props.hideControls]);


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Export chart
  async function downloadSVG(datatype) {
    const chartInstance = chartRef.current; // Access the chart DOM element
    if (!chartInstance || !chartInstance.chart || !chartInstance.chart.exports) {
      console.error("Chart instance or necessary properties are undefined");
      return;
    }
    // Use the correct method for exporting the chart based on the datatype
    if (datatype === "png") {
      await chartInstance.chart.exports.exportToPng();
    } else if (datatype === "svg") {
      await chartInstance.chart.exports.exportToSVG();
    } else {
      console.error("Unsupported datatype");
    }
  }
  // format X axis values based on the selected format
  function format_y_axis(value, format, sum = false, addpercentage = false) {
    if (format === 'percentage') {
      return MoondanceLayout.formatPercentage(value, 2);
    } else if (format === 'number') {
      return MoondanceLayout.formatNumber((Math.round(value * 100) / 100));
    } else if (format === 'percentageOfSum') {
      return MoondanceLayout.formatPercentage(value / sum, 2)
    }
    return MoondanceLayout.formatNumber(value / 1000, 1) + ' k' + (props.currency ? (MoondanceLayout.currencyShort[props.currency] ? MoondanceLayout.currencyShort[props.currency] : ' ' + props.currency) : '')
  }
  // Sort data based on the selected key and order
  function sortData(data, key, order) {
    const combinedData = data.x.map((xValue, index) => ({
      x: xValue,
      y: data.y[index],
      y2: (data.y2 && data.y2[index] ? data.y2[index] : null),
      y3: (data.y3 && data.y3[index] ? data.y3[index] : null),
      y4: (data.y4 && data.y4[index] ? data.y4[index] : null),
      
    }));
    combinedData.sort((a, b) => {
      if (key === 'x') {
        return order === 'asc' ? a.x.toString().localeCompare(b.x.toString()) : b.x.toString().localeCompare(a.x.toString());
      } else if (key === 'y') {
        return order === 'asc' ? a.y - b.y : b.y - a.y;
      } else if (key === 'y2') {
        return order === 'asc' ? a.y2 - b.y2 : b.y2 - a.y2;
      }
    });
    // Splitting combined data back to separate x and y arrays
    const sortedX = combinedData.map(item => item.x);
    const sortedY = combinedData.map(item => item.y);
    return { x: sortedX, y: sortedY };
  }


  // Format Data for Chart
  function formatChartData(data) {

    let sortedData ={x:[],y:[]};

      sortedData = sortData(data.statsPerType, order === 'valueAsc' ? 'y' : order === 'valueDesc' ? 'y' : order === 'Z-A' ? 'x' : 'x', order.includes('Asc') ? 'asc' : 'desc');
      data.statsPerType.x = sortedData.x;
      data.statsPerType.y = sortedData.y;
      
       let formattedData = {
      label: data.statsPerType.x,
      series: 
      ((data.statsPerType.y2 && data.statsPerType.y2.length>0) || (data.statsPerType.y3 && data.statsPerType.y3.length>0) || (data.statsPerType.y4 && data.statsPerType.y4.length>0) ? 
        [{ name: selected_y.label, data: data.statsPerType.y, group:'group1',stack:'group1' }]
        :
        [{ name: selected_y.label, data: data.statsPerType.y }]
      )  
      ,
      options: {
        legend: {
          show: true,
        },
        chart: { 
          toolbar: { show: false },
          stacked: ((data.statsPerType.y2 && data.statsPerType.y2.length>0) || (data.statsPerType.y3 && data.statsPerType.y3.length>0) || (data.statsPerType.y4 && data.statsPerType.y4.length>0) ? true : true),
        },
        fill: { colors: 
          (!data.statsPerType.y2 || data.statsPerType.y2.length===0 ?
               (props && props.hideControls ? MoondanceLayout.printColorScheme.success : [MoondanceLayout.colorScheme.success])
               :  (props && props.hideControls ? [MoondanceLayout.printColorScheme.success,MoondanceLayout.printColorScheme.main_backgroundColor,MoondanceLayout.printColorScheme.success,MoondanceLayout.printColorScheme.main_backgroundColor] : 
                [MoondanceLayout.colorScheme.success,MoondanceLayout.colorScheme.main_backgroundColor,MoondanceLayout.colorScheme.success,MoondanceLayout.colorScheme.main_backgroundColor])
          )},
        stroke: (props.hideControls ?
          {
            width: 1,
            colors: ['transparent']
          } : {
            width: 1,
            colors: ['#CCCCCC']
          }),
        tooltip: {
          enabled: (props.hideControls ? false : true),

        },
       /* grid:
          (props.hideControls ?
            {
              show: false, // Ensure no gridlines
              borderColor: "transparent", // Avoid unwanted borders
              xaxis: { lines: { show: false } },
              yaxis: { lines: { show: false } }
            } : {

            }

          ),
*/
        xaxis:

          (horizontal ?
            {
              type: 'category',
              categories: data.statsPerType.x,
              labels: {
                show: true,
                formatter: function (value) {
                  return format_y_axis(value, (props.y ? props.y.y_axis_format : false), (data.sumY ? data.sumY : false), true)
                },
              }
            }
            :
            {
              type: 'category',
              categories: (data.statsPerType.x ? data.statsPerType.x : []),
              labels: {
                maxWidth: 350,
                show: true,
                formatter: function (value) { return value; },
              }
            }
          ),

        yaxis:
          (horizontal ?
            {
              type: 'category',
              categories: (data.statsPerType.x ? data.statsPerType.x : []),
              labels: {
                maxWidth: 350,
                show: true,
                formatter: function (value) { return value; },
              }
            } :
            {
              labels: {

                formatter: value => format_y_axis(value, props.y.y_axis_format, data.statsPerType.sumY, true)
              }
            }),
        plotOptions: {
          bar: { horizontal: horizontal },
       
                columnWidth: "50%", // Adjust width to allow side-by-side appearance
                barWidth: "50%" // Optional: fine-tune bar width
            
        },

        dataLabels: {
          enabled: false,
          /*
          formatter: value => format_y_axis(value, props.data_label_format, data.statsPerType.sumY, true),
          style: {
            fontSize: '12px', // Adjust font size for readability
            fontWeight: 'bold', // Makes text clearer
            colors: ['#000000'] // Ensures black text for visibility
          },
          background: {
            enabled: false, // Disables background to avoid interference
          }*/
        },
        annotations: {
          points:
            (horizontal ?
              sortedData.y.map(function (item,index) {
                const yValue = sortedData.y[index];

                return {
                  y: sortedData.x[index], // The x-axis values (years) are assigned to y (for a horizontal chart)
                  x: yValue < 0 ? 0 : yValue, // Handle negative values by ensuring they don't affect placement
                  seriesIndex: 0,
                  marker: {
                    size: 0,
                    strokeWidth: 0,
                    radius: 0,
                  },
                  label: {
                    offsetY: 8,
                    offsetX: 40, // Adjust label position for better visibility
                    borderWidth: 0,
                    style: {
                      color: '#000000',
                      fontSize: '12px',
                      fontWeight: 600,
                    },
                    text: format_y_axis(yValue, props.y.y_axis_format, sortedData.sumY, true)
                  }
                };
              }) :
              sortedData.x.map(function (item, index) {

                const yValue = sortedData.y[index];
                return {
                  x: item, // Directly use item as x since it's already a year
                  y: yValue < 0 ? 0 : yValue, // Ensure y is correctly assigned for negatives
                  seriesIndex: 0,
                  marker: {
                    size: 0,
                    strokeWidth: 0,
                    radius: 0,
                  },
                  label: {
                    offsetX: 8,
                    offsetY: -5, // Adjust this for better positioning
                    borderWidth: 0,
                    style: {
                      color: '#000000',
                      fontSize: '12px',
                      fontWeight: 600,
                    },
                    text: format_y_axis(yValue, props.y.y_axis_format, sortedData.sumY, true)
                  }
                }
              })
            )
        },

      }
    };
    if (data.statsPerType.y2 && data.statsPerType.y2.length > 0) {
      formattedData.series.push({ name: selected_x.label, data: data.statsPerType.y2,group:'group1', stack:'group2' })
    }
    if (data.statsPerType.y3 && data.statsPerType.y3.length > 0) {
      formattedData.series.push({ name: selected_x.label, data: data.statsPerType.y3,group:'group2', stack:'group2'})
    }
    if (data.statsPerType.y4 && data.statsPerType.y4.length > 0) {
      formattedData.series.push({ name: selected_x.label, data: data.statsPerType.y4,group:'group2', stack:'group2' })
    }
    //console.log('formattedData',  formattedData)
    return (formattedData);
  }

  // Format Data for Table
  function formatTableData(data) {
    let tableData = data.statsPerType.x.map((item, index) => ({ x: item, y: data.statsPerType.y[index] }));
    return (tableData);
  }

  // Fetch Data and Format Chart
  useEffect(() => {
    //console.log('setChartData',props)
    const fetchAndFormatData = async () => {
      if (!selected_x.id || !selected_y.id || !props.data) return;
      //  let new_data = await fetchData();
      if (props.data) {
        setChartData(formatChartData(props.data));
        setTableData(formatTableData(props.data));
      }
    };

    fetchAndFormatData();
  }, [props.data, horizontal, order, selected_y, selected_x, fullScreen, props.x, props.y]);

  function showGraph() {

    return (
      <>

        {(props && props.hideControls) ? '' :
          <Row>
            <div className=" d-flex bd-highlight">
              <div className="me-auto" style={{ marginBottom: "1em" }}>
                <h2 style={{ marginLeft: '1em' }}>  {y_option.find(y => y.id === (selected_y.id ? selected_y.id : props.y)) ? y_option.find(y => y.id === (selected_y.id ? selected_y.id : props.y)).label : ''}
                  {x_option.find(x => x.id === (selected_x.id ? selected_x.id : props.x)) ? ' per ' + x_option.find(x => x.id === (selected_x.id ? selected_x.id : props.x)).label : ''}
                </h2>
              </div>
            </div>
          </Row>
        }
        {CHART_DATA && (
          <>
            <ReactApexChart
              ref={chartRef}
              key={selected_x.id + '_' + selected_y.id + '_' + order + '_' + horizontal}
              series={CHART_DATA.series}
              type={chart_type}
              options={CHART_DATA.options}
              height={height}
              title={(CHART_DATA.title ? CHART_DATA.title : '')}
              className="apex-charts"
            />
          </>
        )}


        {(props && props.hideControls) ? '' :
          <Row >
            <Col xl={12} sm={12} className="d-flex flex-row ">
              <button className="btn btn-link" onClick={() => { setFullScreen(!fullScreen) }} >  {fullScreen ? <><span className="bx bx-collapse"></span> Reduce</> : <><span className="bx bx-expand"></span> Full Screen</>} </button>
              <button className="btn btn-link" onClick={() => { downloadSVG('png') }} > <span className="bx bx-export"></span> Export </button>
            </Col>
          </Row>
        }
      </>)

  }

  return (
    <React.Fragment>

      {(props && props.hideControls) ? showGraph() :

        (fullScreen ?
          <div style={{ zIndex: 100000, width: '100%', height: '100%', backgroundColor: 'white', minWidth: '100%', minHeight: '100%', position: 'fixed', top: '0', left: '0' }}>
            {showGraph()}
          </div>
          :
          <>
            {ai_reply ? <UncontrolledAlert color="info"><h5>Asking AI : </h5><p style={{ whiteSpace: 'pre-line' }}>{ai_reply}</p></UncontrolledAlert> : ''}
            <Row>
              <Col xl={12} sm={12} >

                {showGraph()}

              </Col>
              <Col xl={12} sm={12} >

              </Col>
        
             </Row>
          </>
        )
      }
    </React.Fragment >

  )
}
export default PositionChart


/*  <Col xl={4} sm={12}>

                {(TABLE_DATA ?
                  <Card className="greyCard">
                    <CardBody>
                      <MoondanceTable
                        rows={TABLE_DATA}
                        title={props.title}
                        columns={[
                          {
                            label: (x_option.find(x => x.id === (selected_x.id ? selected_x.id : props.x)) ? x_option.find(x => x.id === (selected_x.id ? selected_x.id : props.x)).label : ''),
                            field: 'x',
                          },
                          {
                            label: (y_option.find(y => y.id === (selected_y.id ? selected_y.id : props.y)) ? y_option.find(y => y.id === (selected_y.id ? selected_y.id : props.y)).label : ''),
                            field: 'y',
                            format: (selected_y.data_label_format ? selected_y.data_label_format : 'number'),
                            customFormat: (row) => {
                              return format_y_axis(row['y'], (selected_y.data_label_format ? selected_y.data_label_format : false), false, true)
                            },
                            align: 'right'
                          },
                        ]}
                        hideAiReply={true}
                        hideAiButton={true}

                      />
                    </CardBody>
                  </Card>
                  : 'NO data')}

              </Col>*/