import axios from "axios";
import React, { useState, useEffect,useContext } from "react";
import { MoondanceContext } from '../../App.js';

let API_URL = 'https://api.moondance.financial'
if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    API_URL = 'http://127.0.0.1:8080'
}

let globalCache = {};
//###############################################################################################################

function useMoondanceApi() {

        
    const api ={
       
        debug: false,
        //###############################################################################################################
        // Error handling function
        // S,hould redirect to login, if error is related to authentication and not already on login page
        //###############################################################################################################
        handleError(error, url) {

            if (error === '401' || error ==='Request failed with status code 401') {

                var this_url = window.location.href;
                if (!this_url.endsWith("/login")) {
                    console.log("URL does not end with '/login'");
                    window.location.href = '/login';
                }
            }
            return false;
        },

        convertDateToISO(dateString) {
            // Regular expression to match the date in DD/MM/YYYY format
            const regexDDMMYYYY = /^(\d{2})\/(\d{2})\/(\d{4})$/;
            // Regular expression to check if it's already in YYYY-MM-DD format
            const regexYYYYMMDD = /^(\d{4})-(\d{2})-(\d{2})$/;

            // Check if the string is already in the desired YYYY-MM-DD format
            if (regexYYYYMMDD.test(dateString)) {
                return dateString;
            }

            // Check if the string is in DD/MM/YYYY format and convert it
            const match = dateString.match(regexDDMMYYYY);
            if (match) {
                const day = match[1];
                const month = match[2];
                const year = match[3];
                return `${year}-${month}-${day}`;
            }

            // If the format is incorrect, return null or an error message
            return null; // or handle as needed
        },


        //###############################################################################################################
        //get token from local strage (session) and prepare api request header
        //###############################################################################################################
        setHeader() {
            let token = (localStorage.getItem("authUser") ? localStorage.getItem("authUser") : null)
            let header = {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            return header
        },
        //#################################,##############################################################################
        //encode get parameters to be used in url
        //###############################################################################################################
        encodeGetParams(p) {
            return Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
        },
        //#################################,#############################################################################
        //Store new token in local storage
        //###############################################################################################################
        storeNewToken(response) {
            if (response && response.nt) {
                localStorage.setItem('authUser', response.nt);
                localStorage.setItem('tokenLastRenewed', Date.now());
                return true
            }
            return false
        },
        //###############################################################################################################
        //Check if cache exist for this request
        //###############################################################################################################
        getFromCache(url, params) {
            var cacheKey = url + '?' + this.encodeGetParams(params)
            if (this.debug) { console.log('checking cache', cacheKey) }
            if (!globalCache[cacheKey]) {
                if (this.debug) { console.log('No cache found ', cacheKey) }
          
                return false
            } else {
                if (this.debug) { console.log('returning cache', globalCache[cacheKey]) }
                return globalCache[cacheKey]
            }
        },
        //###############################################################################################################
        //Store cache for this request
        //###############################################################################################################
        storeCache(url, params, value) {
            var cacheKey = url + '?' + this.encodeGetParams(params)
            if (this.debug) { console.log('storing to cache', cacheKey) }
            globalCache[cacheKey] = value;
        },
        //###############################################################################################################
        //clear cache 
        //###############################################################################################################
        clearCache() {
            if (this.debug) { console.log('Clearing cache') }
            globalCache= {};
        },
        //###############################################################################################################
        //send GET request to api
        //###############################################################################################################
        async getFromApi(url, params, restricted = true, useCache = true) {
            //console.log('getFromApi',url,params,restricted,useCache)
            //must have parameters
                                                                                                                                                                                                                                               
            
            if (restricted && !localStorage.getItem("authUser")) {
                return this.handleError(401, url + ' authUser not found');
            }
            if (useCache) {
                var cache = this.getFromCache(url, params);
            }
            if (useCache && cache) {
                //console.log('Cache',globalCache)
                return cache; // cache is a promise
            } else {
                let apiPromise = new Promise((resolve, reject) => {
                    // existing API call logic...
                    axios.get(API_URL + url + (params ? '?' + this.encodeGetParams(params):''), this.setHeader())
                        .then((response) => {
                            if (this.debug) {
                                console.log('Call api', url + (params ? '?' + this.encodeGetParams(params):''))
                                console.log(url + (params ? '?' + this.encodeGetParams(params):''), response)
                            }
                            if (response && response.nt) {
                                this.storeNewToken(response)
                            }

                            if (useCache) {
                                this.storeCache(url, params, response)
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            this.handleError(err, url)
                            reject(err);
                        });
                });
                this.storeCache(url, params, apiPromise);
                return apiPromise;
            }

        },
        //###############################################################################################################
        //send POST request to api
        //###############################################################################################################
        postToApi(url, requestData, restricted = true) {
            if (restricted && !localStorage.getItem("authUser")) { return this.handleError(401); }

            return axios.post(API_URL + url, requestData, this.setHeader())
                .then(response => {
                    if (this.debug) {
                        console.log('POST : ' + url, requestData)
                        console.log('POST : ' + url + ' response', response)
                    }

                    
                    this.storeNewToken(response)
                    globalCache = {} //clear cache
                    return response
                })
                .catch(error => {
                    console.log('ERROR POST : ' + url, error)

                    console.log('POST : ' + url, requestData)
                    return { success: false, error: error }
                });
        },
        //###############################################################################################################
        // Renew token
        //###############################################################################################################
        async renewToken() {
            return await this.getFromApi('/renewToken', {}, true, false)
        },
        //###############################################################################################################
        // Fetch position summary
        //###############################################################################################################
        async fetchDashboardSummary(params) {
            return await this.getFromApi('/positions/summary', params, true, true)

        },
        //###############################################################################################################
        //Fetch positions expected calls
        //###############################################################################################################
        async fetchPositionsExpectecCalls(params) {
            return await this.getFromApi('/positions/expectedCalls', params, true, true)
        },
        //###############################################################################################################
        // fetch list of positions 
        //###############################################################################################################
        async fetchPositionsList(params) {
            return await this.getFromApi('/positions/list', params, true, true)
        },
        //###############################################################################################################
        // fetch list of Assets 
        //###############################################################################################################
        async fetchAssetsList(params) {
            var positions = await this.getFromApi('/positions/list', params, true, true)

            if (!positions.success) {
                return positions
            }


            // Initialize the data array
            const assetDataArray = [];

            // Iterate through the data and organize it by asset_id
            positions.data.forEach((item) => {
                // Filter variables starting with 'asset_'
                const assetVariables = {};
                for (const key in item) {
                    //if (key.startsWith("asset_")) {
                        assetVariables[key] = item[key];
                    //}
                }

                assetDataArray.push(assetVariables);
            });

            // Create the return object
            const returnObject = {
                success: positions.success,
                nt: positions.nt,
                data: assetDataArray,
            };

            return returnObject


        },
        //###############################################################################################################
        // Fetch assets geographies list
        //###############################################################################################################
        async fetchAssetsGeographiesList(params) {
            return await this.getFromApi('/assets/getGeographies', params, true, true)
        },
        
        //###############################################################################################################
        // Fetch assets sector list
        //###############################################################################################################
        async fetchAssetsSectorsList(params) {
            return await this.getFromApi('/assets/getSectors', params, true, true)
        },
        //###############################################################################################################
        // Fetch assets types list
        //###############################################################################################################
        async fetchAssetsTypeList(params) {
            return await this.getFromApi('/assets/getTypes', params, true, true)
        },
        
        //###############################################################################################################
        // Fetch KPI per year
        //###############################################################################################################
        async fetchYearly(params) {
            return await this.getFromApi('/yearly', params, true, true)
        },
         //###############################################################################################################
        // Fetch transactions per position per year
        //###############################################################################################################
        async fetchYearlyTransactionPerPosition(params) {
            return await this.getFromApi('/yearly/perPosition', params, true, true)
        },
         //###############################################################################################################
        // Fetch expected future transactions per position per year
        //###############################################################################################################
        async fetchPositionExpectedCashFlowPerYear(params) {
            return await this.getFromApi('/yearly/expectedPerPosition', params, true, true)
        },
        //###############################################################################################################
        // Fetch list of positions for current year
        async fetchPositionCurrentYear(params) {
            return await this.getFromApi('/positions/current_year', params, true, true)
        },
        //###############################################################################################################
        // Fetch position details
        //###############################################################################################################
        async fetchPositionDetails(params, id) {
            return await this.getFromApi('/position/' + id, params, true, true)
        },
        //###############################################################################################################
        // Fetch asset details
        //###############################################################################################################
        async fetchAssetDetails(params, id) {
            return await this.getFromApi('/asset/' + id, params, true, true)
        },
        //###############################################################################################################
        // Fetch asset geography
        //###############################################################################################################
        async fetchPositionGeography(params, id) {
            return await this.getFromApi('/position/' + id + '/geography', params, true, true)
        },
          //###############################################################################################################
        // Fetch asset types
        //###############################################################################################################
        async fetchPositionTypes(params, id) {
            return await this.getFromApi('/position/' + id + '/types', params, true, true)
        },
        //###############################################################################################################
        // Fetch postion nav history
        //###############################################################################################################
        async fetchPositionNavHistory(params, id) {
            return await this.getFromApi('/position/' + id + '/nav/history', params, true, true)
        },
        //###############################################################################################################
        //fetch asset sectors
        //###############################################################################################################
        async fetchPositionSectors(params, id) {
            return await this.getFromApi('/position/' + id + '/sectors', params, true, true)
        },
        //###############################################################################################################
        //fetch transactions for a position
        //###############################################################################################################
        async fetchPositionTransactions(params, id) {
            return await this.getFromApi('/position/' + id + '/transactions', params, true, true)
        },

        //###############################################################################################################
        //fetch position IRR calculation serie
        //###############################################################################################################
        async fetchPositionIrrSerie(params, id) {
            return await this.getFromApi('/position/' + id + '/irr/data', params, true, true)
        },
        //###############################################################################################################
        //fetch Transactions
        //###############################################################################################################
        async fetchTransactions(params) {
            return await this.getFromApi('/transactions', params, true, true)
        },
        //###############################################################################################################
        //fetch position stats per year
        //###############################################################################################################
        async fetchPositionYearly(params, id) {
            return await this.getFromApi('/position/' + id + '/yearly', params, true, true)
        },
        //###############################################################################################################
        //fetch Accounts
        //###############################################################################################################
        async fetchAccounts() {
            try {
                var response = await this.getFromApi('/accounts', {}, true, true);
                if (this.debug) {
                    console.log('fetchAccounts', response);
                }
                if (response.data && response.data.length > 0) {
                    response.defaultSelectedArray = [];
                    response.selectArray = [];

                    response.data.forEach((item) => {
                        response.selectArray.push({ 
                            value: item.id, 
                            label: item.n, 
                            user_can_edit_position: item.user_can_edit_position,
                            user_can_update_nav: item.user_can_update_nav,
                            user_can_add_transaction: item.user_can_add_transaction,
                         });
                        if (item.selectedByDefault === 1) {
                            response.defaultSelectedArray.push({ value: item.id, label: item.n });
                        }
                    });
                } else {
                    response.data = false;
                }

                return response;
            } catch (error) {
                console.error('Error fetching accounts:', error);
                // Handle the error appropriately
                return null; // or any other appropriate default/error value
            }
        },
        //###############################################################################################################
        //List users that can  access a given account
        //###############################################################################################################
        async listAccountUsers(id) {
            return await this.getFromApi('/account/' + id + '/users', {}, true, true)
        },
        //###############################################################################################################
        // POST Add a transaction
        //###############################################################################################################
        addNewTransaction(requestData) {
            //Legacy check
            if (!requestData.position_id && requestData.fund_id) {
                requestData.position_id = requestData.fund_id
            }
            return this.postToApi('/transaction/' + requestData.position_id, requestData, true)
        },
        //###############################################################################################################
        // Update a transaction
        //###############################################################################################################
        updateTransaction(id, requestData) {

            return this.postToApi('/transaction/' + id + '/update', requestData, true)
        },
        //###############################################################################################################
        // Delete transaction
        //###############################################################################################################
        deleteTransaction(requestData) {
            return this.postToApi('/transaction/' +  requestData.transaction_id+'/delete', requestData, true)
        },
        //###############################################################################################################
        // Insert a new postion NAV
        //###############################################################################################################
        postNav(requestData) {
            console.log('postNav',requestData)
            return this.postToApi('/position/' + requestData.position_id + '/nav', requestData, true)
        },
        //###############################################################################################################
        // Update a  NAV
        //###############################################################################################################
        updateNav(requestData) {
            return this.postToApi('/position/' + requestData.position_id + '/nav/update/' + requestData.id, requestData, true)
        },
        //###############################################################################################################
        // Delete a  NAV
        //###############################################################################################################
        deleteNav(requestData) {
            return this.postToApi('/position/' + requestData.position_id + '/nav/delete/' + requestData.nav_id, requestData, true)
        },
        //###############################################################################################################
        // Update an asset feedername
        //###############################################################################################################
        postUpdateFeederName(requestData) {
            return this.postToApi('/asset/' + requestData.asset_id + '/feeder', requestData, true)
        },
        //###############################################################################################################
        // add new position
        //###############################################################################################################
        addNewPosition(requestData) {
            console.log('addNewPosition',requestData)
            return this.postToApi('/position/new', requestData, true)
        },
        //###############################################################################################################
        // add new asset
        //###############################################################################################################
        createAsset(requestData) {
            console.log('createAsset',requestData)
            return this.postToApi('/asset/new', requestData, true)
        },
        //###############################################################################################################
        // Update asset
        //###############################################################################################################
        updateAsset(asset_id, requestData) {
            return this.postToApi('/asset/' + asset_id+'/update', requestData, true)
        },

        
        //###############################################################################################################
        // Update Position
        //###############################################################################################################
        updatePosition(position_id, requestData) {
            return this.postToApi('/position/' + position_id+'/update', requestData, true)
        },
        //###############################################################################################################
        // Save Asset model
        //###############################################################################################################
        saveAssetModel(Asset_id, AssetModelRequestData) {
            return this.postToApi('/asset/' + Asset_id + '/model', AssetModelRequestData, true)
        },
        //###############################################################################################################
        // user Login to API  #SECURITY
        //###############################################################################################################
        loginToApi(requestData) {
            return this.postToApi('/login', requestData, false)
        },
        //###############################################################################################################
        // request reset password  #SECURITY
        //###############################################################################################################
        requestNewPassword(requestData) {
            return this.postToApi('/lost_password', requestData, false)
        },
        //###############################################################################################################
        // Reset password  #SECURITY
        //###############################################################################################################
        resetPassword(requestData) {
            return this.postToApi('/reset_password', requestData, false)
        },
        //###############################################################################################################
        // get currently logged user info
        //###############################################################################################################
        async getLoggedinUser() {
            return await this.getFromApi('/userinfo', {}, true, false) //do not use cache for this call
        },
        //###############################################################################################################
        // get last exchange reates for all available currencies
        //###############################################################################################################
        async getLastExchangeRate(date ) {
            return await this.getFromApi('/exchange_rate/' + this.convertDateToISO(date), {}, true, true)
        },
         //###############################################################################################################
        // get exchange rates for given time period and currencies
        //###############################################################################################################
        async getExchangeRateSerie(requestData ) {
            return await this.getFromApi('/exchange_rate/serie/' + requestData.from_currency+'/'+requestData.to_currency, requestData, true, true)
        },
        //##,#############################################################################################################
        // Create Time based One Time Password secret
        //###############################################################################################################
        createTotpSecret() {
            return this.postToApi('/createTotpSecret', {}, true,)
        },
        //#,##############################################################################################################
        // Check One Time Password
        //###############################################################################################################
        async checkOtp(requestData) {
            return await this.postToApi('/checkOtp', requestData, true,)
        },
        //###################,############################################################################################
        // Fetch Logs
        //###############################################################################################################
        async fetchLogs(date) {
            return await this.getFromApi('/logs', date, true, true)
        },

        //###############################################################################################################
        // get Budget
        //###############################################################################################################
        async getBudget(requestData) {
            return await this.getFromApi('/budget/'+requestData.account_id, requestData, true, true)
    
        },
        //###############################################################################################################
        // update user Funds List
        //###############################################################################################################
        updateProfile(requestData) {
            return this.postToApi('/userProfile/' + requestData.id, requestData, true,)
        },
        //###############################################################################################################
        // update user Account selection
        //###############################################################################################################
        updateUserAccountsSelection(requestData) {
            return this.postToApi('/updateUserAccountsSelection/' + requestData.id, requestData, true)
        },
        //###############################################################################################################
        // Create new account
        //###############################################################################################################
        createAccount(requestData) {
            return this.postToApi('/createAccount', requestData, true)
        },
        //###############################################################################################################
        // update Account Name
        //###############################################################################################################
        updateAccountName(requestData) {
            return this.postToApi('/updateAccountName/' + requestData.id, requestData, true)
        },
        //###############################################################################################################
        // Duplicate positon
        //###############################################################################################################
        duplicateAsset(requestData){
            return this.postToApi('/position/' + requestData.source_position_id+'/duplicate', requestData, true)
        },
        //###############################################################################################################
        // update user Account access
        //###############################################################################################################
        updateUserAccess(requestData) {
            return this.postToApi('/updateUserAccess/' + requestData.id, requestData, true)
        },
        //###############################################################################################################
        // save Account budget
        //###############################################################################################################
        saveBudget(requestData) {
            return this.postToApi('/saveBudget/' + requestData.account_id, requestData, true)
        },
        //###############################################################################################################
        // Invite user to account
        //###############################################################################################################
        inviteUser(requestData) {
            return this.postToApi('/inviteUser', requestData, true)
        },
        //###############################################################################################################
        // User signup (registration)
        //###############################################################################################################
        signup(requestData) {
            return this.postToApi('/signup', requestData, false)
        },
        //###############################################################################################################
        // Confirm Email
        //###############################################################################################################
        confirmEmail(requestData) {
            return this.postToApi('/confirmEmail', requestData, false)
        },
        //###############################################################################################################
        // send  contact Form Email
        //###############################################################################################################
        sendContactForm(requestData) {
            return this.postToApi('/contactForm', requestData, false)
        },
        //###############################################################################################################
        // post Import Template
        //###############################################################################################################
        saveImportTemplate(requestData) {
            return this.postToApi('/importTemplate', requestData, false)
        },
         //###############################################################################################################
        // get Import Template
        //###############################################################################################################
        async getImportTemplate() {
            return await this.getFromApi('/importTemplate', [], true, true)
    
        },
        //###############################################################################################################
        // confirm  contact Form toke,
        //###############################################################################################################
        confirmContactToken(requestData) {

            console.log('confirmContactToken',requestData)
            return this.postToApi('/confirmContactEmail', requestData, false)
        },
        //###############################################################################################################
        // Export Account data
        //###############################################################################################################
        async exportAccount(requestData) {
            try {
                console.log(requestData);
                const response = await axios.post(API_URL + '/export_account/' + requestData.accountId, requestData, {
                    ...this.setHeader(),
                    responseType: 'blob'
                });
                // If response.data is already a blob, just use it
                const blob = response;
                // Trigger download
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                const currentDate = new Date().toISOString().slice(0, 10);  // 'YYYY-MM-DD' format
                const filename = `moondance_data_${requestData.accountId}_${currentDate}.xlsx`;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
                return response;
            } catch (error) {
                console.log(error);
                return { success: false, error: error };
            }
        },
       //###################,############################################################################################
        // Notes
        //###############################################################################################################
        async getNotes(params) {
            return await this.getFromApi('/notes', params, true, true)
        },
        async getNoteForPosition(params) {
            return await this.getFromApi('/position/'+params.id+'/notes', params, true, true)
        },
        async postNote(params) {
            return await this.postToApi('/notes', params, true, true)
        },
        async postNoteUpdate(params) {
            return await this.postToApi('/notes/'+params.note_id+'/update', params, true, true)
        },
        async postNoteClearLink(params) {
            return await this.postToApi('/notes/'+params.note_id+'/clearLink', params, true, true)
        },
        async postLinkNoteToPosition(params) {
            return await this.postToApi('/notes/'+params.note_id+'/position/'+params.position_id, params, true, true)
        },
        async getNotePerId(params) {
            return await this.getFromApi('/notes/'+params.note_id, false, true, true)
        },
        async updateNotePerId(params) {
            return await this.postToApi('/notes/'+params.note_id+'/update', params, true, true)
        },
        //###############################################################################################################
        //Admin functions Account data
        //###############################################################################################################
        async admin_get_users_list(requestData) {
            return await this.getFromApi('/admin/users_list', requestData, true, true)
    
        },
        async admin_get_clients_list(requestData) {
            return await this.getFromApi('/admin/list_clients', requestData, true, true)
    
        },
        async admin_get_client(requestData) {
            return await this.getFromApi('/admin/clients/'+requestData.id, {}, true, true)
    
        },
        async admin_get_client_users(requestData) {
            return await this.getFromApi('/admin/clients/'+requestData.id+'/users', {}, true, true)
    
        },
        async admin_get_client_accounts(requestData) {
            return await this.getFromApi('/admin/clients/'+requestData.id+'/accounts', {}, true, true)
    
        },

        async admin_get_accounts_list(requestData) {  
            return await this.getFromApi('/admin/list_accounts', requestData, true, true)
        },
        async admin_get_account(requestData) {
            return await this.getFromApi('/admin/account/'+requestData.id, {}, true, true)
    
        },
        async admin_get_user(requestData) {
            return await this.getFromApi('/admin/user/'+requestData.id, {}, true, true)
    
        },
      
        async admin_get_client_positions(requestData) {
            return await this.getFromApi('/admin/clients/'+requestData.id+'/positions', {}, true, true)
    
        },
        async admin_fetchLogs(date) {
            return await this.getFromApi('/admin/logs', date, true, true)
        },

        async admin_create_client(requestData) {
            return await this.postToApi('/admin/client/create', requestData, true, true)
        },
        async admin_update_client(requestData) {
            return await this.postToApi('/admin/client/update', requestData, true, true)
        },
        async admin_create_account(requestData) {
            return await this.postToApi('/admin/account/create', requestData, true, true)
        },
        async admin_update_account(requestData) {
            return await this.postToApi('/admin/account/update', requestData, true, true)
        },
        
        async admin_update_user(requestData) {
            return await this.postToApi('/admin/user/update', requestData, true, true)
        },
        
        //###############################################################################################################
        //AI functions
        async ai_analyse(requestData) {
            return await this.postToApi('/ai/analyse', requestData, true, false)
        },
    }
    return api

}

export default useMoondanceApi;

